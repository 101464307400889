import { Grid, TextField, FormControl, Autocomplete, Button, Menu, MenuItem, Dialog, DialogContent, Typography, Box, Snackbar, Alert, Link } from '@mui/material';
import React, { useEffect } from 'react'
import Footer from '../../GlobalComponent/Footer/Footer';
import Header from '../../GlobalComponent/Header/Header';
import './NewProfile.css';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import moment from 'moment';
import { useContext } from "react";
import { Context as mainContext } from '../../Context/auth_context'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { deleteUserProfileUrl, deleteUserProfileUrlAPI, getCompanyAgentDetailsAPI, getCompanyAgentList, getUserDetailsAPI, getUserProfileDetails } from '../../service/login.service';
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridToolbar } from '@mui/x-data-grid';
import { useNavigate, useSearchParams } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ShareIcon from '@mui/icons-material/Share';
import SaveIcon from '@mui/icons-material/Save';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteUserProfilePopup from '../../GlobalComponent/DeleteUserProfilePopup/DeleteUserProfilePopup';
import { CheckCircleRounded, ErrorRounded, Upload } from '@mui/icons-material';
import SaveToSampleProfilePopup from "../../GlobalComponent/SaveToSampleProfilePopup/SaveToSampleProfilePopup";
import AssignProfilePopup from '../../GlobalComponent/AssignProfilePopup/AssignProfilePopup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { color, Stack } from '@mui/system';




const NewProfile = () => {


    const theme = useTheme();
    const navigate = useNavigate();
    const { UpdateLoader, state } = useContext(mainContext)
    const [selectTableAgentBtn, setSelectTableAgentBtn] = useState(false)
    const [selectTableProfileBtn, setSelectTableProfileBtn] = useState(true)
    const [deleteUserProfile, setDeleteUserProfile] = React.useState(false)
    const [message, setMessage] = useState({
        status: false,
        message: "",
        error: "",
    });
    const [filterDetails, setFilterDetails] = useState({
        date_filter_flag: true,
        from_timestamp: moment().subtract(60, "days").format("MM/DD/YYYY"),
        to_timestamp: moment().format("MM/DD/YYYY"),
        agent_id: 0,
    })
    const [filterData, setFilterData] = useState([{}])
    const [userData, setUserData] = useState([])
    const [agentData, setAgentData] = useState([])
    const [agentDataoption, setAgentDataoption] = useState([])
    const [assignAgent, setAssignAgent] = React.useState(false)

    const [btnclickdata, setbtnclickdata] = useState("profile")
    const [countList, setCountList] = useState({
        total_agents: 0,
        total_admins: 0,
        total_active_agents: 0,
        total_pending_agents: 0,
        total_inactive_agents: 0,
    })
    const [minandmaxdate, setminandmaxdate] = useState({
        from_min: new Date(1997, 1, 1),
        from_max: new Date(),
        to_min: new Date(1997, 1, 1),
        to_max: new Date(),
    })
    const [profileCnt, setProfileCnt] = useState(0)
    const [selectstateAgent, setSelectstateAgent] = useState({
        "user_id": 0,
        "fullName": ""
    })
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedAgent, setSelectedAgent] = React.useState({})
    const [flagAgentDeleted, setFlagAgentDeleted] = useState(false);
    const [flagDeleteAgentPopup, setFlagDeleteAgentPopup] = useState(false);
    const open1 = Boolean(anchorEl);
    const [agentList, setAgentList] = React.useState([{
        user_id: "",
        user_firstname: "",
        user_lastname: "",
        user_email: "",
        user_status_id: ""
    }]);
    const [searchParams, setSearchParams] = useSearchParams();

    const [personName, setPersonName] = React.useState((state.user_detail.role_id == 1) ? 0 : "");
    const [saveTOSampleProfilebtn, setSaveTOSampleProfilebtn] = useState(false);





    const userColumns = [
        // {
        //     field: "user_profile_id",
        //     headerName: "Id",
        //     type: "number",
        //     align: 'left',
        //     headerAlign: 'left',
        //     hide: true,
        // },
        {
            field: "user_profile_name",
            headerName: "Name",
            width: 250,
            // type: "string",
            align: 'left',
            headerAlign: 'left',
            cellClassName: (params) => {
                let row = params.row

                if (row.user_profile_name) {
                    return "profile-button-click-style"
                }
            }
        },
        {
            field: "user_profile_created_at",
            headerName: "Created Date",
            type: "date",
            width: 200,
            // valueFormatter: params => moment(params?.value).format("MM/DD/YYYY hh:mm A"),
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>
                        {
                            (row?.sample_profile || row?.user_profile_created_at == null) ? "" : moment(row.user_profile_created_at).format("MM/DD/YYYY hh:mm A")
                        }
                    </>
                )
            }

        },
        {
            field: "user_email_address",
            headerName: "Email",
            // type: "string",
            width: 250,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>{(row?.sample_profile) ? "" : row.user_email_address}</>
                )
            }
        },
        {
            field: "user_contact",
            headerName: "Contact",
            type: "string",
            width: 160,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>{(row?.sample_profile) ? "" : row.user_contact}</>
                )
            }
        },
        {
            field: "user_assigned_agent_name",
            headerName: "Assignee",
            type: "string",
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>
                        {/* <Button sx={{ fontSize: "14px", textTransform: "capitalize", justifyContent: "start", textAlign: "start" }} > */}
                        {(row?.sample_profile) && <span id="pr-sample-btn" size="small" variant="outlined" sx={{ justifyContent: "left", textAlign: "start", }}>Sample Profile</span>} {(state.user_detail.role_id == 1) ? row.user_assigned_agent_name : ""}
                        {/* </Button> */}
                    </>
                )
            }

        },
        {
            field: "",
            headerName: "",
            width: 220,
            type: "string",
            align: 'center',
            headerAlign: 'center',
            // headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>
                        <Button
                            id="fade-button"
                            aria-controls={open ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e) => {
                                setSelectedAgent(row)
                                handleClick(e)
                            }}
                        >

                            <MoreVertIcon className="optionIcon" color='black' />
                        </Button>
                    </>
                )
            }

        },


    ];

    const openEditProfilePage = () => {
        navigate("/userprofile?profile_id=" + selectedAgent.user_profile_id)
    }

    const handleOpenUserProfile = (e) => {
        navigate("/profile/calculators?id=" + e);
    };

    const saveProfileForUserProfile = () => {
        navigate("/userprofile?profile_id=" + selectedAgent.user_profile_id)
    }

    const searchFilterData = () => {
        let searchFilterData = {
            date_filter_flag: filterDetails.date_filter_flag,
            agentid: filterDetails.agent_id,
            from_timestamp: filterDetails.from_timestamp,
            to_timestamp: filterDetails.to_timestamp,
        }
        UpdateLoader(true)
        getUserDetailsAPI(searchFilterData, (res) => {
            setUserData(res.data.data)
            setProfileCnt(res.data.profileCount)
            UpdateLoader(false)

        },
            (err) => {
                console.log("err", err);
            })
    }

    useEffect(() => {
        let filterObj = {
            agentid: filterDetails.agent_id
        }
        UpdateLoader(true)
        getUserDetailsAPI(filterObj, (res) => {
            if (res.data.data) {
                let data = res.data.data
                let getSampleProfile = data.filter(filterobj => filterobj.sample_profile == true)
                let getNotSampleProfile = data.filter(filterObj => filterObj.sample_profile == false)
                getNotSampleProfile = getNotSampleProfile.sort((a, b) => (a.user_profile_name.toLowerCase() > b.user_profile_name.toLowerCase()) ? 0 : ((b.user_profile_name.toLowerCase() > a.user_profile_name.toLowerCase()) ? -1 : 0))
                getSampleProfile = getSampleProfile.sort((a, b) => (a.sample_profile > b.sample_profile) ? 0 : ((b.sample_profile > a.sample_profile) ? -1 : 0))
                data = [...getSampleProfile, ...getNotSampleProfile]
                setUserData(data)
                setProfileCnt(res.data.profileCount)
                UpdateLoader(false)
            }
            else {

                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            }
            UpdateLoader(false)
        },
            (err) => {
                UpdateLoader(false)
                console.log("error", err);
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            })
    }, [])


    useEffect(() => {
        let ss = state.user_detail.role_id
        let ss2 = state.user_detail
        if (state.user_detail.role_id && state.user_detail.role_id == 1) {

            UpdateLoader(true)
            getCompanyAgentDetailsAPI((res) => {
                if (res.data.data) {
                    let data = res.data.data[0].agents.map((obj) => {
                        obj.fullName = obj.user_firstname + ' ' + obj.user_lastname;
                        obj.user_status_name = (obj.user_status_name == "Registered") ? "Pending" : obj.user_status_name
                        obj.fullName = obj.fullName.charAt(0).toUpperCase() + obj.fullName.slice(1);
                        return obj
                    })
                    data = data.filter(rr => rr.user_role_id != 1)
                    data = data.sort((a, b) => (a.fullName > b.fullName) ? 1 : ((b.fullName > a.fullName) ? -1 : 0))
                    data.map((rr, i) => {
                        rr['uid'] = i + 1
                    })
                    var ff = {
                        "user_id": 0,
                        "fullName": "All"
                    }
                    setAgentDataoption([ff].concat(data))
                    setAgentData(data)
                    UpdateLoader(false)
                }
                else {
                    setMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                }
                UpdateLoader(false)
            },
                (err) => {
                    UpdateLoader(false)
                    console.log("error", err);
                    setMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                }
            );
        }
    }, [state.user_detail])



    const handleClose1 = (event, reason) => {
        setMessage({
            status: false,
            message: "",
            error: "",
        })
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAssignAgent(false);
        setDeleteUserProfile(false);
        setSaveTOSampleProfilebtn(false)
        setFlagAgentDeleted(false)

        if (state.user_detail.role_id == 1) {
            let searchFilterData = {
                date_filter_flag: filterDetails.date_filter_flag,
                agentid: filterDetails.agent_id,
                from_timestamp: filterDetails.from_timestamp,
                to_timestamp: filterDetails.to_timestamp,
            }
            getUserDetailsAPI(searchFilterData, (res) => {
                let data = res.data.data
                let getSampleProfile = data.filter(filterobj => filterobj.sample_profile == true)
                let getNotSampleProfile = data.filter(filterObj => filterObj.sample_profile == false)
                getNotSampleProfile = getNotSampleProfile.sort((a, b) => (a.user_profile_name.toLowerCase() > b.user_profile_name.toLowerCase()) ? 0 : ((b.user_profile_name.toLowerCase() > a.user_profile_name.toLowerCase()) ? -1 : 0))
                getSampleProfile = getSampleProfile.sort((a, b) => (a.sample_profile > b.sample_profile) ? 0 : ((b.sample_profile > a.sample_profile) ? -1 : 0))
                data = [...getSampleProfile, ...getNotSampleProfile]
                setUserData(data)
                setProfileCnt(res.data.profileCount)
            },
                (err) => {
                    console.log("err", err);
                })
        }
    }

    const deactivateShareUrl = (agentList) => {
        let formData = {
            public_token: agentList.url_token,
            user_profile_id: agentList.user_profile_id
        }

        UpdateLoader(true)
        deleteUserProfileUrlAPI(formData, (res) => {
            if (res.data.status) {
                setMessage({
                    status: true,
                    message: "URL Deactivated Successfully!",
                    error: false,
                })
                UpdateLoader(false);
                setFlagDeleteAgentPopup(false)
            }
            else {
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                UpdateLoader(false);
                setFlagDeleteAgentPopup(false)
            }

            handleClose()

        }, (err) => {
            UpdateLoader(false);
            setMessage({
                status: false,
                message: "Something went wrong, Please try again later.!",
                error: true,
            })
        })
    }

    const handleOptionClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    function NoRowsOverlay() {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                No Data Found
            </Stack>
        );
    }



    return (
        <>
            <Header />
            {flagDeleteAgentPopup &&
                <Dialog
                    open={true}
                    onClose={() => { handleClose() }}
                    aria-labelledby="responsive-dialog-title">
                    <DialogContent>
                        <Grid item xs={12}>
                            <Grid container className="pr-assign-profile-popup">
                                <Grid item xs={12}>
                                    <Grid container>

                                        <Grid item xs={12}>
                                            <Grid container className="pr-assign-profile-popup-title-container">
                                                {flagAgentDeleted ?
                                                    (
                                                        <>
                                                            <Typography id="pr-assign-profile-popup-title">Successfully Deleted!</Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography id="pr-assign-profile-popup-title">Deactivate URL</Typography>
                                                        </>
                                                    )
                                                }

                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid container className="pr-assign-profile-popup-close-btn-container">
                                                <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={() => { setFlagDeleteAgentPopup(false); setFlagAgentDeleted(false) }} /></Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xl={7} lg={7} md={7} sm={7} xs={12} className='temp'>
                                    <Grid container className="pr-assign-agent-dropdown-container">
                                        <Grid item xs={12}>
                                            <Grid container className="pr-assign-agent-dropdown-label" textAlign={"center"}>
                                                {flagAgentDeleted ?
                                                    (
                                                        <>
                                                            <CheckCircleRounded sx={{ color: "green", width: "60px", height: "60px", margin: "0px auto 15px" }} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ErrorRounded sx={{ color: "red", width: "60px", height: "60px", margin: "5px auto" }} />
                                                        </>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="pr-assign-agent-dropdown-label">
                                                {flagAgentDeleted ?
                                                    (
                                                        <>
                                                            <Typography id="pr-assign-agent-dropdown-label">Agent Deleted Successfully!!</Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography id="pr-assign-agent-dropdown-label">Are you sure you want to deactivate URL?</Typography>
                                                        </>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
                                    <Grid container className="pr-save-cancel-popup-container-agent" justifyContent="center" marginTop='10px'>
                                        {flagAgentDeleted ?
                                            (
                                                <>
                                                    <Grid item xs={5.6}>
                                                        <Grid container className="avl-email-popup-btn" sx={{ display: "flex", justifyContent: "center", margin: "0 auto", width: "100%" }}>
                                                            <Button variant="outlined" sx={{ margin: "0 auto", justifyContent: "center" }} id="avl-email-save-popup-btn" onClick={() => { setFlagDeleteAgentPopup(false); setFlagAgentDeleted(false) }}>Close</Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Grid container justifyContent="space-between">

                                                            <Grid item xs={5.6} >
                                                                <Grid container className="avl-email-popup-btn">
                                                                    <Button variant="outlined" id="avl-email-save-popup-btn" onClick={() => { setFlagDeleteAgentPopup(false) }}>No</Button>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={5.6}>
                                                                <Grid container className="pr-save-popup-btn">
                                                                    <Button variant="contained" id="pr-save-popup-btn" onClick={() => { deactivateShareUrl(selectedAgent) }}>Yes</Button>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }

            {message.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
                    <Alert onClose={handleClose1} severity={message.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {message.message}
                    </Alert>
                </Snackbar>
            }

            {assignAgent &&
                <AssignProfilePopup
                    data={selectedAgent}
                    agentList={agentData}
                    showMessage={(data) => { setMessage(data) }}
                    handleClose={() => { handleClose() }}
                />
            }

            {deleteUserProfile &&
                <DeleteUserProfilePopup
                    data={selectedAgent}
                    agentList={agentData}
                    showMessage={(data) => { setMessage(data) }}
                    handleClose={() => { handleClose() }}
                    personName={personName}
                />
            }

            {saveTOSampleProfilebtn &&
                <SaveToSampleProfilePopup
                    data={selectedAgent}
                    showMessage={(data) => { setMessage(data) }}
                    handleClose={() => { handleClose() }}
                />
            }


            <Grid container className='np-main-container'>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={11} sx={{ margin: "0 auto", textAlign: "center" }}>
                            <Grid container>

                                <Grid item xs={12}>
                                    <span className='np-heading-lebel'>Profile</span>
                                </Grid>

                                <Grid item xs={12} className="np-table-blue-item">
                                    <Grid container className='np-table-blue-container'>

                                        <>
                                            <Grid item xs={12}>
                                                <Grid container className='np-table-white-container1'>

                                                    <Grid item xl={2} lg={3} md={3} sm={10} xs={12} className='tp-from-item'>
                                                        <Grid container alignItems="center" sx={{ width: '100%' }} >

                                                            <Grid item sx={{ width: '100%' }} xl={3} lg={3} md={3} sm={2} xs={2} className='tp_date_from_item'>
                                                                <span>From </span>
                                                            </Grid>
                                                            <Grid item sx={{ width: '100%' }} xl={9} lg={8} md={8} sm={4.5} xs={10}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                                                    <DesktopDatePicker
                                                                        className="datepicker-report"
                                                                        inputFormat="MM/DD/YYYY"
                                                                        value={filterDetails.from_timestamp}
                                                                        minDate={minandmaxdate.from_min}
                                                                        maxDate={minandmaxdate.from_max}
                                                                        onChange={
                                                                            (e) => {
                                                                                setminandmaxdate({ ...minandmaxdate, to_min: new Date(e) })
                                                                                e = moment(new Date(e)).format("MM/DD/YYYY")
                                                                                setFilterDetails({ ...filterDetails, from_timestamp: e })
                                                                            }
                                                                        }
                                                                        renderInput={(params) => <TextField size="small" {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={2} lg={3} md={3} sm={10} xs={12} className='tp-to-item'>
                                                        <Grid container justifyContent="start">
                                                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className='tp_date_to_item' >
                                                                <span>To &nbsp;</span>
                                                            </Grid>
                                                            <Grid item xl={9} lg={8} md={8} sm={4.5} xs={10}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DesktopDatePicker
                                                                        className="datepicker-report"
                                                                        inputFormat="MM/DD/YYYY"
                                                                        value={filterDetails.to_timestamp}
                                                                        minDate={minandmaxdate.to_min}
                                                                        maxDate={minandmaxdate.to_max}
                                                                        onChange={
                                                                            (e) => {
                                                                                setminandmaxdate({ ...minandmaxdate, from_max: new Date(e) })
                                                                                e = moment(new Date(e)).format("MM/DD/YYYY");
                                                                                setFilterDetails({ ...filterDetails, to_timestamp: e })
                                                                            }
                                                                        }
                                                                        renderInput={(params) => <TextField size="small" {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {state.user_detail.role_id == 1 &&
                                                        <Grid item xl={3} lg={4} md={4} xs={12} sm={12} className="rp-dropdown-main-item">
                                                            <Grid container>
                                                                <Grid item xl={12} lg={12} md={12} xs={12} sm={10}>
                                                                    <Grid container alignItems="center">
                                                                        <Grid item xl={4} lg={4} md={5} sm={3} xs={12} className='tp_select_item'>
                                                                            <span>Select Agent</span>
                                                                        </Grid>
                                                                        <Grid item xl={8} lg={7} md={7} sm={7} xs={12} >
                                                                            <FormControl fullWidth  >
                                                                                <Autocomplete
                                                                                    disablePortal
                                                                                    id="combo-box-demo"
                                                                                    onChange={(e, newvalue) => {
                                                                                        if (!newvalue) {
                                                                                            setSelectstateAgent({ "user_id": 0, "fullName": "" })
                                                                                        } else {
                                                                                            setSelectstateAgent(newvalue)
                                                                                        }
                                                                                        if (newvalue) {
                                                                                            setFilterDetails({ ...filterDetails, agent_id: newvalue.user_id })
                                                                                        } else {
                                                                                            setFilterDetails({ ...filterDetails, agent_id: 0 })
                                                                                        }
                                                                                    }}
                                                                                    value={selectstateAgent}
                                                                                    options={(agentDataoption)}
                                                                                    defaultValue={agentData}
                                                                                    getOptionLabel={(option) => option.fullName}
                                                                                    renderInput={(params) => <TextField {...params} size="small" variant="outlined" placeholder='Select Agent' />}

                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }

                                                    <Grid item xl={1} lg={1.5} md={1.5} sm={3} xs={5}>
                                                        <Button variant='contained' fullWidth className='rp-search-btn' onClick={searchFilterData}>Search</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>

                                        <Grid item xs={12}>
                                            <Grid container className='np-table-white-container2'>
                                                <DataGrid
                                                    className='profile-datagrid'
                                                    autoHeight
                                                    rows={userData}
                                                    columns={userColumns}
                                                    density="compact"
                                                    // options={options} 
                                                    pageSize={10}
                                                    rowsPerPageOptions={[10]}
                                                    getRowId={(userData) => (userData.user_profile_id)}
                                                    disableSelectionOnClick
                                                    experimentalFeatures={{ newEditingApi: true }}
                                                    components={{
                                                        Toolbar: GridToolbar, NoRowsOverlay
                                                    }}

                                                    onCellClick={(params) => {
                                                        if (params.field == "user_profile_name") {
                                                            let row = params.row
                                                            handleOpenUserProfile(row.user_profile_id)
                                                        }
                                                    }}
                                                    sx={{
                                                        '& .MuiDataGrid-cell:hover': {
                                                            color: 'primary.main',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Box >
                                            <AddCircleIcon fontSize="large" onClick={() => { navigate('/userprofile', { replace: true }) }} className="np-add-edit-circle-btn" />
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open1}
                onClose={handleOptionClose}
                className="profile-menu-list"
                TransitionComponent={Fade}
            >
                {
                    (selectedAgent.sample_profile != true) ?
                        (
                            <>
                                {
                                    (state.user_detail.role_id == 1) &&
                                    (
                                        <>
                                            <MenuItem
                                                onClick={() => { setAssignAgent(true); }}
                                                className="optionsClass">
                                                <ShareIcon fontSize="small" />&nbsp; Assign to Agent
                                            </MenuItem>
                                        </>
                                    )
                                }

                                <MenuItem
                                    onClick={(e) => { openEditProfilePage(e) }}
                                    className="optionsClass">
                                    <EditOutlinedIcon fontSize="small" />&nbsp; Edit
                                </MenuItem>

                                {
                                    (!selectedAgent.url_deleted && selectedAgent.url_created) &&
                                    <MenuItem
                                        onClick={() => { { setFlagDeleteAgentPopup(true) } }}
                                        className="optionsClass">
                                        <LinkOffIcon fontSize="small" />&nbsp; Deactivate Share
                                    </MenuItem>
                                }

                                <MenuItem
                                    onClick={() => { setDeleteUserProfile(true) }}
                                    className="optionsClass">
                                    <DeleteOutlineIcon fontSize="small" />&nbsp;  Delete
                                </MenuItem>
                            </>
                        ) : (
                            <MenuItem
                                className="optionsClass"
                                onClick={saveProfileForUserProfile}>
                                <SaveIcon fontSize="small" />&nbsp; Save Profile for User
                            </MenuItem>
                        )

                }

            </Menu>

            <Footer />
        </>
    );
}

export default NewProfile;