import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, FormLabel, Grid, Stack, TextField, Typography } from '@mui/material'
import "./ForgotPassword.css";
import Header from "../../GlobalComponent/Header/Header";
import Footer from "../../GlobalComponent/Footer/Footer";
import { Box } from "@mui/system";
import LoginBG from '../../assets/Images/LoginBG.png'
import Loginblureimg from '../../assets/Images/Loginblureimg.png'
// import Blueimg from '../../assets/Images/Blueimg.png'
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import { Context as mainContext } from '../../Context/auth_context'
import { MobileFooter } from "../../GlobalComponent/MobileFooter/MobileFooter";
import { forgotpasswordApiCall } from "../../service/login.service";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [responseData, setResponseData] = useState();
    const [disableBtn, setDisableBtn] = useState(false);
    const { UpdateLoader, state } = useContext(mainContext)
    const navigate = useNavigate();

    const [showMessage, setShowMessage] = useState({
        'status': false,
        'error': false,
        'message': "Test"
    })


    const changeMail = (e) => {
        setEmail(e.target.value);
    }




    const forgotPass = () => {

        let obj = {
            email: email
        }

        const emailRegex = /^([a-zA-Z0-9\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/

        if (!email) {
            setShowMessage({
                'status': false,
                'error': true,
                'message': "Please Fill Field"
            })
            return
        }
        else if (!emailRegex.test(email)) {
            setShowMessage({
                'status': false,
                'error': true,
                'message': "Invalid Email Adress!"
            })
            return
        }

        else {

            forgotpasswordApiCall(obj, (res) => {
                setResponseData(res);
                UpdateLoader(false);
                if (res.data.status === true) {
                    setDisableBtn(true);
                    setShowMessage({
                        'status': true,
                        'error': false,
                        'message': "Email sent successfully!"
                    })
                }
                else {
                    if (res.data.error.code == 9026 || res.data.error.code == 9025) {
                        setShowMessage({
                            'status': false,
                            'error': true,
                            'message': res.data.error.message
                        })
                    }

                    else {
                        if (res.data.error.code == 9001) {
                            setShowMessage({
                                'status': false,
                                'error': true,
                                'message': "User not found!"
                            })
                        } else {

                            setShowMessage({
                                'status': false,
                                'error': true,
                                'message': res.data.error.message
                            })
                        }
                    }
                }

            }, (err) => {
                console.log(err);
                UpdateLoader(false);
                setShowMessage({
                    'status': false,
                    'error': true,
                    'message': "Something went wrong."
                })
            })
        }
    }

    return (
        <>
            <Header />
            <Grid container className="forp-main-contaiber">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="forp-img-main-item">
                    <Grid container className="forp-img-main-container">
                        <Grid item xl={4} lg={5} md={7} sm={9} xs={10} className="forget-blure-img-item" >
                            <Grid container className="forp-items-center-container">
                                <Grid item xl={8} lg={9} md={9} sm={9} xs={11}>
                                    <Grid container className="forp-fields-main-container">

                                        <Grid item xs={12}>
                                            <Grid container className="forp-fields-sub-container">
                                                <Grid item xs={12}>
                                                    <Typography id="forp-tittle">Forgot Password</Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="forp-email-field-container">
                                                <Grid item xs={12} className="resMessage">
                                                    {showMessage.error && <Alert severity="error">{showMessage.message}</Alert>}
                                                    {showMessage.status && <Alert severity="success">{showMessage.message}</Alert>}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container className="forp-email-field-label">
                                                        <Typography id="forp-fields-label">Email Address</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="forp-email-textfield-container">
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className="forp-email-textfield"
                                                                id="outlined-size-small"
                                                                size="small"
                                                                placeholder="Enter Email"
                                                                onChange={changeMail}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" className="person-icon">
                                                                            <PersonIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="forp-create-btn-container">
                                                <Grid item xs={12}>
                                                    <Grid container className="forp-btn-container">
                                                        {!disableBtn && <Button variant="contained" id="forp-btn-text" onClick={forgotPass} >Forgot Password</Button>}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className="backToLogin">
                                                    <b className="loginPageBtn" onClick={() => { navigate("/login") }} >Back to Login Page</b>
                                                </Grid>
                                            </Grid>
                                        </Grid>



                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
            <MobileFooter className="mobilefooterResposive" />
        </>
    )

}

export default ForgotPassword;