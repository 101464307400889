import { Alert, Autocomplete, Button, Dialog, DialogContent, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, InputLabel, Link, OutlinedInput, RadioGroup, Snackbar, TextField, Typography } from "@mui/material";
import { span } from "@mui/system";
import Header from "../../GlobalComponent/Header/Header";
import './UserProfile.css';
import React, { useContext, useEffect, useReducer } from 'react'
import Radio from '@mui/material/Radio';

// import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { useFormControl } from '@mui/material/FormControl';
import UserProfileiInput from '../../assets/Images/UserProfileiInput.png';
// add new
import AddIcon from '@mui/icons-material/Add';
import { blue } from "@mui/material/colors";
import { AccountBalanceWalletRounded, ArrowRight, Cancel } from '@mui/icons-material';
import { Context as mainContext } from '../../Context/auth_context'
import { createNewUserProfile, getCompanyAgentList, getparticularUserProfileDetail, updateUserProfile } from '../../service/login.service';

import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Navigate, useNavigate } from "react-router-dom";

// dollar icon
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PercentIcon from '@mui/icons-material/Percent';
import { useSearchParams } from "react-router-dom";
import iframeDialogue from "../../GlobalComponent/ShareClientProfilePopup/ShareClientProfilePopup"
import { useState } from "react";
import Warningmark from '../../assets/Images/Warningmark.png'
import Sucessmark from '../../assets/Images/Sucessmark.png'



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function MyFormHelperText() {
  const { focused } = useFormControl() || {};

  const helperText = React.useMemo(() => {
    if (focused) {
      return 'This field is being focused';
    }
    return 'Helper text';
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
}

const FormReducer = (state, action) => {
  switch (action.type) {
    case "updateField": {
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          [action.fieldName]: action.fieldValue
        }
      }
    }
    case "updateAllField": {
      let { payload } = action;
      return { ...state, ...payload }
    }
  }
}

const initialValue = {
  "universalInput": {
    firstName: "",
    currentAge: "",
    ageAtRetirement: "",
    Contact: "",
    email: ""
  },
  "note": {
    note: ""
  },
  "IUL": {
    lumpSumPremium: "",
    rateOfReturn: "",
    annualSpendableIncome: "",
    incomeDuration: "",
    incomeTaxRate: "",
  },
  "typicalPlan": {
    user_tpi_monthly_flag: "",
    user_tpi_annually_flag: "",
    currentAccountBalance: "",
    percentFees: "",
    employerMatch: "",
    rateOfReturnDuringRetirement: "",
    rateOfReturnDuringWorkingYears: "",
    incomeTaxRateBeforeRetirement: "",
    incomeTaxRateDuringRetirement: "",
  },
  "IULFeeDetails": {
    IULillustration: "",
  },
  "IULAccountValueDetail": {
    IULillustration: "",
  },
  "IULDeathBenifits": {
    IULillustration: "",
  },
  "AssignProfile": {
    agentId: "",
  }
}


const UserProfile = () => {

  const [textAreaInput, setTextAreaInput] = React.useState("");
  const { UpdateLoader, state } = useContext(mainContext)
  const [stateForm, dispatch] = useReducer(FormReducer, initialValue)
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [adminEnable, setAdminEnable] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('a');
  const [openIframePopup, setOpenIframePopup] = React.useState(false);
  const [profileIdUpdate, setProfileIdUpdate] = React.useState(false);
  const [iulAnnualPremium, setIulAnnualPremium] = React.useState([{
    annual_premium: "",
    duration: ""
  }]);
  const [typicalPlanInput, setTypicalPlanInput] = React.useState([{
    total_contribution: "",
    duration: ""
  }]);
  const [agentList, setAgentList] = React.useState([{
    user_id: "",
    user_firstname: "",
    user_lastname: "",
    user_email: "",
    user_status_name: ""
  }]);
  const [agentValue, setAgentValue] = useState("");
  const [successFullPopup, setsuccessFullPopup] = React.useState(
    {
      open: true,
      successMsg: "hi how are you jems!!!",
    }
  )
  const [message, setMessage] = React.useState({
    status: false,
    message: "",
    error: "",
  });
  const [agentInputName, setAgentInputName] = useState("");
  const [backupAgentNameOptions, setBackupAgentNameOptions] = useState([]);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [agent, setAgent] = React.useState("");
  const [mainUserProfileDetail, setMainUserProfileDetail] = React.useState({});
  const [btnmsg, setBtnmsg] = React.useState("Create Profile");
  const [orderAscDesc, setOrderAscDesc] = React.useState('')
  const [filterTableFlag, setFilterTableFlag] = React.useState(false)
  const [agentProfileData, setAgentProfileData] = React.useState([{}]);
  const [filterTable, setFilterTable] = React.useState(false)
  const [alreadySelectedAgent, setAlreadySelectedAgent] = React.useState(0)
  const [openErrorPopup, setopenErrorPopup] = React.useState(false)

  const [openCreatedPopup, setopenCreatedPopup] = React.useState(false)
  const [openCreatedErrorPopup, setopenCreatedErrorPopup] = React.useState({
    status: false,
    message: "",
    profileId: 0,
  })

  const [openUpdatedPopup, setopenUpdatedPopup] = React.useState(false)
  const [openUpdatedErrorPopup, setopenUpdatedErrorPopup] = React.useState({
    status: false,
    message: ""
  })

  const [openUpdatedSamlePopup, setopenUpdatedSamlePopup] = React.useState(false)
  const [openUpdatedSampleErrorPopup, setopenUpdatedSampleErrorPopup] = React.useState({
    status: false,
    message: ""
  })


  const [errorList, setErrorList] = useState([{}])



  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () =>
      window.removeEventListener('message', handleMessage);
  }
  )

  const closeSuccessfullmsg = () => {
    navigate("/profile/calculators?id=" + profileIdUpdate)
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function handleMessage(event) {
    if (event.origin != 'https://www.retirementbrain.io:8081')
      return
    // if(event.origin!='http://localhost')
    //     return

    var messageFromSender = event.data;
    let val = parseText(messageFromSender);
    val = val.replace(/\*/g, '')
    if (textAreaInput)
      ChangeFieldValue(textAreaInput, "IULillustration", val)
    setOpenIframePopup(false)
  }

  function numberWithCommas(n) {
    if (!n) return n
    return n.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  function parseText(str) {
    var data = str.split("+@@+")

    for (var i = 0; i < data.length - 1; i++) {
      data[i] = data[i].split("?!#!?")
    }
    let count = data.length - 1
    if (count > 5)
      count = 5

    var str = [];
    for (var i = 0; i < count; i++) {
      for (var j = 0; j < data[i].length; j++) {
        if (str[j] == undefined)
          str[j] = data[i][j]
        else
          str[j] = str[j] + "       " + data[i][j]
      }
    }
    var tStr = str.join(" \n ")
    tStr = tStr.replace(/\(/gi, "").replace(/\)/gi, "").replace(/{/gi, "").replace(/}/gi, "").replace(/\]/gi, "").replace(/\[/gi, "")
    return tStr;
  }

  const handleClose1 = (event, reason) => {
    setMessage({
      status: false,
      message: "",
      error: "",
    })
  };

  const formatUpdateProfileData = (data) => {
    var arr = ["IUL", "typicalPlan"]
    if (data["typicalPlan"]["user_tpi_contribution_data"]) {
      for (var i in data["typicalPlan"]["user_tpi_contribution_data"]) {
        data["typicalPlan"]["user_tpi_contribution_data"][i]['annualContribution'] = numberWithCommas(data["typicalPlan"]["user_tpi_contribution_data"][i]['annualContribution'])
      }
    }
    if (data["IUL"]["user_iul_annual_premium_data"]) {
      for (var i in data["IUL"]["user_iul_annual_premium_data"]) {
        data["IUL"]["user_iul_annual_premium_data"][i]['annualPremium'] = numberWithCommas(data["IUL"]["user_iul_annual_premium_data"][i]['annualPremium'])
      }
    }
    for (var i in arr) {
      let keys = Object.keys(data[arr[i]])
      if (keys && typeof keys == "object") {
        for (var j in keys) {
          if (typeof data[arr[i]][keys[j]] != "object") {
            data[arr[i]][keys[j]] = numberWithCommas(data[arr[i]][keys[j]])
          }
        }
      }
    }
    let tt = data;
    return data
  }

  useEffect(() => {
    let id = searchParams.get("profile_id");

    if (id) {
      setProfileIdUpdate(id)
      let data = getparticularUserProfileDetail(id, (res) => {
        // console.log("get user details response :-", res);
        var formatData = formatUpdateProfileData(res.data.inputData[0])
        dispatch({
          type: "updateAllField",
          payload: formatData
        })
        setMainUserProfileDetail(res.data)

        if (formatData.sample_profile == true && state.user_detail.role_id == 1) {
          setAdminEnable(true)
        }

        let IULAccountValueDetail_temp = formatData['IULAccountValueDetail']['IULillustration']
        IULAccountValueDetail_temp = (IULAccountValueDetail_temp) ? IULAccountValueDetail_temp.join("\n") : ''

        let IULDeathBenifits_temp = formatData['IULDeathBenifits']['IULillustration']
        IULDeathBenifits_temp = (IULDeathBenifits_temp) ? IULDeathBenifits_temp.join("\n") : ''

        let IULFeeDetails_temp = formatData['IULFeeDetails']['IULillustration']
        IULFeeDetails_temp = (IULFeeDetails_temp) ? IULFeeDetails_temp.join("\n") : ''

        ChangeFieldValue("IULFeeDetails", "IULillustration", IULFeeDetails_temp)
        ChangeFieldValue("IULAccountValueDetail", "IULillustration", IULAccountValueDetail_temp)
        ChangeFieldValue("IULDeathBenifits", "IULillustration", IULDeathBenifits_temp)

        if (formatData.sample_profile) {
          // ChangeFieldValue("universalInput", "firstName", "")
          ChangeFieldValue("universalInput", "Contact", "")
          ChangeFieldValue("universalInput", "email", "")
          setBtnmsg("Save Profile")
        } else {
          // setBtnmsg("Save and Return")
          setBtnmsg("Update Profile")
        }
        let fData = formatData
        if (fData.IUL.user_iul_annual_premium_data) {
          let finalTPICData = [...fData.IUL.user_iul_annual_premium_data]
          finalTPICData.map((rr, i) => {
            rr['annual_premium'] = rr['annualPremium']
          })
          setIulAnnualPremium(finalTPICData)
        }
        if (fData.typicalPlan.user_tpi_contribution_data) {
          let finalTPICData = [...fData.typicalPlan.user_tpi_contribution_data]
          finalTPICData.map((rr, i) => {
            rr['total_contribution'] = rr['annualContribution']
          })
          setTypicalPlanInput(finalTPICData)
        }

        getCompanyAgentList((response) => {
          let data = response.data.data[0]
          data = data?.agents
          data = data.filter(rr => rr.user_status_id == 2 && rr.user_role_id == 2)
          data.map(rr => (rr.name = (rr.user_firstname + " " + rr.user_lastname)))
          // let gg = data.findIndex((rr,i)=>{if(rr.user_id ==stateForm.AssignProfile.agentId){return i}})
          if (res.data.inputData[0]?.AssignProfile?.agentId) {
            var gg = data.findIndex(rr => rr.user_id == res.data.inputData[0].AssignProfile.agentId)
            setAlreadySelectedAgent(gg)
            if (data?.length > 0 && gg !== -1) {

              // console.log("Printing data and gg", data, gg)
              setAgentValue(data[gg]?.user_firstname + " " + data[gg]?.user_lastname)
              setAgentInputName(data[gg]?.user_firstname + " " + data[gg]?.user_lastname)
            }
          }
          setAgentList(data);
          setBackupAgentNameOptions(data);
        }, (err) => {
          console.log("getCompanyAgentList error")
          console.log(err)
        })


      }, (err) => {
        // console.log(err)
      })
    }
    else {
      getCompanyAgentList((response) => {
        let data = response.data.data[0]
        data = data?.agents
        data = data.filter(rr => rr.user_status_id == 2 && rr.user_role_id == 2)
        data.map(rr => (rr.name = (rr.user_firstname + " " + rr.user_lastname)))

        setAgentList(data);
        setBackupAgentNameOptions(data);
      }, (err) => {
        console.log("getCompanyAgentList error")
        console.log(err)
      })
    }
  }, [])


  const addIULAnnualPremium = () => {
    let tempiulAnnualPremium = [...iulAnnualPremium];
    tempiulAnnualPremium.push({
      annual_premium: "",
      duration: ""
    })
    setIulAnnualPremium(tempiulAnnualPremium)
  }

  const addTypicalPlanInput = () => {
    let temptypicalPlanInput = [...typicalPlanInput];
    temptypicalPlanInput.push({
      total_contribution: "",
      duration: ""
    })
    setTypicalPlanInput(temptypicalPlanInput)
  }

  const removeiulAnnualPremium = (e) => {
    let tempiulAnnualPremium = [...iulAnnualPremium];
    if (tempiulAnnualPremium.length == 1) {
      tempiulAnnualPremium = ([
        {
          annual_premium: "",
          duration: ""
        }
      ])
    } else {
      tempiulAnnualPremium.splice(e, 1);
    }

    setIulAnnualPremium(tempiulAnnualPremium)
  }

  const removetypicalPlanInput = (e) => {
    let temptypicalPlanInput = [...typicalPlanInput];

    if (temptypicalPlanInput.length == 1) {
      temptypicalPlanInput = ([
        {
          total_contribution: "",
          duration: ""
        }
      ])
    } else {
      temptypicalPlanInput.splice(e, 1);
    }
    setTypicalPlanInput(temptypicalPlanInput)
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const onChangeInInput = (event, i, field) => {
    let tempiulAnnualPremium = [...iulAnnualPremium];
    tempiulAnnualPremium.map((rr, d) => {
      if (d == i) {
        if (field == "annual_premium") {
          let a_value = event.target.value
          a_value = a_value.replace(/,/gi, '')
          rr['annual_premium'] = (a_value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        }
        if (field == "duration") {
          rr['duration'] = event.target.value;
        }
      }
      return rr
    })
    setIulAnnualPremium(tempiulAnnualPremium)
  }

  const onChangeInInputTypical = (event, i, field) => {
    let temptypicalPlanInput = [...typicalPlanInput];
    temptypicalPlanInput.map((rr, d) => {
      if (d == i) {
        if (field == "total_contribution") {
          let a_value = event.target.value
          a_value = a_value.replace(/,/gi, '')
          rr['total_contribution'] = (a_value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if (field == "duration") {
          rr['duration'] = event.target.value;
        }
      }
      return rr
    })
    setTypicalPlanInput(temptypicalPlanInput)
  }

  // input type numbers and percentage functions
  const ChangeFieldValue = (formName, fieldName, fieldValue) => {
    var arr = ["lumpSumPremium", "annualSpendableIncome", "incomeDuration", "Contribution", "currentAccountBalance", "employerMatch", "currentAge", "ageAtRetirement"]
    var checkPercentagearr = ["rateOfReturn", "incomeTaxRate", "percentFees", "rateOfReturn", "rateOfReturnDuringRetirement", "rateOfReturnDuringWorkingYears", "incomeTaxRateBeforeRetirement", "incomeTaxRateDuringRetirement"]

    if (arr.indexOf(fieldName) > -1) {
      let a_value = fieldValue
      a_value = a_value.replace(/,/gi, '')
      fieldValue = (a_value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (checkPercentagearr.indexOf(fieldName) > -1 && (fieldValue < 0 || fieldValue > 100)) {
      return;
    }
    dispatch({
      type: "updateField",
      formName,
      fieldName,
      fieldValue
    })
  }

  const refreshPage = () => {
    if (openCreatedErrorPopup.profileId)
      window.location.href = "userprofile?profile_id=" + openCreatedErrorPopup.profileId;
    else
      window.reload()
  }

  const validation = (stat) => {

    var mainKeys = Object.keys(stateForm);
    var errorFlag = false;
    var errorArr = []
    for (var i in mainKeys) {

      if (mainKeys[i] == "AssignProfile" || mainKeys[i] == "note") {
        continue
      }
      if (stateForm[mainKeys[i]] && typeof stateForm[mainKeys[i]] == "object") {
        let subKeys = Object.keys(stateForm[mainKeys[i]]);
        for (var j in subKeys) {
          if (!stat) {
            if (subKeys[j] == "email" || subKeys[j] == "firstName" || subKeys[j] == "Contact") {
              continue
            }
          }
          if (!stateForm[mainKeys[i]][subKeys[j]]) {
            if (subKeys[j] == "user_tpi_annually_flag" || subKeys[j] == "user_tpi_monthly_flag" || subKeys[j] == "agentId") {
              if (stateForm[mainKeys[i]]["user_tpi_annually_flag"] && stateForm[mainKeys[i]]["user_tpi_monthly_flag"] || !stateForm[mainKeys[i]]["user_tpi_annually_flag"] && !stateForm[mainKeys[i]]["user_tpi_monthly_flag"]) {
                errorFlag = true
                errorArr.push(subKeys[j])
                // break;
              }
            } else {
              errorFlag = true
              errorArr.push(subKeys[j])
              // break;
            }
          }
        }
      }
    }
    for (var i in iulAnnualPremium) {
      if (!iulAnnualPremium[i].annual_premium || !iulAnnualPremium[i].duration) {
        errorFlag = true
        break
      }
    }
    for (var i in typicalPlanInput) {
      if (!typicalPlanInput[i].total_contribution || !typicalPlanInput[i].duration) {
        errorFlag = true
        break
      }
    }
    if (!stateForm.IULFeeDetails.IULillustration) {
      errorFlag = true
      errorArr.push("IULFeeDetails")
    }
    if (!stateForm.IULAccountValueDetail.IULillustration) {
      errorFlag = true
      errorArr.push("IULAccountValueDetail")
    }
    if (!stateForm.IULDeathBenifits.IULillustration) {
      errorFlag = true
      errorArr.push("IULDeathBenifits")
    }
    if (errorFlag) {
      let error = [...errorList]
      for (var i in errorArr) {
        error[errorArr[i]] = true;
      }
      setErrorList(error);
      UpdateLoader(false)
      setopenErrorPopup(true)
      return false

    }
    return true

  }

  const SelectAgentDropdown = (t, e) => {
    ChangeFieldValue("AssignProfile", "agentId", e.user_id)
  }
  console.log("state-", stateForm.IULFeeDetails.IULillustration);

  const createANewProfile = async (stat) => {

    let finalObj = {};
    if (!validation(stat)) {
      return;
    }
    finalObj['user_name'] = stateForm.universalInput.firstName
    finalObj['user_current_age'] = stateForm.universalInput.currentAge
    finalObj['user_retirement_age'] = stateForm.universalInput.ageAtRetirement
    finalObj['user_contact'] = stateForm.universalInput.Contact
    finalObj['user_email'] = stateForm.universalInput.email
    finalObj['user_notes'] = stateForm.note.note ? stateForm.note.note : ""
    finalObj['user_iul_annual_premium_data'] = iulAnnualPremium
    finalObj['user_tpi_contribution_data'] = typicalPlanInput
    finalObj['user_iul_lump_sum_premium'] = stateForm.IUL.lumpSumPremium.replace(",", "")

    finalObj['user_iul_rate_of_return'] = stateForm.IUL.rateOfReturn
    finalObj['user_iul_annual_spendable_income'] = stateForm.IUL.annualSpendableIncome.replace(",", "")
    finalObj['user_iul_income_duration'] = stateForm.IUL.incomeDuration
    finalObj['user_iul_it_rate_br'] = stateForm.IUL.incomeTaxRate
    finalObj['user_tpi_monthly_flag'] = false
    finalObj['user_tpi_annually_flag'] = false

    if (stateForm.typicalPlan.user_tpi_monthly_flag) {
      finalObj['user_tpi_monthly_flag'] = true;
    }
    if (stateForm.typicalPlan.user_tpi_annually_flag) {
      finalObj['user_tpi_annually_flag'] = true;
    }
    finalObj['user_tpi_curr_account_balance'] = stateForm.typicalPlan.currentAccountBalance.replace(",", "")
    finalObj['user_tpi_percent_fees'] = stateForm.typicalPlan.percentFees
    finalObj['user_tpi_employer_match'] = stateForm.typicalPlan.employerMatch.replace(",", "")
    finalObj['user_tpi_rate_of_return_dr'] = stateForm.typicalPlan.rateOfReturnDuringRetirement
    finalObj['user_tpi_rate_of_return_br'] = stateForm.typicalPlan.rateOfReturnDuringWorkingYears
    finalObj['user_tpi_it_rate_br'] = stateForm.typicalPlan.incomeTaxRateBeforeRetirement
    finalObj['user_tpi_it_rate_dr'] = stateForm.typicalPlan.incomeTaxRateDuringRetirement

    // IUL FEE DETAIL
    // finalObj['user_iul_fee_detail'] = stateForm.IULFeeDetails.IULillustration.replace(/,/g, "").replace(/\n+/gi, "\n").split("\n");
    finalObj['user_iul_fee_detail'] = stateForm?.IULFeeDetails?.IULillustration?.replace(/\$/g, "").replace(/,/g, "").replace(/\n+/gi, "\n").split("\n");

    // IUL ACCUNT VALUE DETAIL
    // finalObj['user_iul_account_value_detail'] = stateForm.IULAccountValueDetail.IULillustration.replace(/,/g, "").replace(/\n+/gi, "\n").split("\n");
    finalObj['user_iul_account_value_detail'] = stateForm?.IULAccountValueDetail?.IULillustration?.replace(/\$/g, "").replace(/,/g, "").replace(/\n+/gi, "\n").split("\n");


    // IUL DEATH BENEFITS
    // finalObj['user_iul_death_benefit'] = stateForm.IULDeathBenifits.IULillustration.replace(/,/g, "").replace(/\n+/gi, "\n").split("\n");
    finalObj['user_iul_death_benefit'] = stateForm?.IULDeathBenifits?.IULillustration?.replace(/\$/g, "").replace(/,/g, "").replace(/\n+/gi, "\n").split("\n");

    // finalObj['current_timestamp'] = new Date().getTime()
    if (stateForm.AssignProfile.agentId)
      finalObj['user_iul_assigned_agent_id'] = [stateForm.AssignProfile.agentId]
    else
      finalObj['user_iul_assigned_agent_id'] = []
    if (profileIdUpdate) {
      if (!mainUserProfileDetail.inputData[0].sample_profile || !stat) {
        finalObj["user_profile_id"] = profileIdUpdate;
      }
    }
    UpdateLoader(false)

    let msg = "Profile Created Successfully!"
    // if (profileIdUpdate) {
    //   if (!mainUserProfileDetail.inputData[0].sample_profile) {
    //     msg = "Profile Updated Successfully!"
    //     setopenUpdatedPopup(true)
    //   }
    // }

    if (profileIdUpdate) {

      if (mainUserProfileDetail.inputData[0].sample_profile && stat) {
        UpdateLoader(true)
        let createProfile = await createNewUserProfile(finalObj, (res) => {

          var createResponseData = res.data;
          let getId = createResponseData.data[0].user_profile_id

          if (createResponseData.status == false && createResponseData.error.code == 9038) {
            setProfileIdUpdate(getId)
            setopenCreatedPopup(true)
            setopenCreatedErrorPopup({
              status: true,
              message: createResponseData.error.message,
              profileId: getId
            })
            return
          }
          else {
            if (profileIdUpdate) {
              if (!mainUserProfileDetail.inputData[0].sample_profile) {
                msg = "Profile Updated Successfully!"
                setopenUpdatedPopup(true)
              }
            }
            setProfileIdUpdate(getId)
            setopenCreatedPopup(true)
            setopenCreatedErrorPopup({
              status: false,
              message: "Profile Created Successfully!"
            })
          }
          UpdateLoader(false)

        },
          (err) => {
            alert("Something Went Wrong!")
          });
        UpdateLoader(false)

      }
      else {

        UpdateLoader(true)
        let createProfile = await updateUserProfile(finalObj,
          (res) => {
            var responseUpdateData = res.data;
            if (responseUpdateData.status == false) {
              if (responseUpdateData.error.code == 9038) {
                setopenUpdatedPopup(true)
                setopenUpdatedErrorPopup({
                  status: true,
                  message: responseUpdateData.error.message
                })
                setProfileIdUpdate(profileIdUpdate)
              }
              else {
                if (!stat) {
                  setopenUpdatedSamlePopup(true)
                  setopenUpdatedSampleErrorPopup({
                    status: true,
                    message: responseUpdateData.error.message
                  })
                }
                else {
                  setopenUpdatedPopup(true)
                  setopenUpdatedErrorPopup({
                    status: true,
                    message: responseUpdateData.error.message
                  })
                  setProfileIdUpdate(profileIdUpdate)
                }
              }
            }
            else {
              setProfileIdUpdate(profileIdUpdate)
              if (!stat) {
                setopenUpdatedSamlePopup(true)
                setopenUpdatedSampleErrorPopup({
                  status: false,
                  message: "Sample Profile Updated Succesfully!"

                })
              }
              else {
                setopenUpdatedPopup(true)
                setopenUpdatedErrorPopup({
                  status: false,
                  message: "Profile Updated Successfully!"
                })
              }
              return
            }
            UpdateLoader(false)

          },
          (err) => {
            alert("Something Went Wrong!")
            UpdateLoader(false)
          })
      }
    }
    else {
      let createProfile = await createNewUserProfile(finalObj, (res) => {
        var createResponseData = res.data;
        let getId = createResponseData.data[0].user_profile_id

        if (createResponseData.status == false && createResponseData.error.code == 9038) {
          setProfileIdUpdate(getId)
          setopenCreatedPopup(true)
          setopenCreatedErrorPopup({
            status: true,
            message: createResponseData.error.message,
            profileId: getId
          })
          return
        }
        else {
          setProfileIdUpdate(getId)
          setopenCreatedPopup(true)
          setopenCreatedErrorPopup({
            status: false,
            message: "Profile Created Successfully!"
          })
        }
      },
        (err) => {
          alert("Something Went Wrong!")
        });
    }
    setMessage({
      status: false,
      message: msg,
      error: "",
    })
    sleep(10000)
    UpdateLoader(false)
    return;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openErrorPopup])

  const closeErrorPopup = () => {
    setopenErrorPopup(false);
  }

  return (
    <>
      {message.status &&
        <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
          <Alert onClose={handleClose1} severity={message.error == true ? "error" : "success"} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        </Snackbar>
      }
      {openIframePopup &&
        <Dialog
          open={true}
          fullWidth={true}
          maxWidth={"xl"}
          onClose={() => {
            setOpenIframePopup(false)
          }}
          aria-labelledby="responsive-dialog-title">
          <DialogContent >
            <iframe id="tabula" class="tabula_class" src="https://www.retirementbrain.io:8081/auth/1662619239061" height={700} frameborder="0" allowtransparency="true" style={{ width: "100%" }}></iframe>
            {/* <iframe id="tabula" class="tabula_class" src="http://localhost/test2.html" height={700} frameborder="0" allowtransparency="true" style={{width:"100%"}}></iframe> */}
          </DialogContent>
          <Grid container className="avl-save-email-popup-container" justifyContent={"center"} marginBottom={2}>
            <Grid item xs={2}>
              <Grid container className="avl-email-popup-btn">
                <Button variant="contained" id="avl-email-save-popup-btn" onClick={() => {
                  setOpenIframePopup(false)
                }}>Close</Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      }

      {openErrorPopup &&
        <>
          <Dialog
            open={openErrorPopup}
            // onClose={closeErrorPopup}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className="up-field-warining-img">
                      <img src={Warningmark} alt="" />
                    </Grid>
                    <Grid item xs={12} className="up-field-warining-text">
                      <Typography id="up-field-warining-label">Please fill all required fields</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center">
                        <Grid item xs={4} className="up-field-warining-btn">
                          <Button variant="contained" id="up-field-warining-btn" onClick={closeErrorPopup} >Close</Button>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      }

      {openCreatedPopup &&
        <>
          <Dialog
            open={openCreatedPopup}
            // onClose={closeErrorPopup}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
          >
            <DialogContent >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className="up-field-warining-img">
                      {(openCreatedErrorPopup.status == true) ?
                        (<img src={Warningmark} alt="" />) : (<img src={Sucessmark} alt="" />)
                      }

                    </Grid>
                    <Grid item xs={12} className="up-field-warining-text">
                      {openCreatedErrorPopup.status == true ? (
                        <Typography className="error-popup-label">
                          {openCreatedErrorPopup.message}
                        </Typography>
                      ) : (
                        <Typography className="error-popup-label">
                          {openCreatedErrorPopup.message}
                        </Typography>
                      )
                      }
                    </Grid>
                    <Grid item xs={12}>
                      {openCreatedErrorPopup.status == true ?
                        (<>
                          <Grid container justifyContent="center">
                            <Grid item xs={2.5} className="error-ce-popup-btn-item" >
                              <Button fullWidth variant="outlined" className="error-button2" onClick={() => { refreshPage() }}>Close</Button>
                            </Grid>
                            <Grid item xs={2.5} className="error-ce-popup-btn2-item">
                              <Button fullWidth variant="contained" className="error-button2" onClick={() => {
                                setopenCreatedErrorPopup({
                                  status: false,
                                  message: "Profile Created Successfully!",
                                  profileId: 0
                                })
                              }}>Ignore</Button>
                            </Grid>
                          </Grid>
                        </>) : (<>
                          <Grid container justifyContent={"center"}>
                            <Grid item xs={4} className="up-field-warining-btn">
                              <Button variant="contained" id="up-field-warining-btn" onClick={() => { closeSuccessfullmsg() }}>Okay</Button>
                            </Grid>
                          </Grid>
                        </>)
                      }
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      }

      {openUpdatedPopup &&
        <>
          <Dialog
            open={openUpdatedPopup}
            // onClose={closeErrorPopup}
            aria-labelledby="responsive-dialog-title"
            maxWidth={"sm"}
          >

            <DialogContent >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className="up-field-warining-img">
                      {(openUpdatedErrorPopup.status == true) ?
                        (<img src={Warningmark} alt="" />) : (<img src={Sucessmark} alt="" />)
                      }

                    </Grid>
                    <Grid item xs={12} className="up-field-warining-text">
                      {openUpdatedErrorPopup.status == true ? (
                        <Typography className="error-popup-label">
                          {openUpdatedErrorPopup.message}
                        </Typography>
                      ) : (
                        <Typography className="error-popup-label">
                          {openUpdatedErrorPopup.message}
                        </Typography>
                      )
                      }
                    </Grid>
                    <Grid item xs={12}>
                      {openUpdatedErrorPopup.status == true ?
                        (<>
                          <Grid container justifyContent="center">
                            <Grid item xs={2.5} className="error-ce-popup-btn-item" >
                              <Button fullWidth variant="outlined" className="error-button2" onClick={() => { setopenUpdatedPopup(false) }}>Close</Button>
                            </Grid>
                            <Grid item xs={2.5} className="error-ce-popup-btn2-item">
                              <Button fullWidth variant="contained" className="error-button2" onClick={() => {
                                setopenUpdatedErrorPopup({
                                  status: false,
                                  message: "Profile Updated Successfully!"
                                })
                              }}>Ignore</Button>
                            </Grid>
                          </Grid>

                        </>) : (<>
                          <Grid container justifyContent="center">
                            <Grid item xs={4} className="up-field-warining-btn" >
                              <Button variant="contained" id="up-field-warining-btn" onClick={() => { closeSuccessfullmsg() }}>Okay</Button>
                            </Grid>
                          </Grid>
                        </>)
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      }

      {openUpdatedSamlePopup &&
        <>
          <Dialog
            open={openUpdatedSamlePopup}
            // onClose={closeErrorPopup}
            aria-labelledby="responsive-dialog-title"
            maxWidth="sm"
          >
            <DialogContent >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} className="up-field-warining-img">
                      {(openUpdatedSampleErrorPopup.status == true) ?
                        (<img src={Warningmark} alt="" />) : (<img src={Sucessmark} alt="" />)
                      }

                    </Grid>
                    <Grid item xs={12} className="up-field-warining-text">
                      {openUpdatedSampleErrorPopup.status == true ? (
                        <Typography className="error-popup-label">
                          {openUpdatedSampleErrorPopup.message}
                        </Typography>
                      ) : (
                        <Typography className="error-popup-label">
                          {openUpdatedSampleErrorPopup.message}
                        </Typography>
                      )
                      }
                    </Grid>
                    <Grid item xs={12}>
                      {openUpdatedSampleErrorPopup.status == true ?
                        (<>
                          <Grid container justifyContent="center">
                            <Grid item xs={2.5} className="error-ce-popup-btn-item" >
                              <Button fullWidth variant="outlined" className="error-button2" onClick={() => { setopenUpdatedSamlePopup(false) }}>Close</Button>
                            </Grid>
                            <Grid item xs={2.5} className="error-ce-popup-btn2-item">
                              <Button fullWidth variant="contained" className="error-button2" onClick={() => {
                                setopenUpdatedSampleErrorPopup({
                                  status: false,
                                  message: "Sample Profile Updated Successfully!"
                                })
                              }}>Ignore</Button>
                            </Grid>
                          </Grid>
                        </>) : (<>
                          <Grid container justifyContent="center">
                            <Grid item xs={4} className="up-field-warining-btn">
                              <Button variant="contained" id="up-field-warining-btn" onClick={() => { closeSuccessfullmsg() }}>Okay</Button>
                            </Grid>
                          </Grid>
                        </>)
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </>
      }






      <Header />
      <Grid container className="up-input-main-conatiner" justifyContent="center" alignItem="center" marginTop={15} >
        <Grid item xl={11} lg={11} xs={11}>
          <Grid container className="up-input-main-img-container" >
            <Grid item xl={12} lg={12} xs={12} className="up-input-main-item" >
              <Grid container className="up-input-sub-heading" justifyContent="center">
                <span id="up-input-sub-heading">{(stateForm.sample_profile) ? "Create Profile" : btnmsg}</span>
              </Grid>
              {/* heading */}
              {/* <span id="up-input-sub-heading">{(stateForm.sample_profile)?"Create Profile":btnmsg}</span> */}
            </Grid>
            {/* MAIN ITEM-1 */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-input-universal-notes-item spanTopMar">
              <Grid container className="up-input-universal-notes-container">

                {/* UNIVERSAL CONTAINER */}
                <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11} className="up-input-universal-container  container-color" >
                  <Grid container>
                    {/* heading */}
                    <Grid item xs={12}>
                      <Grid container className="up-input-sub-heading">
                        <span id="up-input-sub-heading">Universal Inputs</span>
                      </Grid>
                    </Grid>

                    {/* Universal Input fields */}
                    <Grid item xs={12}>
                      <Grid container className="up-universal-field-container1">
                        {/* left */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-left-sub-field-container">
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Name <span id="up-input-astrisk">*</span></span>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-input-textfield">
                              <TextField

                                required
                                id="up-input-textfield"
                                autoComplete="off"
                                size="small"
                                value={stateForm?.universalInput?.firstName}
                                onChange={(e) => {
                                  ChangeFieldValue("universalInput", "firstName", e.target.value)
                                }} />
                              {errorList.firstName && <lable className="up-nput-textfield-validation">Please fill the Name field.</lable>}
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* right */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-right-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Current Age <span id="up-input-astrisk">*</span></span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField
                                required
                                autoComplete="off"
                                id="up-input-textfield" size="small" value={stateForm?.universalInput.currentAge} onChange={(e) => {
                                  ChangeFieldValue("universalInput", "currentAge", e.target.value)
                                }}
                                onKeyPress={(e) => {
                                  // alert(e.key)
                                  if (!/[0-913]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errorList.currentAge && <lable className="up-nput-textfield-validation">Please fill the Age field.</lable>}
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* left */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-left-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Age at Retirement <span id="up-input-astrisk">*</span></span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField
                                required
                                id="up-input-textfield"
                                size="small"
                                autoComplete="off"
                                value={stateForm?.universalInput.ageAtRetirement} onChange={(e) => {
                                  ChangeFieldValue("universalInput", "ageAtRetirement", e.target.value)
                                }}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errorList.ageAtRetirement && <lable className="up-nput-textfield-validation">Please fill the Age field.</lable>}
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* right */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-right-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Cell Phone <span id="up-input-astrisk">*</span></span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField required id="up-input-textfield" size="small" autoComplete="off" value={stateForm?.universalInput.Contact} onChange={(e) => {
                                ChangeFieldValue("universalInput", "Contact", e.target.value)
                              }}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errorList.Contact && <lable className="up-nput-textfield-validation">Please fill the Cell Phone field.</lable>}
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* left */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-left-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Email <span id="up-input-astrisk">*</span></span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField required id="up-input-textfield" size="small" autoComplete="off" value={stateForm?.universalInput.email} onChange={(e) => {
                                ChangeFieldValue("universalInput", "email", e.target.value)
                              }}

                              />
                              {errorList.email && <lable className="up-nput-textfield-validation">Please fill the email field.</lable>}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* NOTES */}
                <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11} className="up-notes-field-item ">
                  <Grid container className="up-notes-field-container1 container-color">
                    {/* heading */}
                    <Grid item xs={12}>
                      <Grid container className="up-input-sub-heading">
                        <span id="up-input-sub-heading">Notes</span>
                      </Grid>
                    </Grid>

                    {/* Notes field */}
                    <Grid item xs={12} className="up-input-notes-field">
                      <Grid container>
                        <TextField
                          className="up-input-notes"
                          id="outlined-multiline-static"
                          autoComplete="off"
                          multiline
                          rows={4}
                          fullWidth
                          value={stateForm?.note.note}
                          onChange={(e) => {
                            ChangeFieldValue("note", "note", e.target.value)
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            {/* MAIN ITEM-2 */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-input-iul-tp-item spanTopMar">
              <Grid container className="up-input-iul-tp-container">

                {/* IUL */}
                <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11} className="up-input-iul-container container-color">
                  <Grid container>
                    {/* heading */}
                    <Grid item xs={12}>
                      <Grid container className="up-input-sub-heading">
                        <span id="up-input-sub-heading">IUL Inputs</span>
                      </Grid>
                    </Grid>

                    {/* Iul Input fields */}
                    <Grid item xs={12}>
                      <Grid container className="up-iul-field-container2">

                        {iulAnnualPremium.map((rr, i) => {
                          return (
                            <>
                              {/* left */}
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} key="{i}">
                                <Grid container className="up-universal-left-sub-field-container">
                                  <Grid item xs={12} className="up-textfield-label">
                                    <span id="up-textfield-label">Annual Premium <span id="up-input-astrisk">*</span></span>
                                  </Grid>
                                  <Grid item xs={12} className="up-input-textfield">
                                    <TextField required id="up-input-textfield" size="small" type="text" autoComplete="off" value={rr.annual_premium}
                                      onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => { onChangeInInput(e, i, 'annual_premium') }}
                                      InputProps={{
                                        startAdornment: <AttachMoneyOutlinedIcon sx={{ fontSize: "20px" }} />
                                      }}
                                    />
                                    {errorList.annualPremium && <lable className="up-nput-textfield-validation">Please fill the Annual Premium field.</lable>}

                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* right */}
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Grid container className="up-universal-right-sub-field-container">
                                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-textfield-label">
                                    <span id="up-textfield-label">Duration (Years) <span id="up-input-astrisk">*</span> </span>
                                  </Grid>
                                  <Grid item xl={4.5} lg={4.5} md={4.5} sm={4.5} xs={4.5} className="up-duretion-input-textfield">
                                    <TextField required id="up-input-duretion-textfield" size="small" autoComplete="off" value={rr.duration} onChange={(e) => { onChangeInInput(e, i, 'duration') }} />
                                  </Grid>
                                  <Grid item xl={4} lg={4} md={2} sm={3} xs={3} className="duretion-cancle">
                                    <Cancel onClick={() => { removeiulAnnualPremium(i) }} />
                                  </Grid>
                                  {errorList.duration && <lable className="up-nput-textfield-validation">Please fill the Duration field.</lable>}
                                </Grid>
                              </Grid>
                            </>
                          )
                        })}


                        {/* Add New Annual Premium */}
                        <Grid item xs={10} textAlign="start">
                          <Grid container className="up-input-add-new">
                            <AddIcon id="up-input-add-icon" fontSize="small" /><div id="up-input-add-new"><Button variant="text" className="p-0" onClick={addIULAnnualPremium}>New Annual Premium</Button></div>
                          </Grid>
                        </Grid>

                        {/* left */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-left-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Lump sum Premium <span id="up-input-astrisk">*</span> </span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField id="up-input-textfield" size="small"
                                required
                                value={stateForm?.IUL.lumpSumPremium}
                                autoComplete="off"
                                onChange={(e) => {
                                  ChangeFieldValue("IUL", "lumpSumPremium", e.target.value)
                                }}
                                InputProps={{
                                  startAdornment: <AttachMoneyOutlinedIcon sx={{ fontSize: "20px" }} />
                                }}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}

                              />
                              {errorList.lumpSumPremium && <lable className="up-nput-textfield-validation">Please fill the Age field.</lable>}

                            </Grid>
                          </Grid>
                        </Grid>

                        {/* right */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-right-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Rate of Return (%) <span id="up-input-astrisk">*</span> </span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField id="up-input-textfield" size="small"
                                required
                                autoComplete="off"
                                value={stateForm?.IUL.rateOfReturn}
                                onChange={(e) => {
                                  ChangeFieldValue("IUL", "rateOfReturn", e.target.value)
                                }}
                                // InputProps={{
                                //   startAdornment: <PercentIcon sx={{ fontSize:"18px" }}/>
                                // }}
                                onKeyPress={(e) => {
                                  if (!/[0-9.]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errorList.rateOfReturn && <lable className="up-nput-textfield-validation">Please fill the Rate of Return field.</lable>}

                            </Grid>
                          </Grid>
                        </Grid>

                        {/* left */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-left-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Annual Spendable Income <span id="up-input-astrisk">*</span> </span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField id="up-input-textfield" size="small"
                                required
                                value={stateForm?.IUL.annualSpendableIncome}
                                autoComplete="off"
                                onChange={(e) => {
                                  ChangeFieldValue("IUL", "annualSpendableIncome", e.target.value)
                                }}
                                InputProps={{
                                  startAdornment: <AttachMoneyOutlinedIcon sx={{ fontSize: "20px" }} />
                                }}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}

                              />
                              {errorList.annualSpendableIncome && <lable className="up-nput-textfield-validation">Please fill the Rate of Return field.</lable>}
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* right */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-right-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Income Duration (Years) <span id="up-input-astrisk">*</span>  </span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField id="up-input-textfield" size="small"
                                autoComplete="off"
                                value={stateForm?.IUL.incomeDuration}

                                onChange={(e) => {
                                  ChangeFieldValue("IUL", "incomeDuration", e.target.value)
                                }}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errorList.incomeDuration && <lable className="up-nput-textfield-validation">Please fill the Income Duration field.</lable>}

                            </Grid>
                          </Grid>
                        </Grid>

                        {/* left */}
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Grid container className="up-universal-left-sub-field-container">
                            <Grid item xs={12} className="up-textfield-label">
                              <span id="up-textfield-label">Income Tax Rate (Before Retirement) (%) <span id="up-input-astrisk">*</span></span>
                            </Grid>
                            <Grid item xs={12} className="up-input-textfield">
                              <TextField id="up-input-textfield" size="small"
                                required
                                autoComplete="off"
                                value={stateForm?.IUL.incomeTaxRate}
                                onChange={(e) => {
                                  ChangeFieldValue("IUL", "incomeTaxRate", e.target.value)
                                }}

                                onKeyPress={(e) => {
                                  if (!/[0-9.]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {errorList.incomeTaxRate && <lable className="up-nput-textfield-validation">Please fill the Income Tax Rate field.</lable>}

                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* TYPICAL PLAN */}
                <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11} className="up-input-iul-container1 container-color">
                  {/* heading */}
                  <Grid item xs={12}>
                    <Grid container className="up-input-sub-heading">
                      <span id="up-input-sub-heading">Typical Plan Inputs</span>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container className="up-iul-field-container2">

                      {/* radio btn */}
                      <Grid item xs={12}>
                        <Grid container marginBottom="10px">

                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-input-payment-frequency">
                            <span id="up-input-payment-frequency">Payment Frequency <span id="up-input-astrisk">*</span></span>
                          </Grid>

                          <Grid item xl={2} lg={3} md={2} sm={3} xs={5} className="up-input-radio-btn" justifyContent="start" textAlign="start">
                            <Radio
                              checked={stateForm.typicalPlan.user_tpi_monthly_flag}
                              required
                              onChange={(e) => {
                                if (e.target.checked) {
                                  ChangeFieldValue("typicalPlan", "user_tpi_monthly_flag", e.target.checked)
                                  ChangeFieldValue("typicalPlan", "user_tpi_annually_flag", false)
                                }

                              }}
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  fontSize: 20,
                                },
                              }}
                              value="monthly"
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'A' }}
                            />
                            <span id="up-input-radio-font">Monthly</span>
                          </Grid>

                          <Grid item xl={2} lg={3} md={2} sm={3} xs={5} className="up-input-radio-btn" justifyContent="start" textAlign="start">
                            <Radio
                              checked={stateForm.typicalPlan.user_tpi_annually_flag}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  ChangeFieldValue("typicalPlan", "user_tpi_annually_flag", e.target.checked)
                                  ChangeFieldValue("typicalPlan", "user_tpi_monthly_flag", false)
                                }

                              }}
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  fontSize: 20,
                                },
                              }}
                              value="annually"
                              name="radio-buttons"
                              inputProps={{ 'aria-label': 'A' }}

                            />
                            <span id="up-input-radio-font">Annually</span>

                          </Grid>
                        </Grid>
                        <Grid item xs={10} textAlign="start">
                          {errorList.user_tpi_annually_flag && errorList.user_tpi_monthly_flag && <lable className="up-nput-textfield-validation">Please check the field</lable>}
                        </Grid>
                      </Grid>
                      {/* left */}
                      {
                        typicalPlanInput.map((rr, i) => {
                          return (
                            <>
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} key="{i}">
                                <Grid container className="up-universal-left-sub-field-container">
                                  <Grid item xs={12} className="up-textfield-label">
                                    <span id="up-textfield-label">Contribution <span id="up-input-astrisk">*</span></span>
                                  </Grid>
                                  <Grid item xs={12} className="up-input-textfield">
                                    <TextField
                                      required
                                      // variant="filled"
                                      id="up-input-textfield"
                                      size="small"
                                      autoComplete="off"
                                      value={rr.total_contribution}
                                      onChange={(e) => { onChangeInInputTypical(e, i, 'total_contribution') }}
                                      InputProps={{
                                        startAdornment: <AttachMoneyOutlinedIcon sx={{ fontSize: "20px" }} />
                                      }}
                                      onKeyPress={(e) => {
                                        if (!/[0-9]/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {errorList.total_contribution && <lable className="up-nput-textfield-validation">Please fill the Income Tax Rate field.</lable>}

                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* right */}
                              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                                <Grid container className="up-universal-right-sub-field-container">
                                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-textfield-label">
                                    <span id="up-textfield-label">Duration (Years) <span id="up-input-astrisk">*</span> </span>
                                  </Grid>
                                  <Grid item xl={4.5} lg={4.5} md={4.5} sm={4.5} xs={4.5} className="up-duretion-input-textfield">
                                    <TextField required id="up-input-duretion-textfield" size="small" autoComplete="off" value={rr.duration} onChange={(e) => { onChangeInInputTypical(e, i, 'duration') }} />
                                  </Grid>
                                  <Grid item xl={4} lg={4} md={2} sm={3} xs={3} className="duretion-cancle">
                                    <Cancel onClick={() => { removetypicalPlanInput(i) }} />
                                  </Grid>
                                  {errorList.total_contribution && <lable className="up-nput-textfield-validation">Please fill the Income Tax Rate field.</lable>}
                                </Grid>
                              </Grid>
                            </>

                          )
                        })
                      }
                      {/* Add New Annual Premium */}
                      <Grid item xs={10} textAlign="start">
                        <Grid container className="up-input-add-new">
                          <AddIcon id="up-input-add-icon" fontSize="small" /><div id="up-input-add-new"><Button variant="text" className="p-0" onClick={addTypicalPlanInput}>New Contribution</Button></div>
                        </Grid>
                      </Grid>


                      {/* left */}
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className="up-universal-left-sub-field-container">
                          <Grid item xs={12} className="up-textfield-label">
                            <span id="up-textfield-label">Current Account Balance <span id="up-input-astrisk">*</span></span>
                          </Grid>
                          <Grid item xs={12} className="up-input-textfield">
                            <TextField id="up-input-textfield" size="small" autoComplete="off" value={stateForm?.typicalPlan.currentAccountBalance}
                              required
                              onChange={(e) => {
                                ChangeFieldValue("typicalPlan", "currentAccountBalance", e.target.value)
                              }}
                              InputProps={{
                                startAdornment: <AttachMoneyOutlinedIcon sx={{ fontSize: "20px" }} />
                              }}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errorList.currentAccountBalance && <lable className="up-nput-textfield-validation">Please fill the Current Account Balance field.</lable>}

                          </Grid>
                        </Grid>
                      </Grid>

                      {/* right */}
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className="up-universal-right-sub-field-container">
                          <Grid item xs={12} className="up-textfield-label">
                            <span id="up-textfield-label">Percent Fees (%) <span id="up-input-astrisk">*</span></span>
                          </Grid>
                          <Grid item xs={12} className="up-input-textfield">
                            <TextField id="up-input-textfield" size="small"
                              value={stateForm?.typicalPlan.percentFees}
                              autoComplete="off"
                              required
                              onChange={(e) => {
                                ChangeFieldValue("typicalPlan", "percentFees", e.target.value)
                              }}
                              //  InputProps={{
                              //   startAdornment: <PercentIcon sx={{ fontSize:"18px" }}/>
                              // }}
                              onKeyPress={(e) => {
                                if (!/[0-9.]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}

                            />
                            {errorList.percentFees && <lable className="up-nput-textfield-validation">Please fill the Percent Fees field.</lable>}
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* left */}
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className="up-universal-left-sub-field-container">
                          <Grid item xs={12} className="up-textfield-label">
                            <span id="up-textfield-label">Employer Match <span id="up-input-braces-text">(Annual $ Contribution) <span id="up-input-astrisk">*</span></span> </span>
                          </Grid>
                          <Grid item xs={12} className="up-input-textfield">
                            <TextField id="up-input-textfield" size="small"
                              required
                              autoComplete="off"
                              value={stateForm?.typicalPlan.employerMatch}
                              onChange={(e) => {
                                ChangeFieldValue("typicalPlan", "employerMatch", e.target.value)
                              }}
                              InputProps={{
                                startAdornment: <AttachMoneyOutlinedIcon sx={{ fontSize: "20px" }} />
                              }}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errorList.employerMatch && <lable className="up-nput-textfield-validation">Please fill the Employer Match field.</lable>}
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* right */}
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className="up-universal-right-sub-field-container">
                          <Grid item xs={12} className="up-textfield-label">
                            <span id="up-textfield-label">Rate of Return during Retirement (%) <span id="up-input-astrisk">*</span></span>
                          </Grid>
                          <Grid item xs={12} className="up-input-textfield">
                            <TextField id="up-input-textfield" size="small"
                              required
                              autoComplete="off"
                              value={stateForm?.typicalPlan.rateOfReturnDuringRetirement}
                              onChange={(e) => {
                                ChangeFieldValue("typicalPlan", "rateOfReturnDuringRetirement", e.target.value)
                              }}
                              // InputProps={{
                              //   startAdornment: <PercentIcon sx={{ fontSize:"18px" }}/>
                              // }}
                              onKeyPress={(e) => {
                                if (!/[0-9.]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errorList.rateOfReturnDuringRetirement && <lable className="up-nput-textfield-validation">Please fill the Rate of Return during Retirement field.</lable>}
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* left */}
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className="up-universal-left-sub-field-container">
                          <Grid item xs={12} className="up-textfield-label">
                            <span id="up-textfield-label">Rate of Return during Working Years (%) <span id="up-input-astrisk">*</span></span>
                          </Grid>
                          <Grid item xs={12} className="up-input-textfield">
                            <TextField id="up-input-textfield" size="small"
                              autoComplete="off"
                              value={stateForm?.typicalPlan.rateOfReturnDuringWorkingYears}
                              required
                              onChange={(e) => {
                                ChangeFieldValue("typicalPlan", "rateOfReturnDuringWorkingYears", e.target.value)
                              }}
                              // InputProps={{
                              //   startAdornment: <PercentIcon sx={{ fontSize:"18px" }}/>
                              // }}
                              onKeyPress={(e) => {
                                if (!/[0-9.]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errorList.rateOfReturnDuringWorkingYears && <lable className="up-nput-textfield-validation">Please fill the Rate of Return during Working Years field.</lable>}

                          </Grid>
                        </Grid>
                      </Grid>

                      {/* right */}
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className="up-universal-right-sub-field-container">
                          <Grid item xs={12} className="up-textfield-label">
                            <span id="up-textfield-label">Income Tax Rate <span id="up-input-braces-text">(Before Retirement) (%) <span id="up-input-astrisk">*</span></span> </span>
                          </Grid>
                          <Grid item xs={12} className="up-input-textfield">
                            <TextField id="up-input-textfield" size="small"
                              value={stateForm?.typicalPlan.incomeTaxRateBeforeRetirement}
                              required
                              autoComplete="off"
                              onChange={(e) => {
                                ChangeFieldValue("typicalPlan", "incomeTaxRateBeforeRetirement", e.target.value)
                              }}
                              // InputProps={{
                              //   startAdornment: <PercentIcon sx={{ fontSize:"18px" }}/>
                              // }}
                              onKeyPress={(e) => {
                                if (!/[0-9.]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {errorList.incomeTaxRateBeforeRetirement && <lable className="up-nput-textfield-validation">Please fill the Income Tax Rate field.</lable>}

                          </Grid>
                        </Grid>
                      </Grid>

                      {/* left */}
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Grid container className="up-universal-left-sub-field-container">
                          <Grid item xs={12} className="up-textfield-label">
                            <span id="up-textfield-label">Income Tax Rate <span id="up-input-braces-text">(During Retirement) (%) <span id="up-input-astrisk">*</span></span> </span>
                          </Grid>
                          <Grid item xs={12} className="up-input-textfield">
                            <TextField id="up-input-textfield" size="small"
                              required
                              autoComplete="off"
                              value={stateForm?.typicalPlan.incomeTaxRateDuringRetirement}
                              onChange={(e) => {
                                ChangeFieldValue("typicalPlan", "incomeTaxRateDuringRetirement", e.target.value)
                              }}
                              // InputProps={{
                              //   startAdornment: <PercentIcon sx={{ fontSize:"18px" }}/>
                              // }}
                              onKeyPress={(e) => {
                                if (!/[0-9.]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Grid>

                          {errorList.incomeTaxRateDuringRetirement && <lable className="up-nput-textfield-validation">Please fill the Income Tax Rate field.</lable>}

                        </Grid>
                      </Grid>


                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* MAIN ITEM 3 */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-input-fee-acc-item spanTopMar">
              <Grid container className="up-input-fee-acc-container"  >
                {/* IUL FEE DETAILS */}
                <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11} className="up-input-iul-fee-container container-color">
                  <Grid container>

                    {/* heading */}
                    <Grid item xs={12}>
                      <Grid container className="up-input-sub-heading">
                        <span id="up-input-sub-heading">IUL Fee Detail</span>
                      </Grid>
                    </Grid>

                    {/* Iul fee details textfield */}
                    <Grid item xs={12}>
                      <Grid container className="up-iul-fee-field-container2">
                        <Grid item xs={6} className="up-input-fee-left-item">
                          <span id="ilu-fee-left-text">Data from IUL illustration <span id="up-input-astrisk">*</span></span>
                        </Grid>

                        <Grid item xs={6} className="up-input-fee-right-item" >
                          <Button variant="text" className="p-0" onClick={() => { setOpenIframePopup(true); setTextAreaInput("IULFeeDetails") }}>Extract From PDF</Button>
                        </Grid>

                        <Grid item xs={12} className="up-input-fee-notes-field">
                          <TextField
                            required
                            autoComplete="off"
                            className="up-input-notes"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            fullWidth
                            value={stateForm?.IULFeeDetails.IULillustration}
                            onKeyPress={(e) => {
                              // console.log(e.key)
                              if (((e.key != "Enter" && !/[0-9]/.test(e.key) && e.key != " "))) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/ +/gi, " ").replace(/,/gi, "")
                              ChangeFieldValue("IULFeeDetails", "IULillustration", e.target.value)
                            }}
                          />

                        </Grid>
                        <Grid item xs={12} textAlign="start" marginLeft="40px">
                          {errorList.IULFeeDetails && <lable className="up-nput-textfield-validation">Please fill the Iul Fee Details field.</lable>}
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                {/* IUL Account Value Detail */}
                <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11} className="up-input-iul-acc-value-container container-color">
                  <Grid container>

                    {/* heading */}
                    <Grid item xs={12}>
                      <Grid container className="up-input-sub-heading">
                        <span id="up-input-sub-heading">IUL Account Value Detail</span>
                      </Grid>
                    </Grid>

                    {/* Iul fee details textfield */}
                    <Grid item xs={12}>
                      <Grid container className="up-iul-fee-field-container2">

                        <Grid item xs={6} className="up-input-fee-left-item">
                          <span id="ilu-fee-left-text">Data from IUL illustration <span id="up-input-astrisk">*</span></span>
                        </Grid>

                        <Grid item xs={6} className="up-input-fee-right-item" >

                          <Button variant="text" className="p-0" onClick={() => { setOpenIframePopup(true); setTextAreaInput("IULAccountValueDetail") }}>Extract From PDF</Button>


                        </Grid>

                        <Grid item xs={12} className="up-input-fee-notes-field">
                          <TextField
                            required
                            autoComplete="off"
                            className="up-input-notes"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            fullWidth
                            value={stateForm?.IULAccountValueDetail.IULillustration}
                            // onKeyPress={(e) => {
                            //   if (!/[0-9]/.test(e.key)) {
                            //     e.preventDefault();
                            //   }
                            // }}
                            onKeyPress={(e) => {
                              if (((e.key != "Enter" && !/[0-9]/.test(e.key) && e.key != " "))) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/ +/gi, " ").replace(/,/gi, "")
                              ChangeFieldValue("IULAccountValueDetail", "IULillustration", e.target.value)
                            }}
                          />

                        </Grid>
                        <Grid item xs={12} textAlign="start" marginLeft="40px">
                          {errorList.IULAccountValueDetail && <lable className="up-nput-textfield-validation">Please fill the Iul Account value Details field.</lable>}
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* MAIN ITEM 4 */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-input-death-profile-item spanTopMar">
              <Grid container className="up-input-death-profile-container" justifyContent="center" >
                {/* IUL FEE DETAILS */}
                <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11} className="up-input-death-container container-color" justifyContent="center">
                  <Grid container>


                    {/* heading */}
                    <Grid item xs={12}>
                      <Grid container className="up-input-sub-heading">
                        <span id="up-input-sub-heading">IUL Death Benefits</span>
                      </Grid>
                    </Grid>

                    {/* Iul fee details textfield */}
                    <Grid item xs={12}>
                      <Grid container className="up-iul-death-field-container3" >
                        <Grid item xs={6} className="up-input-fee-left-item" >
                          <span id="ilu-fee-left-text">Data from IUL illustration <span id="up-input-astrisk">*</span></span>
                        </Grid>

                        <Grid item xs={6} className="up-input-fee-right-item" >
                          <Button variant="text" className="p-0" onClick={() => { setOpenIframePopup(true); setTextAreaInput("IULDeathBenifits") }}>Extract From PDF</Button>


                        </Grid>

                        <Grid item xs={12} className="up-input-fee-notes-field">
                          <TextField
                            required
                            autoComplete="off"
                            className="up-input-notes"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            fullWidth
                            value={stateForm?.IULDeathBenifits.IULillustration}
                            onKeyPress={(e) => {

                              if (((e.key != "Enter" && !/[0-9]/.test(e.key) && e.key != " "))) {
                                e.preventDefault();
                              }
                            }}
                            // onKeyPress={(e) => {
                            //   if (!/[0-9]/.test(e.key)) {
                            //     e.preventDefault();
                            //   }
                            // }}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/ +/gi, " ").replace(/,/gi, "")
                              ChangeFieldValue("IULDeathBenifits", "IULillustration", e.target.value)
                            }}
                          />

                        </Grid>

                        <Grid item xs={12} textAlign="start" marginLeft="40px">
                          {errorList.IULDeathBenifits && <lable className="up-nput-textfield-validation">Please fill the Iul Death Benefits field.</lable>}
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                {/* IUL Account Value Detail */}
                {/* {console.log("AgentList", agentList[1], agentList)} */}

                {
                  (state.user_detail.role_id == 1) ?
                    (
                      <>
                        <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11} className="up-input-agent-profile-container container-color">
                          <Grid container>

                            {/* heading */}
                            <Grid item xs={12}>
                              <Grid container className="up-input-sub-heading">
                                <span id="up-input-sub-heading">Assign Profile</span>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container className="up-iul-fee-field-container2">
                                <Grid item xs={6} className="up-input-fee-left-item">
                                  <span id="ilu-fee-left-text">Agent Name</span>
                                </Grid>
                                <Grid item xs={12} className="up-input-fee-notes-field">
                                  <FormControl sx={{ width: "100%" }}>
                                    <Autocomplete

                                      onChange={(e, newInputValue) => { SelectAgentDropdown(e, newInputValue); setAgentValue(newInputValue) }}
                                      onInputChange={(e, value) => {
                                        // console.log("value of input change for agent name", value);
                                        setAgentInputName(value);
                                      }}
                                      defaultValue={(alreadySelectedAgent) && agentList[alreadySelectedAgent]?.user_firstname + " " + agentList[alreadySelectedAgent]?.user_lastname}
                                      value={agentValue}
                                      getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.user_firstname + ' ' + option.user_lastname}
                                      renderInput={(params) => <TextField {...params} />}
                                      options={agentList.sort((a, b) => {
                                        return a.user_lastname?.toLocaleLowerCase() > b.user_lastname?.toLocaleLowerCase() ? 1 : -1
                                      })}
                                    />
                                  </FormControl>
                                </Grid>

                              </Grid>
                            </Grid>

                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xl={5.5} lg={5.5} md={10} sm={11} xs={11}>
                          <Grid container>

                          </Grid>
                        </Grid>
                      </>
                    )
                }

              </Grid>
            </Grid>

            <Grid item xs={12} className="spanTopMar">
              <Grid container className="up-input-last-btn-container" >

                {
                  (stateForm.sample_profile && state.user_detail.role_id == 1) ?
                    <>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={7} className="up-input-last-left-item" textAlign="start">
                        <Grid container>
                          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Button variant="contained" id="up-input-last-button" onClick={() => { createANewProfile(false); }} >Update Sample Profile</Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xl={4} lg={4} md={4} sm={4} xs={7} textAlign="start" className="up-save-btn-form" >
                        <Button variant="contained" id="up-input-last-button" onClick={() => { createANewProfile(true) }}>
                          {btnmsg}
                        </Button>
                      </Grid>
                    </> :
                    <>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="up-input-last-right-item" textAlign="center"  >
                        <Button variant="contained" id="up-input-last-button" onClick={() => { createANewProfile(true) }}>
                          {btnmsg}
                        </Button>
                      </Grid>
                    </>
                }
              </Grid>
            </Grid>


          </Grid>
        </Grid>
      </Grid>

    </>
  );
}

export default UserProfile;