import { Alert, Box, Button, Dialog, DialogContent, FormControlLabel, Grid, Menu, MenuItem, Snackbar, styled, Switch, Typography } from '@mui/material';
import React, { useContext, useState } from 'react'
import Footer from '../../GlobalComponent/Footer/Footer';
import Header from '../../GlobalComponent/Header/Header';
import './NewAgent.css';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid, GridActionsCellItem, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { Context as mainContext } from '../../Context/auth_context'
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { deleteUserProfile, getAgentList, getStatusDetails, inActiveAgent, pendingApprovalAPI, removeAgent } from '../../service/login.service';
import moment from 'moment';
import FormGroup from '@mui/material/FormGroup';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Fade from '@mui/material/Fade';
import RPage from '../RPage/RPage';
import { CheckCircleRounded, ErrorRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';




// Radio Button
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 36,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#3B78CE' : '#3B78CE',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 15,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


function NoRowsOverlay() {
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            No Data Found
            {/* <pre>(rows=&#123;[]&#125;)</pre> */}
        </Stack>
    );
}

const DeleteUserProfilePopup = (props) => {
    const { UpdateLoader } = useContext(mainContext)
    const [apistatus, setApistatus] = React.useState();

    const deleteUserProfilebtn = () => {
        UpdateLoader(true)
        if (!props.data.user_profile_id) return alert('Soemthing went wrong!')
        deleteUserProfile(props.data.user_profile_id, (res) => {
            props.showMessage({
                status: true,
                message: "Profile Deleted Successfully!",
                error: false,
            })
            props.handleClose()
            UpdateLoader(false)
        },
            (err) => {
                console.log("err", err)
                props.showMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                props.handleClose()
                UpdateLoader(false)
            })
    }


    return (

        <Dialog
            open={true}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title">
            <DialogContent>
                <Grid item xs={12}>
                    <Grid container className="pr-assign-profile-popup">
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container className="pr-assign-profile-popup-title-container">
                                        {apistatus ?
                                            (
                                                <>
                                                    <Typography id="pr-assign-profile-popup-title">Successfully Deleted!</Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography id="pr-assign-profile-popup-title">Delete {props.data.user_profile_name} </Typography>
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container className="pr-assign-profile-popup-close-btn-container">
                                        <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={props.handleClose} /></Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
                            <Grid container className="pr-assign-agent-dropdown-container">

                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Grid container className="pr-assign-agent-dropdown-label" textAlign={"center"}>
                                        {apistatus ?
                                            (
                                                <>
                                                    <CheckCircleRounded fontSize="large" sx={{ color: "green" }} />
                                                </>
                                            ) : (
                                                <>
                                                    <ErrorRounded fontSize="large" sx={{ color: "red" }} />
                                                </>
                                            )
                                        }
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container className="pr-assign-agent-dropdown-label">
                                        {apistatus ?
                                            (
                                                <>
                                                    <Typography id="pr-assign-agent-dropdown-label">Profile Deleted Successfully!!</Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography id="pr-assign-agent-dropdown-label">Are you sure you want delete this profile?</Typography>
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
                            <Grid container className="pr-save-cancel-popup-container">
                                {apistatus ?
                                    (
                                        <>
                                            <Grid item xs={5.6}>
                                                <Grid container className="avl-email-popup-btn">
                                                    <Button variant="outlined" id="avl-email-save-popup-btn" onClick={props.handleClose}>Close</Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={5.6}>
                                                <Grid container className="avl-email-popup-btn">
                                                    <Button variant="outlined" id="avl-email-save-popup-btn" onClick={props.handleClose}>No</Button>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={5.6}>
                                                <Grid container className="pr-save-popup-btn">
                                                    <Button variant="contained" id="pr-save-popup-btn" onClick={deleteUserProfilebtn}>Yes</Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}




const NewAgent = (props) => {

    const [message, setMessage] = useState({
        status: false,
        message: "",
        error: false,
    });
    const [agentList, setAgentList] = useState([]);
    const [selectedAgent, setSelectedAgent] = React.useState([]);


    const { UpdateLoader, previousUrl, state } = useContext(mainContext)
    const [open, setOpen] = React.useState({
        status: false,
        id: 0,
    });
    const [flagDeleteAgentPopup, setFlagDeleteAgentPopup] = useState(false);
    const [flagAgentDeleted, setFlagAgentDeleted] = useState(false);
    const [agentToDeleteInfo, setAgentToDeleteInfo] = useState({});
    const [currentStatusId, setCurrentStatusId] = useState(-1);
    const [editAgent, setEditAgent] = useState({});
    const [editFlag, setEditFlag] = useState(false)
    const [globalAgentList, setGlobalAgentList] = React.useState([{}]);
    const [orderAscDesc, setOrderAscDesc] = useState('asc')
    const [filterTableFlag, setFilterTableFlag] = useState(false)
    const [filterTable, setFilterTable] = useState('user_firstname')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [flagApprovePopup, setFlagApprovePopup] = useState(false)
    const [flagAgentApproved, setFlagAgentApproved] = useState(false);

    const [flagCancelPopup, setFlagCancelPopup] = useState(false)
    const [flagAgentCancel, setFlagAgentCancel] = useState(false)

    const [flagRejectPopup, setFlagRejectPopup] = useState(false)
    const [flagAgentReject, setFlagAgentReject] = useState(false)

    const [selectRegistrationBtn, setSelectRegistrationBtn] = useState(false)
    const [selectAgentlistBtn, setSelectAgentlistBtn] = useState(true)
    const [buttonClick, setButtonClick] = useState("")


    const open1 = Boolean(anchorEl);
    const location = useLocation();
    const navigate = useNavigate();
    const [countList, setCountList] = useState({
        total_agents: 0,
        total_admins: 0,
        total_active_agents: 0,
        total_pending_agents: 0,
        total_inactive_agents: 0,
    })


    const queryParams = new URLSearchParams(window.location.search);
    const LinkId = queryParams.get("agent_id");

    const [defaultSearch, setDefaultSearch] = useState({
        filter: {
            filterModel: {
                items: [{ columnField: 'user_id', operatorValue: '=', value: LinkId }],
            },
        },
    })


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setEditAgent({})
        setOpen(false);
        setAnchorEl(null);

    };

    const handleClickOpen = () => {
        setEditAgent({})
        setOpen({
            status: true,
            id: 1,
        });
    };

    const handleOptionClose = () => {
        setAnchorEl(null);
    };

    const columns = [
        {
            field: 'user_id',
            headerName: 'Agent Id',
            width: 80,
            type: "number",
            align: 'left',
            headerAlign: 'left',
            hide: true,
        },
        {
            field: 'user_firstname',
            headerName: 'First Name',
            width: 180,
            type: "string",
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                var firstname = params.row.user_firstname;

                return <span>{firstname} {params.row.user_role_id == 1 && <span className='agent-admin-badge'>Admin</span>}</span>
            }

        },
        {
            field: 'user_lastname',
            headerName: 'Last Name',
            width: 180,
            type: "string",
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'user_created_at',
            headerName: 'Created Date',
            width: 180,
            type: "date",
            valueFormatter: params => moment(params?.value).format("MM/DD/YYYY hh:mm A"),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'user_email',
            headerName: 'Email',
            width: 300,
            type: "email",
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'pending',
            headerName: 'Email Verification',
            width: 150,
            type: "string",
            align: 'center',
            headerAlign: 'left',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>
                        {row.pending && (<span class="agent-label-warning">Pending</span>)}
                    </>
                )
            }

        },
        {
            field: 'user_contact',
            headerName: 'Contact',
            width: 140,
            type: "string",
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: "total_assigned_profile_count",
            headerName: "Profiles",
            type: "number",
            width: 80,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'user_status_id',
            headerName: 'Status',
            width: 220,
            type: "string",
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>
                        <FormGroup className='agent-status-row'>
                            {row.user_role_id != 1 && row.user_status_id != "1" &&
                                <FormControlLabel
                                    control={<IOSSwitch checked={row.user_status_id === currentStatusId.toString()}
                                        onChange={(e) => { ChangeStatus(e, row) }}

                                    />}
                                    align="right"
                                />
                            }
                        </FormGroup>
                    </>
                )
            }
        },
        {
            field: '',
            headerName: 'Action',
            width: 80,
            type: "",
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>
                        {row.user_role_id != 1 &&
                            <Button
                                id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={(e) => {
                                    setSelectedAgent(row)
                                    handleClick(e)

                                }}
                            >

                                <MoreVertIcon className="optionIcon" color='black' />
                            </Button>
                        }
                    </>
                )
            }
        },
    ];

    const pendingColumns = [
        {
            field: 'user_id',
            headerName: 'Agent Id',
            width: 80,
            type: "number",
            align: 'left',
            headerAlign: 'left',
            hide: true,
        },
        {
            field: 'user_firstname',
            headerName: 'First Name',
            width: 180,
            type: "string",
            align: 'left',
            headerAlign: 'left',


        },
        {
            field: 'user_lastname',
            headerName: 'Last Name',
            width: 180,
            type: "string",
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'user_created_at',
            headerName: 'Created Date',
            width: 180,
            type: "date",
            valueFormatter: params => moment(params?.value).format("MM/DD/YYYY hh:mm A"),
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'user_email',
            headerName: 'Email',
            width: 300,
            type: "email",
            align: 'left',
            headerAlign: 'left',
        },

        {
            field: 'user_contact',
            headerName: 'Contact',
            width: 140,
            type: "string",
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: '',
            headerName: '',
            width: 200,
            type: "",
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                let row = params.row
                return (
                    <>
                        <Button className='agent-pending-table-btn1' variant='contained' size='small' onClick={() => { setSelectedAgent(row); approvebtnfunction(true) }}>Approve</Button>
                        <Button className='agent-pending-table-btn2' variant='contained' size='small' onClick={() => { setSelectedAgent(row); approvebtnfunction(false) }}>Reject</Button>
                    </>
                )
            }
        },
    ];

    const ChangeStatus = (e, row) => {

        UpdateLoader(true);
        inActiveAgent({
            agent_id: row.user_id,
            agent_active_flag: e.target.checked
        },
            (res) => {
                if (res.data.status) {
                    getAgentList(
                        (res) => {
                            if (res.data.status) {

                                res.data.data[0].agents = res.data.data[0].agents.sort((a, b) => a.user_firstname.toLowerCase() > b.user_firstname.toLowerCase() ? 1 : -1);
                                // res.data.data[0].agents = res.data.data[0].agents.sort((a, b) => a.user_status_id.toLowerCase() > b.user_status_id.toLowerCase() ? 1 : -1);
                                res.data.data[0].agents = res.data.data[0].agents.map(row => {
                                    if (row.user_role_id != 1 && row.user_status_id == "1") {
                                        return { ...row, pending: true };
                                    }
                                    else {
                                        return { ...row, pending: false };
                                    }
                                })
                                setAgentList(res.data.data[0].agents);
                                setGlobalAgentList(res.data.data[0].agents)
                                getStatusDetails({}, (res) => {
                                    let statusList = res.data.data;
                                    let activeStatusId = statusList.find(s => s.status_name === "Active" || s.status_name === 'active').status_id;
                                    setCurrentStatusId(activeStatusId);
                                },


                                    (err) => {
                                        console.log("Printing error for eget status details", err);
                                        UpdateLoader(false);
                                        setMessage({
                                            status: false,
                                            error: true,
                                            message: "Something went wrong, Please try again later.!"
                                        })
                                    })
                            }
                            else {
                                console.warn("Status call failed, value", 2);
                                setCurrentStatusId(2)
                            }
                            UpdateLoader(false);
                        }, (err) => {
                            UpdateLoader(false);
                            console.log("Printing error for get agent detail call", err);
                            setMessage({
                                status: false,
                                error: true,
                                message: "Something went wrong, Please try again later.!"
                            })
                        })
                }
            }, (err) => {
                UpdateLoader(false);
                console.log("Printing error for get status detail call", err);
                setMessage({
                    status: false,
                    error: true,
                    message: "Something went wrong, Please try again later.!"
                })
            });
    }


    useEffect(() => {
        setButtonClick("Agent List")
        UpdateLoader(true)
        getAgentList((res) => {
            // debugger
            if (res.data.status) {
                res.data.data[0].agents = res.data.data[0].agents.sort((a, b) => a.user_firstname.toLowerCase() > b.user_firstname.toLowerCase() ? 1 : -1);
                res.data.data[0].agents = res.data.data[0].agents.map(row => {
                    if (row.user_role_id != 1 && row.user_status_id == "1") {
                        return { ...row, pending: true };
                    }
                    else {
                        return { ...row, pending: false };
                    }
                })
                if (res.data.data) {
                    var obj = {
                        total_agents: res.data.data[0]['total_agent_count'],
                        total_admins: res.data.data[0]['total_admin_count'],
                        total_active_agents: res.data.data[0]['total_active_agent_count'],
                        total_pending_agents: res.data.data[0]['total_pending_agent_count'],
                        total_inactive_agents: res.data.data[0]['total_inactive_agent_count'],
                    }
                    setCountList(obj)
                    UpdateLoader(false)
                }
                let aa = res.data.data[0].agents
                let filterObj = aa.filter(filterData => filterData.user_status_id != 5)
                setAgentList(filterObj)
                setGlobalAgentList(aa)

                getStatusDetails({}, (res) => {
                    let statusList = res.data.data;
                    let activeStatusId = statusList.find(s => s.status_name === "Active" || s.status_name === "active").status_id;

                    setCurrentStatusId(activeStatusId);
                    UpdateLoader(false);
                }, (err) => {
                    console.log("Printing error for get status detail", err);
                    setMessage({
                        status: false,
                        error: true,
                        message: "Something went wrong, please try again later.!"
                    })

                })
            } else {
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            }
            UpdateLoader(false)

        }, (err) => {
            UpdateLoader(false)
            console.log(err)
            setMessage({
                status: true,
                message: "Something Went Wrong!",
                error: true,
            })
        })
    }, [])



    const DeleteAgent = (agent_id) => {
        UpdateLoader(true)
        removeAgent({ agentId: agent_id }, (res) => {
            if (res.data.status) {
                getAgentList((res) => {
                    setFlagAgentDeleted(true);
                    setGlobalAgentList(res.data.data[0].agents)
                    UpdateLoader(false);
                    if (res.data.status) {
                        res.data.data[0].agents = res.data.data[0].agents.map(row => {
                            if (row.user_role_id != 1 && row.user_status_id == "1") {
                                return { ...row, pending: true };
                            }
                            else {
                                return { ...row, pending: false };
                            }
                        })
                        res.data.data[0].agents = res.data.data[0].agents.sort((a, b) => a.user_firstname.toLowerCase() > b.user_firstname.toLowerCase() ? 1 : -1);
                        setAgentList(res.data.data[0].agents)


                    } else {
                        setMessage({
                            status: true,
                            message: "Something Went Wrong!",
                            error: true,
                        })
                    }
                }, (err) => {
                    UpdateLoader(false)
                    console.log(err)
                    setMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                })
            }
            else {
                UpdateLoader(false);
                setMessage({
                    status: false,
                    message: res.data.error.message,
                    error: true,
                })
            }
        }, (err) => {
            UpdateLoader(false);
            setMessage({
                status: false,
                message: "Something went wrong, Please try again later.!",
                error: true,
            })
        })
    }

    const approveRejectAgent = (agent_id, flag) => {

        if (!agent_id) {
            setMessage({
                status: true,
                message: "Something Went Wrong!",
                error: true,
            })
        }
        UpdateLoader(true)
        var payload = {
            "user_id": agent_id,
            "approval_flag": flag
        }
        pendingApprovalAPI(payload, (res) => {
            if (res.data.status) {
                setMessage({
                    status: true,
                    message: res.data.data.message,
                    error: false,
                })
                getAgentList((res) => {
                    setFlagAgentDeleted(false);
                    setGlobalAgentList(res.data.data[0].agents)
                    UpdateLoader(false);
                    if (res.data.status) {
                        res.data.data[0].agents = res.data.data[0].agents.sort((a, b) => a.user_firstname.toLowerCase() > b.user_firstname.toLowerCase() ? 1 : -1);
                        res.data.data[0].agents = res.data.data[0].agents.filter(rr => rr.user_status_id == 5);
                        setAgentList(res.data.data[0].agents)
                        setFlagApprovePopup(false)
                        setFlagRejectPopup(false)

                    } else {
                        setMessage({
                            status: true,
                            message: "Something Went Wrong!",
                            error: true,
                        })
                    }
                }, (err) => {
                    UpdateLoader(false)
                    console.log(err)
                    setMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                })
            }
            else {
                UpdateLoader(false);
                setMessage({
                    status: false,
                    message: res.data.error.message,
                    error: true,
                })
            }
        }, (err) => {
            UpdateLoader(false);
            setMessage({
                status: false,
                message: "Something went wrong, Please try again later.!",
                error: true,
            })
        })
    }


    const approvebtnfunction = (name) => {
        if (name) {
            setFlagApprovePopup(true)
            setFlagAgentApproved(false)
        } else {
            setFlagRejectPopup(true)

            setFlagAgentReject(false)
        }
    }

    const openRegitrationTable = () => {
        setButtonClick("Registration Request")
        setSelectRegistrationBtn(true)
        setSelectAgentlistBtn(false)
        let getFilterData = [...globalAgentList]
        let filterDataObj = getFilterData.filter(filterAgent => filterAgent.user_status_id == 5)
        setAgentList(filterDataObj)

    }

    const openAgentlistTable = () => {
        setButtonClick("Agent List")
        setSelectAgentlistBtn(true)
        setSelectRegistrationBtn(false)
        let globalData = [...globalAgentList]
        let globalDataObj = globalData.filter(filterAgent => filterAgent.user_status_id != 5)
        setAgentList(globalDataObj)
    }

    const handleClose1 = () => {
        setMessage({
            status: false,
            message: "",
            error: "",
        })
    };




    return (
        <>
            {message.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
                    <Alert onClose={handleClose1} severity={message.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {message.message}
                    </Alert>
                </Snackbar>
            }

            <Header />
            {flagDeleteAgentPopup &&
                <Dialog
                    open={true}
                    // onClose={() => { setFlagAgentDeleted(false) }}
                    onClose={props.handleClose}
                    aria-labelledby="responsive-dialog-title">
                    <DialogContent>
                        <Grid item xs={12}>
                            <Grid container className="pr-assign-profile-popup">
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container className="pr-assign-profile-popup-title-container">
                                                {flagAgentDeleted ?
                                                    (
                                                        <>
                                                            <Typography id="pr-assign-profile-popup-title">Successfully Deleted!</Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography id="pr-assign-profile-popup-title">Delete {selectedAgent.user_firstname} Agent</Typography>
                                                        </>
                                                    )
                                                }

                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid container className="pr-assign-profile-popup-close-btn-container">
                                                <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={() => { setFlagDeleteAgentPopup(false); setFlagAgentDeleted(false) }} /></Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} >
                                    <Grid container className="pr-assign-agent-dropdown-container">

                                        <Grid item xs={12}>
                                            <Grid container className="pr-assign-agent-dropdown-label" textAlign={"center"}>
                                                {flagAgentDeleted ?
                                                    (
                                                        <>
                                                            <CheckCircleRounded sx={{ color: "green", width: "60px", height: "60px", margin: "0px auto 15px" }} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ErrorRounded sx={{ color: "red", width: "60px", height: "60px", margin: "5px auto" }} />
                                                        </>
                                                    )
                                                }

                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="pr-assign-agent-dropdown-label">
                                                {flagAgentDeleted ?
                                                    (
                                                        <>
                                                            <Typography id="pr-assign-agent-dropdown-label">Agent Deleted Successfully!</Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography id="pr-assign-agent-dropdown-label">Are you sure you want delete this Agent?</Typography>
                                                        </>
                                                    )
                                                }

                                            </Grid>
                                        </Grid>


                                    </Grid>
                                </Grid>

                                <Grid item xs={7} >
                                    <Grid container className="pr-save-cancel-popup-container-agent" justifyContent="center" marginTop='10px'>
                                        {flagAgentDeleted ?
                                            (
                                                <>
                                                    <Grid item xs={7}>
                                                        <Grid container className="avl-email-popup-btn" sx={{ display: "flex", justifyContent: "center", margin: "0 auto", width: "100%" }}>
                                                            <Button variant="outlined" sx={{ margin: "0 auto", justifyContent: "center" }} id="avl-email-save-popup-btn" onClick={() => { setFlagDeleteAgentPopup(false); setFlagAgentDeleted(false) }}>Close</Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Grid container justifyContent="space-between">

                                                            <Grid item xl={5.6} lg={5.6} md={5.6} sm={5.6} xs={5.6} >
                                                                <Grid container className="avl-email-popup-btn">
                                                                    <Button variant="outlined" id="avl-email-save-popup-btn" onClick={() => { setFlagDeleteAgentPopup(false) }}>No</Button>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xl={5.6} lg={5.6} md={5.6} sm={5.6} xs={5.6}>
                                                                <Grid container className="pr-save-popup-btn">
                                                                    <Button variant="contained" id="pr-save-popup-btn" onClick={() => { DeleteAgent(selectedAgent.user_id) }}>Yes</Button>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        }

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }

            {flagApprovePopup &&
                <>
                    <Dialog
                        open={true}
                        onClose={props.handleClose}
                        aria-labelledby="responsive-dialog-title">
                        <DialogContent>
                            <Grid item xs={12}>
                                <Grid container className="pr-assign-profile-popup">

                                    <Grid item xs={12}>
                                        <Grid container>

                                            <Grid item xs={12}>
                                                <Grid container className="pr-assign-profile-popup-title-container">
                                                    {flagAgentApproved ?
                                                        (
                                                            <>
                                                                <Typography id="pr-assign-profile-popup-title">Successfully Approved!</Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography id="pr-assign-profile-popup-title">Approve {selectedAgent.user_firstname} Agent</Typography>
                                                            </>
                                                        )
                                                    }

                                                </Grid>
                                            </Grid>

                                            <Grid item>
                                                <Grid container className="pr-assign-profile-popup-close-btn-container">
                                                    <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={() => { setFlagApprovePopup(false); setFlagAgentApproved(false) }} /></Box>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid item xs={7} >
                                        <Grid container className="pr-assign-agent-dropdown-container">


                                            <Grid item xs={12}>
                                                <Grid container className="pr-assign-agent-dropdown-label">
                                                    {flagAgentApproved ?
                                                        (
                                                            <>
                                                                <Typography id="pr-assign-agent-dropdown-label">Agent Approve Successfully!</Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography id="pr-assign-agent-dropdown-label">Are you sure you want approve this agent?</Typography>
                                                            </>
                                                        )
                                                    }

                                                </Grid>
                                            </Grid>


                                        </Grid>
                                    </Grid>

                                    <Grid item xs={7} >
                                        <Grid container className="pr-save-cancel-popup-container-agent" justifyContent="center" marginTop='10px'>
                                            {flagAgentApproved ?
                                                (
                                                    <>
                                                        <Grid item xs={5.6}>
                                                            <Grid container className="avl-email-popup-btn" sx={{ display: "flex", justifyContent: "center", margin: "0 auto", width: "100%" }}>
                                                                <Button variant="outlined" sx={{ margin: "0 auto", justifyContent: "center" }} id="avl-email-save-popup-btn" onClick={() => { setFlagApprovePopup(false); setFlagAgentApproved(false) }}>Close</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent="space-between">

                                                                <Grid item xs={5.6} >
                                                                    <Grid container className="avl-email-popup-btn">
                                                                        <Button variant="outlined" id="avl-email-save-popup-btn" onClick={() => { setFlagApprovePopup(false) }}>No</Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={5.6}>
                                                                    <Grid container className="pr-save-popup-btn">
                                                                        <Button variant="contained" id="pr-save-popup-btn" onClick={() => { approveRejectAgent(selectedAgent.user_id, true) }}>Yes</Button>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </>
            }

            {flagRejectPopup &&
                <>
                    <Dialog
                        open={true}
                        onClose={props.handleClose}
                        aria-labelledby="responsive-dialog-title">
                        <DialogContent>
                            <Grid item xs={12}>
                                <Grid container className="pr-assign-profile-popup">

                                    <Grid item xs={12}>
                                        <Grid container>

                                            <Grid item xs={12}>
                                                <Grid container className="pr-assign-profile-popup-title-container">
                                                    {flagAgentReject ?
                                                        (
                                                            <>
                                                                <Typography id="pr-assign-profile-popup-title">Successfully Rejected!</Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography id="pr-assign-profile-popup-title">Reject {selectedAgent.user_firstname} Agent</Typography>
                                                            </>
                                                        )
                                                    }

                                                </Grid>
                                            </Grid>

                                            <Grid item>
                                                <Grid container className="pr-assign-profile-popup-close-btn-container">
                                                    <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={() => { setFlagRejectPopup(false); setFlagAgentReject(false) }} /></Box>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid item xs={7} >
                                        <Grid container className="pr-assign-agent-dropdown-container">


                                            <Grid item xs={12}>
                                                <Grid container className="pr-assign-agent-dropdown-label">
                                                    {flagAgentReject ?
                                                        (
                                                            <>
                                                                <Typography id="pr-assign-agent-dropdown-label">Agent Cancel Successfully!</Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Typography id="pr-assign-agent-dropdown-label">Are you sure you want reject this agent?</Typography>
                                                            </>
                                                        )
                                                    }

                                                </Grid>
                                            </Grid>


                                        </Grid>
                                    </Grid>

                                    <Grid item xs={7} >
                                        <Grid container className="pr-save-cancel-popup-container-agent" justifyContent="center" marginTop='10px'>
                                            {flagAgentReject ?
                                                (
                                                    <>
                                                        <Grid item xs={5.6}>
                                                            <Grid container className="avl-email-popup-btn" sx={{ display: "flex", justifyContent: "center", margin: "0 auto", width: "100%" }}>
                                                                <Button variant="outlined" sx={{ margin: "0 auto", justifyContent: "center" }} id="avl-email-save-popup-btn" onClick={() => { setFlagRejectPopup(false); setFlagAgentReject(false) }}>Close</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent="space-between">

                                                                <Grid item xs={5.6} >
                                                                    <Grid container className="avl-email-popup-btn">
                                                                        <Button variant="outlined" id="avl-email-save-popup-btn" onClick={() => { setFlagRejectPopup(false) }}>No</Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={5.6}>
                                                                    <Grid container className="pr-save-popup-btn">
                                                                        <Button variant="contained" id="pr-save-popup-btn" onClick={() => { approveRejectAgent(selectedAgent.user_id, false) }}>Yes</Button>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </>
            }
            {state.user_detail.role_id == 1 &&
                <>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container className="na-main-container">
                                <Grid item xs={11} sx={{ textAlign: "center" }} >
                                    <Grid container>

                                        <Grid item xs={11} sx={{ margin: "0 auto" }}>
                                            <Grid container>
                                                <Grid item xs={4}></Grid>

                                                <Grid item xs={4} classNames="na-main-item1" textAlign="center">
                                                    <span className='na-agnet-main-heading'>Agent</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} className='na-blue-white-main-item'>
                                            <Grid container className='na-table-blue-container'>

                                                <Grid item xs={12}>
                                                    <Grid container className='na-table-white-container1'>
                                                        <Grid item xl={12} lg={12} md={12} sm={11} xs={11}>
                                                            <Grid container className='tp-total-status-combine-container'>
                                                                <Grid item xs={12}>
                                                                    <Grid container>
                                                                        <Grid item xl={0.5} lg={1} md={1} sm={1} xs={1}>
                                                                            <Grid container className='tp_total_and_tables_container'>
                                                                                <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className='tp_box_total_item'>
                                                                                    <span className='tp_box_total_label'>Total</span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className='tp_box_main_item' >
                                                                            <Grid container className='tp_box_main_container'>

                                                                                <Grid item xl={12} lg={12} md={12} xs={12}>
                                                                                    <Grid container justifyContent="space-between">

                                                                                        {/* agent & admin */}
                                                                                        <Grid item xl={3.5} lg={4} md={4} sm={12} xs={12}  >
                                                                                            <Grid container className='tp_box_agent_admin_container'>

                                                                                                <Grid item xl={12} lg={12} sm={12} md={12} xs={12} className='tp_box_agent_admin_item'>
                                                                                                    <Grid container justifyContent="space-between">
                                                                                                        {/* <Grid item xl={5.95} lg={5.9} md={5.9} xs={5.9} className='tp_box_agent_item'>
                                                                                                    <span className='tp_box_agent_admin_label'>Agents</span>
                                                                                                </Grid> */}
                                                                                                        <Grid item xl={5.95} lg={5.9} md={5.9} xs={12} className='tp_box_admin_item'>
                                                                                                            <span className='tp_box_agent_admin_label'>Admins</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                <Grid item xl={12} xs={12} marginTop="2px">
                                                                                                    <Grid container justifyContent="space-between">
                                                                                                        {/* <Grid item xl={5.95} lg={5.9} md={5.9} xs={5.9} className='tp_box_agent_count_item'>
                                                                                                    <span className='tp_box_agent_admin_label'>{countList.total_agents}</span>
                                                                                                </Grid> */}
                                                                                                        <Grid item xl={5.95} lg={5.9} md={5.9} xs={12} className='tp_box_admin_count_item'>
                                                                                                            <span className='tp_box_agent_admin_label'>{countList.total_admins}</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        {/* Status */}
                                                                                        <Grid item xl={4} lg={5} md={6} sm={12} xs={12}>
                                                                                            <Grid container className='tp_box_status_container'>

                                                                                                <Grid item xl={12} lg={12} md={12} xs={12} className='tp_box_status_item'>
                                                                                                    <Grid container justifyContent="space-between">
                                                                                                        <Grid item xl={3.95} lg={3.9} md={3.9} xs={3.9} className='tp_status_active'>
                                                                                                            <span className='tp_box_status_label'>Active Agents</span>
                                                                                                        </Grid>
                                                                                                        <Grid item xl={3.95} lg={3.9} md={3.9} xs={3.9} className='tp_status_pending'>
                                                                                                            <span className='tp_box_status_label'>Pending Agents</span>
                                                                                                        </Grid>
                                                                                                        <Grid item xl={3.95} lg={3.9} md={3.9} xs={3.9} className='tp_status_inactive'>
                                                                                                            <span className='tp_box_status_label'>Inactive Agents</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                                <Grid item xl={12} xs={12}>
                                                                                                    <Grid container justifyContent="space-between">
                                                                                                        <Grid item xl={3.95} lg={3.9} xs={3.9} className='tp_status_active_count'>
                                                                                                            <span className='tp_box_status_label'>{countList.total_active_agents}</span>
                                                                                                        </Grid>
                                                                                                        <Grid item xl={3.95} lg={3.9} xs={3.9} className='tp_status_pending_count'>
                                                                                                            <span className='tp_box_status_label'>{countList.total_pending_agents}</span>
                                                                                                        </Grid>
                                                                                                        <Grid item xl={3.95} lg={3.9} xs={3.9} className='tp_status_inactive_count'>
                                                                                                            <span className='tp_box_status_label'>{countList.total_inactive_agents}</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>

                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        {/* Profile */}
                                                                                        {/* <Grid item xl={1.5} md={3} sm={12} xs={12}>
                                                                                    <Grid container>


                                                                                        <Grid item xl={12} xs={12} marginTop="2px">
                                                                                            <Grid container justifyContent="space-between">
                                                                                                <Grid item xl={12} xs={12} className='tp_box_total_profile_head_item'>
                                                                                                    <span className='tp_box_agent_label'>Profiles</span>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xl={12} xs={12} className='tp_box_profile_count_item'>
                                                                                            <span className='tp_box_agent_label'>{profileCnt}</span>
                                                                                        </Grid>

                                                                                    </Grid>
                                                                                </Grid> */}
                                                                                    </Grid>
                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Grid container className='na-table-white-container2'>

                                                        <Grid item xl={4} lg={10} md={12} sm={12} xs={12} >
                                                            <Grid container className='na-table-approval-btn-container'>
                                                                <Grid item xl={5} lg={3} md={4} sm={5} xs={12} className="na-agentlist-btn-item">
                                                                    <Button variant='outlined' className='na-agentlist-btn-class' fullWidth id={selectAgentlistBtn == true ? "na-agent-reg-btn-effect" : "tp-agent-reg-btn-effect-default"} onClick={openAgentlistTable} >Agent List</Button>
                                                                </Grid>
                                                                <Grid item xl={5} lg={3} md={4} sm={5} xs={12} className="na-registration-btn-item">

                                                                    <Button variant='outlined' className='na-registration-btn-class' fullWidth id={selectRegistrationBtn == true ? "na-agent-reg-btn-effect" : "tp-agent-reg-btn-effect-default"} onClick={openRegitrationTable} >Registration Request</Button>
                                                                    {/* <Button variant='contained' className="na-agent-pending-btn" fullWidth onClick={chnagebtnapproval}>{btnClickOpen == "Show All" ? "Registration Requests" : "Show All"}</Button> */}
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} className='na-datagrid-item'>
                                                            {buttonClick == "Agent List" &&
                                                                <DataGrid
                                                                    className='na-datagrid'
                                                                    rows={agentList}
                                                                    columns={columns}
                                                                    getRowId={(agentList) => (agentList.user_id)}
                                                                    // id={Math.random()}
                                                                    pageSize={10}
                                                                    rowsPerPageOptions={[5]}
                                                                    density="compact"
                                                                    autoHeight
                                                                    components={{
                                                                        Toolbar: GridToolbar, NoRowsOverlay

                                                                    }}
                                                                    sx={{
                                                                        '& .MuiDataGrid-cell:hover': {
                                                                            color: 'primary.main',
                                                                        },
                                                                    }}
                                                                    initialState={LinkId ? defaultSearch : ""}


                                                                />
                                                            }
                                                            {buttonClick == "Registration Request" &&
                                                                <DataGrid
                                                                    className='na-datagrid'
                                                                    rows={agentList}
                                                                    columns={pendingColumns}
                                                                    getRowId={(agentList) => (agentList.user_id)}
                                                                    // id={Math.random()}
                                                                    pageSize={10}
                                                                    rowsPerPageOptions={[5]}
                                                                    density="compact"
                                                                    autoHeight
                                                                    components={{
                                                                        Toolbar: GridToolbar, NoRowsOverlay

                                                                    }}
                                                                    sx={{
                                                                        '& .MuiDataGrid-cell:hover': {
                                                                            color: 'primary.main',
                                                                        },
                                                                    }}
                                                                />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                                <Box className="agent-plus-box">
                                                    <AddCircleIcon onClick={handleClickOpen} className="agent-plus-icon" />
                                                </Box>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
            <Menu

                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open1}
                onClose={handleOptionClose}
                TransitionComponent={Fade}
                className="agent-edit-dlt-menu"
            >
                <MenuItem className="optionsClass"
                    onClick={() => {
                        setEditFlag(true)
                        setOpen({
                            status: true,
                            id: 0,
                        });
                        setAnchorEl(null);

                    }}
                ><ModeEditOutlineOutlinedIcon id="agent-edit-btn" fontSize="small" />&nbsp;<span id="menu-edit-agent">Edit</span></MenuItem>
                <MenuItem
                    className="optionsClass"
                    onClick={() => {
                        setAnchorEl(null);
                        setFlagDeleteAgentPopup(true);
                    }}

                >
                    <DeleteForeverOutlinedIcon fontSize="small" id="agent-delete-btn" />&nbsp;<span id="menu-delete-agent">Delete</span></MenuItem>
            </Menu>

            <Footer />
            {open.status && <RPage data={open} row={selectedAgent} editFlag={editFlag} setEditFlag={setEditFlag} setAgentList={(data) => { setAgentList(data) }} close={handleClose} />}


        </>
    )
}

export default NewAgent;