import { Button, Dialog, DialogContent, Grid } from '@mui/material'
import React, { useContext, useState } from 'react'
import './DeleteLinkPopup.css'
import CloseIcon from '@mui/icons-material/Close';
import { deleteUserProfileUrlAPI, getClientFacingUrlDataAPI } from '../../service/login.service';
import { Context as mainContext } from '../../Context/auth_context'
import { useLocation, useSearchParams } from "react-router-dom";


const DeleteLinkPopup = (props) => {

    const { UpdateLoader, state } = useContext(mainContext)
    const [searchParams, setSearchParams] = useSearchParams();

    const deleteProfileURL = () => {
        UpdateLoader(true)
        let profileId = searchParams.get("id");
        let token = props.urlDetailsData.client_facing_url_token

        if (!token) {
            props.setMessage({
                status: true,
                message: "Something Went Wrong!",
                error: true,
            })
            return
        }

        let deteleProfileUrlObj = {
            public_token: token,
            user_profile_id: profileId
        }

        deleteUserProfileUrlAPI(deteleProfileUrlObj, (res) => {
            if (res.data.status) {
                props.setMessage({
                    status: true,
                    message: "URL Deleted Successfully!",
                    error: false,
                })
                getClientFacingUrlDataAPI(profileId,
                    (res) => {
                        props.setActivityLogData(res.data.data[0].client_facing_url_activity_log)
                        props.setUrlDetailsData(res.data.data[0])
                        let userDetails = res.data.data[0]
                        if (userDetails && !userDetails.client_facing_url_token_deleted) {
                            props.setSaveFlag(false);
                        }
                        props.setMessage({
                            status: true,
                            message: "URL Deleted Successfully!",
                            error: false,
                        })
                        UpdateLoader(false)
                        props.handleClose()

                    },
                    (err) => {
                        props.setMessage({
                            status: true,
                            message: "Something Went Wrong!",
                            error: true,
                        })
                    }
                )
            }
            else {
                props.setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            }
            UpdateLoader(false)
        },
            (err) => {
                UpdateLoader(false)
                console.log(err)
                props.setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
            })
    }


    return (
        <Dialog
            open={true}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xs"
        >
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container className='clienturl-main-container'>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} className="cm-head-item">
                                            <span className="cm-heading-span">Delete Confirmation</span>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container className="avl-email-link-popup-close-btn-container">
                                            <span id="avl-email-popup-close-btn"><CloseIcon onClick={props.handleClose} /></span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className="cm-label-text" >
                                <span className='cm-label-label'>
                                    Are you sure you want to delete URL?
                                </span>
                            </Grid>

                            <Grid item xs={8} className="cm-continue-cancle-main-item">
                                <Grid container className="cm-continue-cancle-main-container">
                                    <Grid item xs={5.5} className="cm-close-item">
                                        <Button
                                            variant='outlined'
                                            className='cm-close-btn'
                                            fullWidth
                                            onClick={props.handleClose}
                                        >Close</Button>
                                    </Grid>
                                    <Grid item xs={5.5} className="cm-continue-item">
                                        <Button
                                            variant='contained'
                                            className='cm-continue-btn'
                                            fullWidth
                                            onClick={() => {deleteProfileURL()}}
                                        >Continue</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteLinkPopup;







