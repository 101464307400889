import { Button, Grid, Typography } from '@mui/material';
import React from 'react'
import './UserPage.css';
import { Box } from '@mui/system';
import Footer from '../../GlobalComponent/Footer/Footer';
import Header from '../../GlobalComponent/Header/Header';
import RPage from '../RPage/RPage';
import AddIcon from '@mui/icons-material/Add';
import userFirstPage from "../../assets/Images/userFirstPage.png"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { useNavigate } from 'react-router-dom';
import Termconditionpopup from '../../GlobalComponent/Termconditionpopup/Termconditionpopup';
import { useContext, useState } from "react";
import { Context as mainContext } from '../../Context/auth_context'



const UserPage = () => {
    const { state } = useContext(mainContext)
    const [openPopup, setOpenPopup] = React.useState(true);
    const navigation = useNavigate()

    const slideImages = [
        {
            title: 'Market Volatility',
            description: "Just how exposed to the market is your retirement plan? Discover how you can protect your retirement earnings from negative returns. IULs prevent market loss and never credit less than zero. Compare the effects of market losses on your current retirement plan to an IUL."
        },
        {
            title: 'Income Longevity',
            description: 'How long will your income last? A recent study showed that the average 401(k) will run out of money only 7 - 8 years into retirement. An IUL has the potential to provide income until you are 120 years old! Take a look at the numbers and compare how long your current retirement plan will last in distribution vs an IUL.'
        },
        {
            title: 'Tax Deferred vs Tax Free',
            description: 'Although most retirement plan option allow for Tax Deferred growth, the majority of them tax 100% of the income you take in retirement. That means that you can potentially lose hundreds of thousands of your retirement dollars to taxes. With an IUL, you  not only get Tax-Deferred growth, you can also take Tax-free retirement distribution. Compare the tax savings with an IUL vs your current retirement plan.'
        },
        {
            title: 'Fees',
            description: 'A recent study showed that retirement plan fees can range from 3% to 5%. Just a 1 percentage point difference in fees can dramatically reduce the size of your nest eggs over time. Compare the fees in your current retirement plan to the fees in an IUL to see for yourself just how much you can save.'
        }
    ];

    return (
        <>
            <Header />
            <Grid container justifyContent="center" className='userpage-dashboard-main-container'>
                <Grid item xl={8} lg={10} md={12} sm={11} xs={11}>
                    <h4 id='home'>Don't Leave Your Retirement To Chance. A Secure Retirement Starts With a Solid Plan.</h4>
                </Grid>
                <Grid item xs={11} md={10} className="blueContainer">
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Grid container justifyContent="center" alignItems="center" className='userpage-slider-item'>
                                <Grid item xs={11} className="imgBox">
                                    {/* <div className="slide-container"> */}
                                    <Slide>
                                        {slideImages.map((slideImage, index) => (
                                            <div className="each-slide" key={index}>
                                                <div className='sliderBack'>
                                                    <div className='blurBox'>
                                                        <h3 className='content'>{slideImage.title}</h3>
                                                        <div className='content'>{slideImage.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slide>
                                    {/* </div> */}
                                </Grid>
                                <Grid item xs={11} className="userpage-btn-item" textAlign="center">
                                    <Button variant='contained' className='centerBtn' onClick={() => { navigation('/profile', { replace: true }) }} >Customized Retirement Plans</Button>
                                </Grid>
                                <Grid item xs={11} className="userpage-disclaimer-item" >
                                    <h4 className='disclaimer'>Disclaimer</h4>
                                    <Box variant='p' className='paragraph'>
                                        This presentation is a general discussion of the topic presented, based on our current understanding of applicable tax laws, regulations, and rulings. This information and presentation in no way should be interpreted as investment or tax advice. In actual practice, the transactions discussed may be more complex and will require the attention and expertise of professional tax and legal advisors. In order to comply with certain U.S. Treasury regulations, please be advised of the following: unless expressly stated otherwise, any reference to U.S. Federal tax information contained in these materials, including any attachments, is not intended or written to be used, and cannot be used, by any person for the purpose of avoiding any penalties that may be imposed by the Internal Revenue Service. Values and benefits are not guaranteed unless stated as guaranteed on illustrations. Examples are used to illustrate the potential benefits of indexed universal life insurance / IRS SEC. 7702 & 72(e). Portions of this presentation are based on NAIC compliant life insurance illustrations which must be present during the presentation and fully reviewed for additional disclosures.</Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* <Tearmconditionpopup openPopup={openPopup} /> */}
            <Footer />
        </>
    );
}


export default UserPage;