import { Alert, Button, Dialog, DialogContent, Grid, LinearProgress, Snackbar, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import './TraininPageDocPopup.css';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { FileUpload, Upload } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { updateDocumentAPI, uploadTrainingResourceAPI, createResourceAPI, updateResourceAPI } from '../../service/login.service';
import AddDocSuccessPopup from '../AddDocSuccessPopup/AddDocSuccessPopup';
import { Context as mainContext } from '../../Context/auth_context'
import { uploadFilesAPI } from '../../service/login.service';


const TraininPageDocPopup = (props) => {

    const { UpdateLoader } = useContext(mainContext)
    const [docDetail, setDocDetail] = useState({
        resource_id: 0,
        resource_title: "",
        resource_description: "",
        resource_document_type_id: 0,
        resource_thumbnail: "",
        resource: "",
        file: "",
        resource_flag: "",
        thumbnail_file_id: "",
        resource_file_id: "",
    })
    const [addDocSuccess, setAddDocSuccess] = useState(false);
    const [linearShow, setLinearShow] = useState(false);
    const [showMessage, setShowMessage] = useState({
        'status': false,
        'error': false,
        'message': ""

    })
    const [message, setMessage] = React.useState({
        status: false,
        message: "",
        error: "",
    });

    const [disabledBtn, setDisabledBtn] = useState(false)



    const onChangeFun = () => {
        setShowMessage({
            status: false,
            error: false,
            message: ""
        })
    }

    useEffect(() => {
        setDisabledBtn(false)
    })

    useEffect(() => {
        if (props.selectedResorceData) {
            setDocDetail({
                resource_id: props.selectedResorceData.resource_id,
                resource_title: props.selectedResorceData.resource_title,
                resource_description: props.selectedResorceData.resource_description,
                resource_document_type_id: 0,
                resource_thumbnail: "",
                resource: "",
                file: "",
                resource_flag: "",
                thumbnail_file_id: props.selectedResorceData.thumbnail_file_id,
                resource_file_id: props.selectedResorceData.resource_file_id,

            })
        } else {
            setDocDetail({
                resource_id: "",
                resource_title: "",
                resource_description: "",
                resource_document_type_id: 0,
                resource_thumbnail: "",
                resource: "",
                resource_id: "",
                file: "",
                resource_flag: "",
                thumbnail_file_id: "",
                resource_file_id: "",
            })
        }
    }, [props])

    // const handleSuccessOpen = () => {
    //     setAddDocSuccess(true)
    //     setDocDetail({
    //         resource_title: "",
    //         resource_description: "",
    //         resource_document_type_id: 0,
    //         resource_thumbnail: "",
    //         resource: "",
    //         resource_id: "",
    //         file: "",
    //         resource_flag: "",
    //         thumbnail_file_id:"",
    //         resource_file_id:"",
    //     })
    //     props.handleCloseDoc(false)
    // }

    const handleSuccessClose = () => {
        setAddDocSuccess(false)
    }

    const handlePropClose = () => {
        setDocDetail({
            resource_title: "",
            resource_description: "",
            resource_document_type_id: 0,
            resource_thumbnail: "",
            resource: "",
            file: "",
            resource_flag: "",
        })
        setMessage({
            'status': false,
            'error': false,
            'message': ""
        })
        props.handleCloseDoc()
    }

    const validation = () => {
        // debugger;
        if (props.selectedResorceData.resource_id) {
            // debugger;
            // let ggg =  docDetail;
            if (!docDetail.resource_title) {
                setShowMessage({
                    'status': false,
                    'error': true,
                    'message': "Please fill required fileds!"
                })
                return false
            }
            // if(!docDetail.resource_file_id || !docDetail.thumbnail_file_id)
            // {
            //     setShowMessage({
            //         'status': false,
            //         'error': true,
            //         'message': "Please fill all the fileds!"
            //     })
            //     return false
            // } 
        }
        else {
            // debugger;
            // let ggg =  docDetail;
            if (!docDetail.resource_file_id || !docDetail.resource_description || !docDetail.thumbnail_file_id || !docDetail.resource_title) {
                setShowMessage({
                    'status': false,
                    'error': true,
                    'message': "Please fill required fileds!"
                })
                return false
            }
        }
        return true
    }

    const addDocFun = () => {
        if (linearShow) {
            setMessage({
                'status': false,
                'error': true,
                'message': "Please wait while the file uploading!"
            })
        }
        if (!validation()) {
            return false;
        }
        UpdateLoader(true)

        // let formData = new FormData(); 
        // if(props.selectedResorceData.resource_id)
        // {
        //     formData.append('resource_id', props.selectedResorceData.resource_id);    
        // }
        // formData.append('resource_title', docDetail.resource_title);
        // formData.append('resource_description', docDetail.resource_description);

        // formData.append('resource_file', docDetail.file);
        // formData.append('resource_flag', docDetail.resource_flag);
        // if(docDetail.resource)
        // {
        //     if(docDetail.resource.type.indexOf('video')>-1)
        //     {
        //         formData.append('resource_document_type_id', 2);
        //     }else{
        //         formData.append('resource_document_type_id', 1);
        //     }        
        // }else{
        //     // formData.append('resource_document_type_id', 0);
        // }

        // if(docDetail.resource_thumbnail){
        //     formData.append("thumbnail",docDetail.resource_thumbnail,docDetail.resource_thumbnail.name);

        // }else{
        //     // formData.append("thumbnail","");
        // }
        // if(docDetail.file){
        //     formData.append("thumbnail",docDetail.file,docDetail.file.name);
        // }
        // if(docDetail.resource_flag){
        //     formData.append("thumbnail",docDetail.resource_flag,docDetail.file.name);
        // }
        // if(docDetail.resource){
        //     formData.append("resource",docDetail.resource,docDetail.resource_flag);
        // }
        // else{
        //     // formData.append("resource","");
        // }

        var payload = {
            'resource_id': docDetail.resource_id,
            'resource_title': docDetail.resource_title,
            'resource_description': docDetail.resource_description,
            'resource_file_id': docDetail.resource_file_id,
            'thumbnail_file_id': docDetail.thumbnail_file_id,
        }
        // debugger;

        if (props.selectedResorceData.resource_id) {
            updateResourceAPI(payload, (res) => {

                UpdateLoader(false)
                // debugger;
                if (res.data.status) {
                    setMessage({
                        'status': true,
                        'error': false,
                        'message': "Resource Updated successfully!"
                    })
                    setDisabledBtn(true)
                }
                else {
                    setMessage({
                        'status': false,
                        'error': true,
                        'message': "Something Went  Wrong!!"
                    })
                }
                props.datareload()
                setDocDetail({
                    resource_title: "",
                    resource_description: "",
                    resource_document_type_id: 0,
                    resource_thumbnail: "",
                    resource: "",
                    resource_id: "",
                    file: "",
                    resource_flag: "",
                    thumbnail_file_id: "",
                    resource_file_id: "",
                })
                props.handleCloseDoc()
            },
                (err) => {
                    setMessage({
                        'status': false,
                        'error': true,
                        'message': "Something Went Wrong!"
                    })
                    UpdateLoader(false)
                    console.log(err);
                })

        }
        else {
            UpdateLoader(true)
            createResourceAPI(payload, (res) => {
                UpdateLoader(false)
                if (!res.data.status) {
                    setMessage({
                        'status': false,
                        'error': true,
                        'message': "Something Went Wrong!"
                    })

                } else {
                    setMessage({
                        'status': true,
                        'error': false,
                        'message': "Resource added successfully!"
                    })
                    setDisabledBtn(true)
                }
                props.datareload()
                setDocDetail({
                    resource_title: "",
                    resource_description: "",
                    resource_document_type_id: 0,
                    resource_thumbnail: "",
                    resource: "",
                    resource_id: "",
                    file: "",
                    resource_flag: "",
                    thumbnail_file_id: "",
                    resource_file_id: "",
                })
                props.handleCloseDoc()
            },
                (err) => {
                    setMessage({
                        'status': false,
                        'error': true,
                        'message': "Something Went Wrong!"
                    })
                    UpdateLoader(false)
                    console.log(err);
                })

        }

    }

    const handleClose1 = () => {
        setMessage({
            status: false,
            message: "",
            error: "",
        })
    };

    return (
        <>
            {message.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
                    <Alert onClose={handleClose1} severity={message.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {message.message}
                    </Alert>
                </Snackbar>
            }
            <Dialog
                open={props.addNewDoc}
                onClose={handlePropClose}
                aria-labelledby="responsive-dialog-title"

            >
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container className='tp-doc-main-container'>
                                <Grid item xs={10}>
                                    <Grid container className='tp-doc-sub-container'>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={10} className="tp-doc-main-heading">
                                                    <span id="tp-doc-main-heading">{(props?.selectedResorceData?.resource_id) ? "Edit Document" : "Add Document"}</span>
                                                </Grid>
                                                <Grid item xs={2} textAlign="start">
                                                    <Box id="tp-doc-close-btn"><CloseIcon onClick={handlePropClose} /></Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className='tp-doc-title-container'>
                                                <Grid item xs={12}>
                                                    {showMessage.error && <Alert severity="error">{showMessage.message}</Alert>}
                                                    {showMessage.status && <Alert severity="success">{showMessage.message}</Alert>}
                                                    <Grid container className='tp-doc-title-sub-container'>
                                                        <Grid item xs={12} className='tp-doc-lebels-field'>
                                                            <span className='tp-doc-labels'>Title<span id="astric-training-page">&nbsp;*</span></span>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                value={docDetail.resource_title}
                                                                className="tp-doc-textfields"
                                                                id="outlined-size-small"
                                                                size="medium"
                                                                placeholder="Enter Title"
                                                                onChange={(e) => {
                                                                    onChangeFun()
                                                                    setDocDetail({
                                                                        ...docDetail,
                                                                        resource_title: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container className='tp-doc-desc-sub-container'>
                                                        <Grid item xs={12} className='tp-doc-lebels-field'>
                                                            <span className='tp-doc-labels'>Description<span id="astric-training-page">&nbsp;*</span></span>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                value={docDetail.resource_description}
                                                                className="tp-doc-textfields"
                                                                id="outlined-multiline-static"
                                                                multiline
                                                                maxRows={4}

                                                                fullWidth
                                                                placeholder='Enter Description here....'
                                                                onChange={(e) => {
                                                                    onChangeFun()
                                                                    setDocDetail({
                                                                        ...docDetail,
                                                                        resource_description: e.target.value,

                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container className='tp-doc-thumbnail-sub-container'>
                                                        <Grid item xs={12} className='tp-doc-lebels-field'>
                                                            <span className='tp-doc-labels'>Thumbnail<span id="astric-training-page">&nbsp;*</span></span>
                                                            {props.selectedResorceData.resource_id ? (
                                                                <>
                                                                    <a href={props?.selectedResorceData?.resource_file_data[1]?.thumbnail_url} target="_blank">(Click Here)</a>
                                                                </>
                                                            ) : ""}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                className="tp-doc-textfields"
                                                                name='upload thumbnail'
                                                                type='file'
                                                                inputProps={{ accept: 'image/*' }}
                                                                onChange={(e) => {
                                                                    onChangeFun()
                                                                    let formData = new FormData();
                                                                    formData.append("resource_flag", false);
                                                                    formData.append("file", e.target.files[0], e.target.files[0].name,);

                                                                    uploadFilesAPI(formData, (res) => {
                                                                        if (res.data.status) {
                                                                            setDocDetail({
                                                                                ...docDetail,
                                                                                thumbnail_file_id: res.data.data['thumbnail_file_id'],
                                                                            })
                                                                        }
                                                                        else {
                                                                            setShowMessage({
                                                                                'status': false,
                                                                                'error': true,
                                                                                'message': "Something Went wrong!"
                                                                            })
                                                                        }
                                                                    },
                                                                        (err) => {
                                                                            console.log(err);
                                                                            setShowMessage({
                                                                                'status': false,
                                                                                'error': true,
                                                                                'message': "Something Went wrong!"
                                                                            })
                                                                        })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container className='tp-document-file-sub-container'>
                                                        <Grid item xs={12} className='tp-doc-lebels-field'>
                                                            <span className='tp-doc-labels'>Document<span id="astric-training-page">&nbsp;*</span></span>
                                                            {(props?.selectedResorceData?.resource_id) ? (
                                                                <>
                                                                    <a href={props?.selectedResorceData?.resource_file_data[0]?.file_url} target="_blank">(Click Here)</a>
                                                                </>
                                                            ) : ""}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                className="tp-doc-textfields"
                                                                name='upload document'
                                                                type='file'
                                                                inputProps={{ accept: 'video/*,application/pdf' }}
                                                                onChange={(e) => {
                                                                    onChangeFun()
                                                                    setLinearShow(true)
                                                                    let formData = new FormData();
                                                                    formData.append("resource_flag", true);
                                                                    formData.append("file", e.target.files[0], e.target.files[0].name);

                                                                    uploadFilesAPI(formData, (res) => {
                                                                        if (res.data.status) {
                                                                            setDocDetail({
                                                                                ...docDetail,
                                                                                resource_file_id: res.data.data['resource_file_id'],
                                                                            })
                                                                            setLinearShow(false)
                                                                        }
                                                                        else {
                                                                            setShowMessage({
                                                                                'status': false,
                                                                                'error': true,
                                                                                'message': "Something Went wrong!"
                                                                            })
                                                                            setLinearShow(false)
                                                                        }
                                                                    }, (err) => {
                                                                        console.log(err);
                                                                        setShowMessage({
                                                                            'status': false,
                                                                            'error': true,
                                                                            'message': "Something Went wrong!"
                                                                        })
                                                                        setLinearShow(false)
                                                                    })

                                                                }}
                                                            />
                                                        </Grid>
                                                        {linearShow &&
                                                            <Grid xs={12} marginTop={2}>
                                                                <LinearProgress />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={12} >
                                            <Grid container className='tp-doc-btn-sub-container'>

                                                {(props?.selectedResorceData?.resource_id) ? (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Button id="tp-doc-submit-btn" variant="contained" fullWidth disabled={disabledBtn} onClick={() => { addDocFun(); }}>Update</Button>
                                                            {/* handleSuccessOpen(); */}
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Button id="tp-doc-submit-btn" variant="contained" fullWidth disabled={disabledBtn} onClick={() => { addDocFun(); }}>&nbsp;Save</Button>
                                                            {/* handleSuccessOpen(); */}
                                                        </Grid>
                                                    </>
                                                )
                                                }






                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <AddDocSuccessPopup addDocSuccess={addDocSuccess} handleSuccessClose={handleSuccessClose} handlePropClose={handlePropClose} />

        </>
    );
}


export default TraininPageDocPopup; 