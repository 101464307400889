import React, { useContext, useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Button, Dialog, DialogContent, Grid, InputAdornment, Link, Snackbar, TextField, Typography } from '@mui/material';
import Header from '../../GlobalComponent/Header/Header';
import Footer from '../../GlobalComponent/Footer/Footer';
import './ChangePassword.css';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { sendVerificationCode, changePasswordAPI } from '../../service/login.service';
import { Context as mainContext } from '../../Context/auth_context'



const ChangePassword = () => {

    const { state, UpdateLoader, UpdateUserDetails } = useContext(mainContext)
    const [openpasswordfiledstate, setopenpasswordfiledstate] = useState(false)
    const [changePasswordDetails, setChangePasswordDetails] = useState({
        user_email: "",
        password: "",
        confirm_password: "",
        verification_code: "",
    })
    const [showMessage, setShowMessage] = useState({
        'status': false,
        'error': false,
        'message': ""

    })
    const [message, setMessage] = useState({
        status: false,
        message: "",
        error: "",
    });

    const handleClose1 = () => {
        setMessage({
            status: false,
            message: "",
            error: "",
        })
    };

    const ChangePasswordFunction = () => {
        if (!changePasswordDetails.password && !changePasswordDetails.confirm_password && !changePasswordDetails.verification_code) {

            setShowMessage({
                'status': false,
                'error': true,
                'message': "Please Fill all the Fields"
            })

        }
        if (changePasswordDetails.password != changePasswordDetails.confirm_password) {
            setShowMessage({
                'status': false,
                'error': true,
                'message': "Password does not match."
            })

        }
        UpdateLoader(true)
        let payload = {
            "password": changePasswordDetails.password,
            "confirm_password": changePasswordDetails.confirm_password,
            "verification_code": changePasswordDetails.verification_code,
        }
        changePasswordAPI(payload, (res) => {
            UpdateLoader(false)
            if (res.data.status) {
                setopenpasswordfiledstate(false)
                setShowMessage({
                    'status': true,
                    'error': false,
                    'message': "Password updated succesfully!"
                })
                setChangePasswordDetails({
                    password: "",
                    confirm_password: "",
                    verification_code: "",
                })

            }
            else {
                setShowMessage({
                    'status': false,
                    'error': true,
                    'message': res.data.error.message
                })
                setChangePasswordDetails({
                    password: "",
                    confirm_password: "",
                    verification_code: "",
                })
            }

        }, (err) => {
            console.log(err);
            UpdateLoader(false)
            setShowMessage({
                'status': false,
                'error': true,
                'message': err.data.error.message
            })
        })
    }

    useEffect(() => {
        setMessage({
            status: true,
            message: "Verification code has been sent successfully on your email account",
            error: false,
        })
    }, [])

    useEffect(() => {
        if (state.user_detail.user_details) {
            setChangePasswordDetails({
                user_email: state.user_detail.user_details.user_email
            })
        }

    }, [state])

    const sendtestverificationCodefun = () => {
        UpdateLoader(true)
        sendVerificationCode((res) => {
            if (res.data.status) {
                UpdateLoader(false)
                setopenpasswordfiledstate(true)
                setShowMessage({
                    'status': true,
                    'error': false,
                    'message': "Mail has been sent successfully!"
                })
            } else {
                UpdateLoader(false)
                setShowMessage({
                    'status': false,
                    'error': true,
                    'message': "Something Went Wrong!"
                })
            }
        }, (err) => {
            UpdateLoader(false)
            setShowMessage({
                'status': false,
                'error': true,
                'message': "Something Went Wrong!"
            })
        })
    }

    return (
        <>

            <Header />
            {message.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
                    <Alert onClose={handleClose1} severity={message.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {message.message}
                    </Alert>
                </Snackbar>
            }
            <Grid container className='change-pw-main-container'>
                <Grid item xs={11} sx={{ margin: "0 auto !important" }}>
                    <Grid container>
                        <Grid item xs={12} className='change-pw-item'>
                            <span className='change-pw-heading'>Change Password</span>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="change-pw-blue-item">
                            <Grid container className='change-pw-blue-container'>
                                <Grid item xl={5} lg={6} md={7} sm={9} xs={11} sx={{ margin: "0 auto !important" }}>
                                    <Grid container className='change-pw-container'>
                                        <Grid item xl={10} lg={10} md={11} sm={11} xs={12}>
                                            <Grid container className='change-pw-sub-container'>

                                                <Grid item xl={10} lg={10} md={10} sm={10} xs={11}>
                                                    <Grid container className='change-pw-field-container'>

                                                        <Grid item xl={9} lg={10} md={10} sm={10} xs={11} marginBottom={2}>
                                                            {showMessage.error && <Alert severity="error">{showMessage.message}</Alert>}
                                                            {showMessage.status && <Alert severity="success">{showMessage.message}</Alert>}
                                                        </Grid>
                                                        <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                            <span className='change-pw-labels'>Email</span>
                                                        </Grid>
                                                        <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                disabled={changePasswordDetails.user_email}
                                                                value={changePasswordDetails.user_email}
                                                                id="outlined-size-small"
                                                                size="small"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" className="lock-icon">
                                                                            <PersonIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* {!openpasswordfiledstate &&
                                                    <Grid item xl={4} lg={6} md={6} sm={7} xs={9} textAlign="center">
                                                        <Grid container justifyContent="center" >
                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                                <Button variant='contained' fullWidth onClick={sendtestverificationCodefun} className="change-pw-btn-label">Get Verification Code</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                } */}
                                                {/* {openpasswordfiledstate &&  */}
                                                <>
                                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={11}>
                                                        <Grid container className='change-pw-field-container'>
                                                            <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                                <span className='change-pw-labels'>New Password </span>
                                                            </Grid>
                                                            <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    // value=""
                                                                    type="password"
                                                                    autoComplete="off"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    placeholder="Enter New Password"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" className="lock-icon">
                                                                                <LockIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                        autoComplete: "none",
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setChangePasswordDetails({ ...changePasswordDetails, password: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={11}>
                                                        <Grid container className='change-pw-field-container'>
                                                            <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                                <span className='change-pw-labels'>Confirm New Password</span>
                                                            </Grid>
                                                            <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    type="password"
                                                                    autoComplete="off"
                                                                    value={changePasswordDetails.confirm_password}
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    placeholder="Enter Confirm New Password"
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" className="lock-icon">
                                                                                <LockIcon />
                                                                            </InputAdornment>
                                                                        ),
                                                                        autoComplete: "none",

                                                                    }}
                                                                    onChange={(e) => {
                                                                        setChangePasswordDetails({ ...changePasswordDetails, confirm_password: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={11}>
                                                        <Grid container className='change-pw-field-container'>
                                                            <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                                <span className='change-pw-labels'>Verification Code <span className='change-pw-code-lebel'>(Sent to {changePasswordDetails.user_email})</span></span>
                                                            </Grid>
                                                            <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    value={changePasswordDetails.verification_code}
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    placeholder="Enter Verification Code"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        if (e.target.value.length > 6) {
                                                                            return false
                                                                        }
                                                                        setChangePasswordDetails({ ...changePasswordDetails, verification_code: e.target.value })
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xl={9} lg={10} md={10} sm={10} xs={11} className="chnage-pw-resend-item">
                                                                <Link className="chnage-pw-resend-label" onClick={sendtestverificationCodefun} >Resend Verification Code</Link>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>


                                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={11} textAlign="start">
                                                        <Grid container className='change-pw-field-container'>
                                                            <Grid item xl={9} lg={10} md={10} sm={10} xs={11}>
                                                                <Button variant='contained' onClick={ChangePasswordFunction} fullWidth>Change Password</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default ChangePassword;