import React, { useEffect, useContext, useState } from 'react';
import { Route, useNavigate, useLocation, Routes, useSearchParams, unstable_HistoryRouter } from "react-router-dom";
import { UserMeAPI, getCompanyLogo } from '../service/login.service'
import Login from './LoginPage/Login';
// import Home from './HomePage/Home';
import Register from './RegisterPage/Register';
import CreateAccPopup from '../GlobalComponent/CreateAccPopup/CreateAccPopup';
import Email from '../GlobalComponent/Email/Email';
import RPage from './RPage/RPage';
import UserProfile from './UserProfile/UserProfile';
import UPFee from './UPFeePage/UPFee';
import UPTaxes from './UPTaxesPage/UPTaxes';
import UPRetirementIncome from './UPRetirementIncomePage/UPRetirementIncome';
import UPAccountValue from './UPAccountValuePage/UPAccountValue';
import UPSPComparison from './UPSPComparisonPage/UPSPComparison';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { Context as mainContext } from '../Context/auth_context'
import Loader from './Loader'
import Modules from '../GlobalComponent/ModulesPage/Modules';
import UserPage from './UserPage/UserPage';
import { TermsAndConditions } from './TermsAndConditions/TermsAndConditions';
import { PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy';
import { UserAgreement } from './UserAgreement/UserAgreement';
import { ResetPassword } from './ResetPassword/ResetPassword';
import TrainingPage from './TrainingPage/TrainingPage';
import VerifyUser from './VerifyUser/VerifyUser';
import PricingPage from './PricingPage/PricingPage';
import AccountProfile from './AccountProfile/AccountProfile';
import ChangePassword from './ChangePassword/ChangePassword';
import NewAgent from './NewAgent/NewAgent';
import NewProfile from './NewProfile/NewProfile';


const RoutePage = () => {

  const {
    state,
    UpdateUserDetails,
    UpdateCompanyDetils,
    setPreviousUrl,
    previousUrl
  } = useContext(mainContext)
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getCompanyLogo(
      (res) => {
        if (res.data.status) {
          document.title = res.data.data[0].company_name + " || Retirement Brain"
          UpdateCompanyDetils(res.data.data[0])
        }
      },
      (err) => {
        console.log("err", err)
      })

    let token = searchParams.get("token");
    let profile_id = searchParams.get("profile_id");

    if (location.pathname == "/profile/calculators" && token && profile_id) {

    }
    else if (location.pathname == "/verify_user" || location.pathname == "/forgotpassword" || location.pathname == "/register" || location.pathname == "/termsandconditions" || location.pathname == "/privacypolicy" || location.pathname == "/useragreement" || location.pathname == "/resetpassword") {

    }
    else {
      if (!state.previousUrl) {
        var getPreviousUrl = window.location.pathname + "" + window.location.search;
        var ignoreURLarr = ["login", "register", "forgotPassword", "termsandconditions", "privacypolicy", "useragreement", "resetpassword", "pricing"]
        if (getPreviousUrl && ignoreURLarr.indexOf(getPreviousUrl.toLowerCase().replace("/", ""), "") == -1) {
          setPreviousUrl(getPreviousUrl)
        }
      }

      UserMeAPI(
        (res) => {
          if (res.data.status) {
            UpdateUserDetails(res)

            if (res.data.data[0].user_data.user_status_id == 1) {
              return navigate('/verify_user')
            }

            if (location.pathname == "/" || location.pathname.includes("/login")) {
              navigate("/dashboard");
            }

            if (location.pathname == "/agent" && res.data.data[0].user_data.user_role_id != 1) {
              navigate("/dashboard");
            }
          }
          else {
            navigate("/login");
          }
        },
        (err) => {
          if (location.pathname === "/Register" || location.pathname === "/login") {
          }
          else {
            navigate("/login");
          }
        }
      )
    }

  }, []);

  return (
    <>
      {state.loading && <Loader />}
      {/* {state.error.status && <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {state.error.msg}
          </Alert>
        </Snackbar>
    } */}

      <Routes>
        <Route path="/" element={state.user_detail.loggedIn ? <UserPage /> : <Login />} />
        <Route path="/login" element={<Login />} />


        <Route path='/dashboard' element={<UserPage />} />

        <Route path="/Agent" element={<NewAgent />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/CreateAccPopup" element={<CreateAccPopup />} />
        <Route path="/Email" element={<Email />} />
        <Route path="/RPage" element={<RPage />} />
        <Route path="/Profile" element={<NewProfile />} />
        <Route path='/UserProfile' element={<UserProfile />} />
        <Route path='/UPFee' element={<UPFee />} />
        <Route path='/UPTaxes' element={<UPTaxes />} />
        <Route path='/UPRetirementIncome' element={<UPRetirementIncome />} />
        <Route path='/UPAccountValue' element={<UPAccountValue />} />
        <Route path='/UPSPComparison' element={<UPSPComparison />} />
        <Route path='/profile/calculators' element={<Modules />} />

        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/termsandconditions' element={<TermsAndConditions />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/useragreement' element={<UserAgreement />} />
        <Route path='/resetpassword' element={<ResetPassword />} />
        <Route path='/trainingpage' element={<TrainingPage />} />
        <Route path='/verify_user' element={<VerifyUser />} />
        <Route path='/accountprofile' element={<AccountProfile />} />
        <Route path='/changepassword' element={<ChangePassword />} />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path='/pricing' element={<PricingPage />} />
        {/* <Route path='/report' element={(state.user_detail.role_id == 1) ? <ReportPage />:<UserPage />} /> */}
        {/* <Route path='newagent' element={<NewAgent />} /> */}
        {/* <Route path='newprofile' element={<NewProfile />} /> */}
        {/* <Route path='error' element={<ErrorPage />} /> */}
      </Routes>
    </>
  );
}



export default RoutePage;
