import React from "react";
import './CreateAccPopup.css';
import Header from '../../GlobalComponent/Header/Header';
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Check from "../../assets/Images/Check.png"

const CreateAccPopup = () => {
    return(
        <>
        {/* <Header /> */}
            <Grid container className="popup-main-container" justifyContent="center" textAlign="center">
                <Grid item xs={5} className="popup-main-item" padding={4} marginTop="200px">
                    <Grid container justifyContent="center" textAlign="center">

                        <Grid item xs={6} padding={2}>
                            <img src={Check} alt="" />
                        </Grid>

                        <Grid item xs={12} sx={{ fontSize:15 }} className="popup-text-item" padding={1}>
                            <Grid container>
                                <Grid item xs={12} padding={1}>
                                    <Box id="popup-text1">Account for the new agent has been created successfully.</Box>
                                </Grid>
                                <Grid item xs={12} padding={1}>
                                    <Box id="pop-text2">We have sent a welcome email with login credentials to <span id="pop-email-text">johnk@mail.com</span></Box>
                                </Grid>
                            </Grid>
                        </Grid>  

                        <Grid item xs={6} padding={2}>
                            <Button id="pop-close-btn" variant="contained">Close</Button>
                        </Grid> 

                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CreateAccPopup;
