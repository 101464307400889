import React from 'react'
import './SaveToSampleProfilePopup.css';
import { Dialog, DialogContent, Grid, TextField, Typography, Button, Box, InputAdornment } from "@mui/material"
import { useState } from 'react';
import Warningmark from '../../assets/Images/Warningmark.png'
import SaveClientSuccessPopup from '../SaveClientSuccessPopup/SaveClientSuccessPopup';
import {Context as mainContext} from '../../Context/auth_context'
import { useContext } from "react";
import {SaveToSampleProfile} from '../../../src/service/login.service'
// const [saveClientSuccessState, setsaveClientSuccessState] = useState(false);

// const handleClose

// const handleOpenSaveClientSuccess = () => {
//     setsaveClientSuccessState(true);
// }

// const handleCloseSaveClientSuccess = () => {
//     setsaveClientSuccessState(true);
// }


const SaveToSampleProfilePopup = (props) => {
    const {UpdateLoader} = useContext(mainContext)

    const saveAssignAgentbtn = () => {
        let obj = {
            "user_profile_id": props.data.user_profile_id,
          }
        UpdateLoader(true)
        SaveToSampleProfile(obj,(res)=>{
            props.showMessage({
                status:true,
                message:"Data Saved Successfully!",
                error:false,
              })
            props.handleClose()
            UpdateLoader(false)
        },(err)=>{
            // setApistatus(false)
            props.showMessage({
                status:true,
                message:"Something Went Wrong!",
                error:true,
              })
            props.handleClose()
            UpdateLoader(false)
        })
    }

    return(
        <>
        <Dialog
            open={true}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title">
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container className='save-profile-main-container'>
                            
                            <Grid item xs={12} className="save-profile-text-item1">
                                <span id="save-profile-text-label">Save as a Sample Profile</span>
                            </Grid>
                            
                            <Grid item xs={12} className="save-profile-img-item">
                                <img src={Warningmark} alt="" />
                            </Grid>
                            
                            <Grid item xs={12} className="save-profile-text-item2" >
                                <span id="save-profile-text-item2">Are you sure you want save {props.data.user_profile_name} profile as a Sample Client Profile?</span>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container className="save-profile-button-container">
                                    <Grid item xs={4} className="save-profile-button-item">
                                        <Button variant='outlined' id="save-profile-no-button" onClick={props.handleClose} >No</Button>
                                    </Grid>
                                    <Grid item xs={4} className="save-profile-button-item">
                                        <Button variant='contained' id="save-profile-yes-button" onClick={saveAssignAgentbtn} >Yes</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        {/* <SaveClientSuccessPopup saveClientSuccessState={saveClientSuccessState} handleClose={handleCloseSaveClientSuccess} handleOpen={handleOpenSaveClientSuccess} /> */}
        </>
    )
}

export default SaveToSampleProfilePopup;