import { Avatar, Button, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { Box, height } from "@mui/system";
import React from "react";
import Header from "../../GlobalComponent/Header/Header";
import './UPAccountValue.css';
import ShareClientProfilePopup from "../../GlobalComponent/ShareClientProfilePopup/ShareClientProfilePopup";
import { useEffect } from "react";
import ShareIcon from '@mui/icons-material/Share';
import CreateIcon from '@mui/icons-material/Create';
import OutputAction from "../OutputAction/OutputAction";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UPAccountValue = (props) => {
    const [shareClientProfilePopup, setShareClientProfilePopup] = React.useState(false);
    let finalData = props.data

    const handleClose = () => {
        setShareClientProfilePopup(false);

    }
    const handleOpen = () => {
        setShareClientProfilePopup(true);
    }

    const formatNumber = (q) => {
        if (!q) return q;
        return q.toLocaleString('en-US', {
            style: 'currency',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            currency: "usd"
        })
    }

    return (
        <>
            {window.screen.width > 900 &&
                <Grid item xs={12}>
                    <Grid container className="up-taxes-white-container2"   >
                        {/* Fees */}
                        <Grid item xl={1} xs={1}>
                            <Grid container className="avl-left-blue-container" >
                                <Grid item className="avl-left-blue-text">
                                    <p id="avl-left-blue-retirement-text">Account Value</p>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Typical plan & IUL */}
                        <Grid item xl={10.5} xs={10.5}  >
                            <Grid container className="avl-tp-iul-container"   >

                                {/* Typical plan */}
                                <Grid item xl={4} lg={4.5} md={5.5} xs={4.5} className="avl-typical-plan-item" >
                                    <Grid container className="avl-typical-plan-container"  >

                                        <Grid item xl={10} xs={10}>
                                            <Grid container>
                                                <Grid item xl={10} xs={12}>
                                                    <Grid container className="avl-tp-head-container">
                                                        <Grid item>
                                                            <span id="avl-tp-container-heading">Typical Plan</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xl={12} xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xl={9} xs={9}>
                                                            <span id="avl-tp-label">Current Age</span>
                                                        </Grid>

                                                        <Grid item xl={3} xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{finalData?.current_age ? finalData?.current_age : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="avl-tp-label">Age at Retirement</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <Typography id="avl-tp-value">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9} >
                                                            <span id="avl-tp-label">Income Tax Rate&nbsp;<span id="avl-tp-braces-label">(Pre-Retirement)</span></span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <Typography id="avl-tp-value">{finalData?.typical_plan?.tax_rate_before_retirement ? finalData?.typical_plan?.tax_rate_before_retirement : "0"}%</Typography  >
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-hr-avl-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Annual Contributions</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{formatNumber(finalData?.typical_plan?.annualContribution) ? formatNumber(finalData?.typical_plan?.annualContribution) : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Current Account Balance</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{formatNumber(finalData?.typical_plan?.currAccountBalance) ? formatNumber(finalData?.typical_plan?.currAccountBalance) : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Annual Rate of Return</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{(finalData?.typical_plan?.rateOfReturnWorking) ? (finalData?.typical_plan?.rateOfReturnWorking) : "0"}%</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">%Fees</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{(finalData?.typical_plan?.percentFees) ? (finalData?.typical_plan?.percentFees) : "0"}%</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-hr-avl-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9} >
                                                            <span id="avl-tp-label">Employer Match&nbsp;<span id="avl-tp-braces-label">(Annual $ Contributions)</span></span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{formatNumber(finalData?.typical_plan?.employer_match) ? formatNumber(finalData?.typical_plan?.employer_match) : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                                {/* life insurance start */}
                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-life-head-container">
                                                        <span id="avl-tp-life-container-heading">Account Value at Retirement</span>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-life-container" >

                                                        <Grid item xs={12} className="avl-tp-life-sub-item">
                                                            <Grid container className="avl-tp-life-sub1-container" >
                                                                <Grid item xs={8} >
                                                                    <span id="avl-tp-life-field-label">Total contribution&nbsp; <span><span id="fontsize-label">(Excluding Employer Match)</span></span></span>
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    <Grid container className="avl-sub-field-value-conatiner">
                                                                        <Grid item>
                                                                            <span id="avl-tp-life-field-value">{formatNumber(finalData?.typical_plan?.total_contribution) ? formatNumber(finalData?.typical_plan?.total_contribution) : "$0"}</span>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-life-container-last" >
                                                        <Grid item xs={12} className="avl-tp-life-sub-item">
                                                            <Grid container className="avl-tp-life-sub1-container" >
                                                                <Grid item xs={8} >
                                                                    <span id="avl-tp-life-field-label">Total Account Value</span>
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    <Grid container className="avl-sub-field-value-conatiner">
                                                                        <Grid item>
                                                                            <span id="avl-tp-life-field-value">{formatNumber(finalData?.typical_plan?.total_account_value) ? formatNumber(finalData?.typical_plan?.total_account_value) : "$0"}</span>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* life insurance end */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* IUL */}
                                <Grid item xl={4} lg={4.5} md={5.5} xs={4.5} className="avl-iul-item">
                                    <Grid container className="avl-iul-container">
                                        <Grid item xs={10}>
                                            <Grid container>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-head-container">
                                                        <Grid item>
                                                            <span id="avl-tp-container-heading">IUL</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Current Age</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{finalData?.current_age ? finalData?.current_age : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Age at Retirement</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Income Tax Rate&nbsp;<span id="avl-tp-braces-label">(Pre-Retirement)</span></span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{finalData?.iul_plan?.tax_rate_rpe_retirement ? finalData?.iul_plan?.tax_rate_rpe_retirement : "0"}%</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-hr-avl-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Annual Premium</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{formatNumber(finalData?.iul_plan?.annual_premium) ? formatNumber(finalData?.iul_plan?.annual_premium) : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Lump Sum Premium</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{formatNumber(finalData?.iul_plan?.lumpSumPremium) ? formatNumber(finalData?.iul_plan?.lumpSumPremium) : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">Annual Rate of Return</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">{(finalData?.iul_plan?.rateOfReturn) ? (finalData?.iul_plan?.rateOfReturn) : "0"}%</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="avl-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="avl-tp-label">%Fees</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="avl-sub-value-container">
                                                                <Grid item>
                                                                    <span id="avl-tp-value">Included</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} className="avl-iul-life-item" >
                                                    <Grid container className="avl-iul-life-container">
                                                        <Grid item className="avl-iul-life-sub-item">
                                                            <Grid container>

                                                                <Grid item xs={12}>
                                                                    <Grid container className="avl-iul-life-head-container">
                                                                        <Grid item>
                                                                            <span id="avl-iul-life-container-heading">Account Value at Retirement</span>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <Grid container className="avl-iul-sub-container-tpb" >
                                                                        <Grid item xs={12} className="avl-iul-sub-item">
                                                                            <Grid container>
                                                                                <Grid item xs={8}>
                                                                                    <span id="avl-iul-life-field-label">Total Premiums Paid</span>
                                                                                </Grid>

                                                                                <Grid item xs={4}>
                                                                                    <Grid container className="avl-sub-field-value-conatiner">
                                                                                        <Grid item>
                                                                                            <span id="avl-tp-life-field-value">{formatNumber(finalData?.iul_plan?.total_premiums_paid) ? formatNumber(finalData?.iul_plan?.total_premiums_paid) : "$0"}</span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} >
                                                                    <Grid container className="avl-iul-sub-container" >
                                                                        <Grid item xs={12} className="avl-iul-sub-item">
                                                                            <Grid container >
                                                                                <Grid item xs={8}>
                                                                                    <span id="avl-iul-life-field-label">Total Account Value</span>
                                                                                </Grid>

                                                                                <Grid item xs={4}>
                                                                                    <Grid container className="avl-sub-field-value-conatiner">
                                                                                        <Grid item>
                                                                                            <span id="avl-tp-life-field-value">{formatNumber(finalData?.iul_plan?.total_account_value) ? formatNumber(finalData?.iul_plan?.total_account_value) : "$0"}</span>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>

                        {/* Share edit btn */}
                        {/* Share edit btn */}
                        <Grid item xs={0.5}>
                            <OutputAction data={props.data} inputData={props.inputData} />
                        </Grid>


                    </Grid>
                </Grid>
            }

            {window.screen.width <= 900 &&
                <>
                    <Grid container className='md-avl-main-container'>
                        <Grid item sm={11} xs={11} className='md-avl-main-item'>
                            <Grid container>
                                <Grid item sm={12} xs={12} className='md-avl-item1' >
                                    <Accordion className="md-avl-tp-main-accordion" defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="md-avl-tp-acordion">Typical Plan</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item sm={12} xs={12} className='md-avl-tp-main-item'>
                                                        <Grid container className='md-avl-tp-blue-container'>
                                                            <Grid item sm={12} xs={11} className='md-avl-tp-sub-item' >
                                                                <Grid container>
                                                                    <Grid item sm={12} xs={12}>
                                                                        <Grid container className="md-avl-tp-fields">

                                                                            <Grid item sm={12} xs={12}>
                                                                                <Grid container className="md-avl-tp-container">

                                                                                    {/* <Grid item sm={12} xs={12} className="md-avl-tp-head-item">
                                                                                    <span className="md-avl-head-text">Typical Plan</span>
                                                                                </Grid> */}

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-avl-tp-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Current Age</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {finalData?.current_age ? finalData?.current_age : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-avl-tp-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Age at Retirement</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-avl-tp-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Income Tax Rate <span className="md-avl-tp-label-span">(Pre-Retirement)</span></span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {finalData?.typical_plan?.tax_rate_before_retirement ? finalData?.typical_plan?.tax_rate_before_retirement : "0"}%
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Divider className="md-avl-devider" />
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-avl-tp-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Annual Contribution</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.typical_plan?.annualContribution) ? formatNumber(finalData?.typical_plan?.annualContribution) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-avl-tp-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Current Account Balance</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.typical_plan?.currAccountBalance) ? formatNumber(finalData?.typical_plan?.currAccountBalance) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-avl-tp-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Annual Rate of Retrurn</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {(finalData?.typical_plan?.rateOfReturnWorking) ? (finalData?.typical_plan?.rateOfReturnWorking) : "0"}%
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-avl-tp-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">%Fees</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {(finalData?.typical_plan?.percentFees) ? (finalData?.typical_plan?.percentFees) : "0"}%
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Divider className="md-avl-devider" />
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-avl-tp-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Employer Match <span className="md-avl-tp-label-span">(Annual $Contribution)</span> </span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.typical_plan?.employer_match) ? formatNumber(finalData?.typical_plan?.employer_match) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item sm={12} xs={12}>
                                                                                <Grid container>
                                                                                    <Grid item sm={12} xs={12} className="md-avl-tp-head-item2">
                                                                                        <span className="md-avl-head-text2">Account Value at Retirement</span>
                                                                                    </Grid>

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container className="md-avl-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-avl-tp-iul-label-text">Total Contribution <span className="md-avl-tp-label-span">(Excluding Employer Match)</span></span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-avl-tp-iul-value-text">
                                                                                                    {formatNumber(finalData?.typical_plan?.total_contribution) ? formatNumber(finalData?.typical_plan?.total_contribution) : "0"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container className="md-avl-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-avl-tp-iul-label-text">Total Account Value</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-avl-tp-iul-value-text">
                                                                                                    {formatNumber(finalData?.typical_plan?.total_account_value) ? formatNumber(finalData?.typical_plan?.total_account_value) : "0"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                <Grid item sm={12} xs={12} className='md-avl-item2' >
                                    <Accordion className="md-avl-iul-main-accordion" defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="md-avl-iul-acordion">IUL</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} className='md-avl-iul-main-item'>
                                                        <Grid container className='md-avl-tp-blue-container'>
                                                            <Grid item xs={11} className='md-avl-iul-sub-item' >
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Grid container className="md-avl-iul-fields">

                                                                            <Grid item xs={12}>
                                                                                <Grid container className="md-avl-iul-container">

                                                                                    {/* <Grid item xs={12} className="md-avl-iul-head-item">
                                                                                    <span className="md-avl-head-text">IUL</span>
                                                                                </Grid> */}

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-avl-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Current Age</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {finalData?.current_age ? finalData?.current_age : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-avl-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Age at Retirement</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-avl-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Income Tax Rate <span className="md-avl-tp-label-span">(Pre-Retirement)</span></span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {finalData?.iul_plan?.tax_rate_rpe_retirement ? finalData?.iul_plan?.tax_rate_rpe_retirement : "0"}%
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Divider className="md-avl-devider" />
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-avl-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Annual Premium</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.iul_plan?.annual_premium) ? formatNumber(finalData?.iul_plan?.annual_premium) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-avl-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Lump Sum Premium</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.iul_plan?.lumpSumPremium) ? formatNumber(finalData?.iul_plan?.lumpSumPremium) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-avl-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">Annual Rate of Retrurn</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">
                                                                                                            {(finalData?.iul_plan?.rateOfReturn) ? (finalData?.iul_plan?.rateOfReturn) : "0"}%
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-avl-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-avl-tp-iul-label-text">%Fees</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-avl-tp-iul-value-text">Included</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <Grid container>
                                                                                    <Grid item xs={12} className="md-avl-tp-head-item2">
                                                                                        <span className="md-avl-head-text2">Account Value at Retirement</span>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-avl-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-avl-tp-iul-label-text">Total Premiums Paid</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-avl-tp-iul-value-text">
                                                                                                    {formatNumber(finalData?.iul_plan?.total_premiums_paid) ? formatNumber(finalData?.iul_plan?.total_premiums_paid) : "0"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-avl-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-avl-tp-iul-label-text">Total Account Value</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-avl-tp-iul-value-text">
                                                                                                    {formatNumber(finalData?.iul_plan?.total_account_value) ? formatNumber(finalData?.iul_plan?.total_account_value) : "0"}
                                                                                                </span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

        </>
    );
};

export default UPAccountValue;     