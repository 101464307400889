import React, { useContext, useEffect, useState } from 'react'
import './VerifyUser.css'
import Header from "../../GlobalComponent/Header/Header";
import Footer from "../../GlobalComponent/Footer/Footer";
import { resendVerifyUserAPI, verifyUserAPI, VerifyUserAPI } from "../../service/login.service";
import { Alert, Button, Dialog, DialogContent, Grid, Link, Snackbar } from '@mui/material';
import { state } from '../../service/login.service'
import { Context as mainContext } from '../../Context/auth_context'
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress'
import Sucessmark from '../../assets/Images/Sucessmark.png'



const VerifyUser = () => {

    const { UpdateLoader, state } = useContext(mainContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const [verifyUserDone, setVerifyUserDone] = useState(false)

    const [showMessage, setShowMessage] = React.useState({
        'status': false,
        'error': false,
        'message': ""
    })
    const [errorMessage, setErrorMessage] = React.useState({
        status: false,
        error: false,
        message: ""
    })

    const handleClose1 = () => {
        setShowMessage({
            status: false,
            message: "",
            error: "",
        })

    };

    const handleErrorClose = () => {
        setErrorMessage({
            status: false,
            message: "",
            error: false,
        })
    }

    useEffect(() => {
        let verify_token = searchParams.get("verify_token");

        if (verify_token) {
            var verfyUserPayload = { verify_token: verify_token }

            UpdateLoader(true)
            verifyUserAPI(
                verfyUserPayload,
                (res) => {
                    if (res.data.status) {
                        setVerifyUserDone(true)
                        UpdateLoader(false)
                    }
                    else {
                        setErrorMessage({
                            status: true,
                            message: res.data.error.message,
                            error: true,
                        })
                        UpdateLoader(false)
                    }
                },
                (err) => {
                    navigate('/login')
                    setErrorMessage({
                        status: true,
                        message: "Somthing Went Wrong!",
                        error: true,
                    })
                    UpdateLoader(false)
                })
        }

    }, [])


    const resendVerify = () => {

        var verifyEmail = { email: state.user_detail.user_details.user_email }

        UpdateLoader(true)
        resendVerifyUserAPI(verifyEmail, (res) => {
            if (res.data.status) {
                setShowMessage({
                    status: true,
                    message: "Email Sent Successfully!",
                    error: false,
                })
                UpdateLoader(false)
            }
            else {
                setShowMessage({
                    status: true,
                    message: "Something went wrong.",
                    error: false,
                })
                UpdateLoader(false)
            }
            UpdateLoader(false)
            console.log("verify email response : ", res);
        },
            (err) => {
                console.log("verify email response", err);
                setShowMessage({
                    status: true,
                    message: "Something went wrong.",
                    error: false,
                })
                UpdateLoader(false)
            })
    }


    return (
        <>
            <Header />
            {errorMessage.status &&
                <Snackbar open={true} autoHideDuration={400} onClose={handleClose1}>
                    <Alert onClose={handleErrorClose} severity={errorMessage.error == true ? "error" : "success"} >
                        {errorMessage.message}
                    </Alert>
                </Snackbar>
            }
            {verifyUserDone &&
                <>

                    <Dialog
                        open={true}
                        aria-labelledby="responsive-dialog-title">
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container className='clienturl-main-container'>
                                        <Grid item xs={12} className="clienturl-save-sucess-img-item">
                                            <img src={Sucessmark} alt="" />
                                        </Grid>

                                        <Grid item xs={12} className="clienturl-save-sucess-item" marginTop="15px">
                                            <h2>Email Verification</h2>
                                        </Grid>

                                        <Grid item xs={12} className="clienturl-save-sucess-text-item" >
                                            <span id="client-save-sucess-text2">Your email is verified, you can continue using the site.</span>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="clienturl-save-close-button-container">
                                                <Grid item xs={4} className="clienturl-save-close-button-item">
                                                    <Button variant='contained' id="clienturl-save-close-button" onClick={() => {
                                                        navigate("/login")
                                                    }}>Back To Login Page</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </>
            }
            {/* <CircularProgress color="secondary" /> */}
            <Grid container className="vu-main-container">
                <Grid item xs={12} className="vu-main-item">
                    <Grid container>
                        <Grid item xs={9} className='vu-text-link-item' >
                            <Grid container className='vu-text-link-container'>
                                <Grid item xs={11} className='vu-text-label-item'>
                                    <span id="vu-label-text">Verify Email</span>
                                    <hr className='vu-hr' />
                                </Grid>
                                <Grid item xs={11} marginBottom={2} className='vu-text-item'>
                                    <span id="vi-info-text">Before proceeding, please check your email for a verification link. if you did not recieve the email, <span id="vu-link" class="pointerCustom" onClick={resendVerify}>click here to request another.</span></span>

                                </Grid>
                                <Grid item xs={11} marginBottom={3}>
                                    <Grid container justifyContent="right">
                                        <Grid item xs={4} >
                                            {showMessage.status &&
                                                <Alert severity={showMessage.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                                                    {showMessage.message}
                                                </Alert>

                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Footer />
        </>
    );
}

export default VerifyUser