import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, FormLabel, Grid, Stack, TextField, Typography } from '@mui/material'
import "./Login.css";
import Header from "../../GlobalComponent/Header/Header";
import Footer from "../../GlobalComponent/Footer/Footer";
import { Box } from "@mui/system";
import LoginBG from '../../assets/Images/LoginBG.png'
import Loginblureimg from '../../assets/Images/Loginblureimg.png'
// import Blueimg from '../../assets/Images/Blueimg.png'
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import { Context as mainContext } from '../../Context/auth_context'
import { MobileFooter } from "../../GlobalComponent/MobileFooter/MobileFooter";
import { LoginAPI } from "../../service/login.service";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Login = () => {

    const { loginCall, state, updateErrorMessage, UpdateUserDetails, setPreviousUrl, previousUrl } = useContext(mainContext)
    const navigate = useNavigate();

    const [loginCred, setLoginCred] = useState({
        "email": "",
        "password": "",
        "remember_me_flag": false
    })
    const [showMessage, setShowMessage] = useState({
        'status': false,
        'error': false,
        'message': ""
    })

    const userLoginFunction = async () => {
        try {
            updateErrorMessage({ status: false, message: "" })
            if (!loginCred.email || !loginCred.password) {
                setShowMessage({
                    'status': false,
                    'error': true,
                    'message': "You have entered an invalid username or password!"
                })
                return
            }
            setShowMessage({
                'status': false,
                'error': false,
                'message': ""
            })
            await LoginAPI(loginCred, (res) => {
                if (res.data.status == true) {
                    // console.log("login api response", res)
                    UpdateUserDetails(res)
                    if (res.data.data[0].user_data.user_status_id == 1) {
                        navigate('/verify_user')
                    }
                    else {
                        if (state.previousUrl && res.data.data[0].user_data.user_role_id == 1) {
                            navigate(state.previousUrl);
                            setPreviousUrl("")
                            return

                        }
                        else {
                            navigate('/dashboard')
                        }
                    }
                }
                else {
                    setShowMessage({
                        'status': false,
                        'error': true,
                        'message': res.data.error.message
                    })
                }
                return true;
            }, (err) => {
                console.log(err);
                setShowMessage({
                    'status': false,
                    'error': true,
                    'message': err.data.error.message
                })
                //   navigate('/login')
                return false;
            }
            );
        } catch (e) {
            setShowMessage({
                'status': false,
                'error': true,
                'message': "Something went wrong!"
            })
        }
    }

    return (
        <>
            <Header />
            <Grid container className="login-main-contaiber">
                <Grid item xs={12} className="login-img-main-item">
                    <Grid container className="login-img-main-container">
                        <Grid item xl={4} lg={5} md={6} sm={10} xs={11} className="login-blure-img-item" >
                            <Grid container className="login-items-center-container">
                                <Grid item xs={10} sm={9} md={8}>
                                    <Grid container className="login-fields-main-container">

                                        <Grid item xs={12}>
                                            <Grid container className="login-fields-sub-container">
                                                <Typography id="login-tittle">Login</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="login-email-field-container">
                                                <Grid item xs={12} marginBottom={0.3}>
                                                    {showMessage.error && <Alert severity="error" >{showMessage.message}</Alert>}
                                                </Grid>
                                                <Grid item xs={12} marginBottom={0.3}>
                                                    {state?.error?.status && <Alert severity="error">{state.error.message}</Alert>}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container className="login-email-field-label">
                                                        <Typography id="login-fields-label">Email Address</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="login-email-textfield-container ">
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className="login-email-textfield userLoginInputEmailAdress"
                                                                id="outlined-size-small"
                                                                name="userLoginInputEmailAdress"
                                                                size="small"
                                                                placeholder="Enter Email"
                                                                value={loginCred.email}
                                                                onChange={(e) => { setLoginCred({ ...loginCred, email: e.target.value }) }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" className="person-icon">
                                                                            <PersonIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="login-password-field-container">
                                                <Grid item xs={12}>
                                                    <Grid container className="login-password-field-label">
                                                        <Typography id="login-fields-label">Password</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="login-email-textfield-container">
                                                        <TextField
                                                            className="login-password-textfield userLoginInputPassword"
                                                            id="outlined-size-small"
                                                            name="userLoginInputPassword"
                                                            size="small"
                                                            type="password"
                                                            placeholder="Enter Password"
                                                            value={loginCred.password}
                                                            onChange={(e) => { setLoginCred({ ...loginCred, password: e.target.value }) }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start" className="lock-icon">
                                                                        <LockOutlinedIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="login-remember-main-container">
                                                <Grid item xs={6} sm={6} >
                                                    <Grid container className="login-remember-container">
                                                        <Box id="login-remember-forgot-label">
                                                            <Checkbox id="checkbox" onChange={(e) => { setLoginCred({ ...loginCred, remember_me_flag: e.target.checked }) }} /><span id="login-remember-me-label">Remember Me</span>
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={6} sm={6}>
                                                    <Grid container className="login-forgot-container">
                                                        <span variant="link" id="login-remember-forgot-label" className="p-0" onClick={() => {
                                                            navigate("/forgotpassword");
                                                        }}>Forgot Password?</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="login-create-btn-container">
                                                <Grid item xs={12}>
                                                    <Grid container className="login-btn-container">
                                                        <Button variant="contained" id="login-btn-text" onClick={userLoginFunction} >Login</Button>
                                                    </Grid>
                                                </Grid>


                                                <Grid item xs={12}>
                                                    <Grid container className="create-btn-container">
                                                        <Button variant="outlined" onClick={() => {
                                                            window.location.href = "/Register";

                                                        }} id="create-acc-btn-text" >Create New Account</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
            <MobileFooter className="mobilefooterResposive" />
        </>
    )

}

export default Login;
