import { Avatar, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import './UPRetirementIncome.css';
import ShareIcon from '@mui/icons-material/Share';
import CreateIcon from '@mui/icons-material/Create';
import OutputAction from "../OutputAction/OutputAction";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from "react";


const UPRetirementIncome = (props) => {

    let finalData = props?.data
    var taxTypicalAge = []
    var taxIul_planAge = []



    if (finalData?.typical_plan?.RI_Age_data && finalData?.iul_plan?.RI_Age_data) {
        taxTypicalAge = Object.keys(finalData?.typical_plan?.RI_Age_data)
        taxIul_planAge = Object.keys(finalData?.iul_plan?.RI_Age_data)
        var riTpAgeMin = taxTypicalAge[0]
        var riTpAgeMax = taxTypicalAge[taxTypicalAge.length - 1]
        var riIULAgeMin = taxIul_planAge[0]
        var riIULAgeMax = taxIul_planAge[taxIul_planAge.length - 1]
    }
    const [changeYear, setChangeYear] = useState(finalData.ageAtRetirement)
    const [changeYearIUL, setChangeYearIUL] = useState(finalData.ageAtRetirement)
    const [calculationShowError, setCalculationShowError] = useState(false)
    const [calculationShowErrorIUL, setCalculationShowErrorIUL] = useState(false)
    const formatNumber = (q) => {
        if (q || q == 0) {
            return q.toLocaleString('en-US', {
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                currency: "usd"
            })
        }
    }

    const inputChangeYearIUL = (e) => {
        let val = parseInt(e.target.value);
        if (val >= Number(taxIul_planAge[0]) && val <= Number(taxIul_planAge[taxIul_planAge.length - 1])) {
            setChangeYearIUL(val)
        }
        else {
            return
        }
        setCalculationShowErrorIUL(false)
        if (val == Number(taxIul_planAge[taxIul_planAge.length - 1]) || (changeYearIUL == Number(taxIul_planAge[taxIul_planAge.length - 1]) && val == changeYearIUL)) {
            setCalculationShowErrorIUL(true)
        }

    }

    const isBetween = (num1, num2, value) => value > num1 && value < num2;
    const inputBlureChangeYearIUL = (e) => {
        let val = parseInt(e.target.value);
        let checkInBeetween = isBetween(Number(taxIul_planAge[0]), Number(taxIul_planAge[taxIul_planAge.length - 1]), val)
        if (checkInBeetween == false) {
            setChangeYearIUL(taxIul_planAge[0])
            return
        }
    }

    useEffect(() => {
        document.getElementById("outlined-basic").addEventListener("mouseup", () => {
            inputChangeYear();
        })
        document.getElementById("outlined-basic").addEventListener("mousedown", () => {
            inputChangeYear();
        })
    }, [])

    useEffect(() => {
        if (changeYear == Number(taxTypicalAge[taxTypicalAge.length - 1])) {
            setCalculationShowError(true)
        } else {
            setCalculationShowError(false)
        }
    }, [changeYear])

    useEffect(() => {
        if (changeYearIUL == Number(taxIul_planAge[taxIul_planAge.length - 1])) {
            setCalculationShowErrorIUL(true)
        } else {
            setCalculationShowErrorIUL(false)
        }
    }, [changeYearIUL])

    const inputChangeYear = (e) => {
        let val = parseInt(e.target.value);
        if (val >= Number(taxTypicalAge[0]) && val <= Number(taxTypicalAge[taxTypicalAge.length - 1])) {
            setChangeYear(val)
        }
        else {

            if (val >= Number(taxTypicalAge[taxTypicalAge.length - 1])) {
                setChangeYear(Number(taxTypicalAge[taxTypicalAge.length - 1]))
                val = Number(taxTypicalAge[taxTypicalAge.length - 1]);
            }
            else {
                setChangeYear(Number(taxTypicalAge[0]))
                val = Number(taxTypicalAge[0]);
            }
        }


    }

    const InputChangeYearIUL = (e) => {
        let val = parseInt(e.target.value);

        if (val >= Number(taxIul_planAge[0]) && val <= Number(taxIul_planAge[taxIul_planAge.length - 1])) {
            setChangeYearIUL(val)
        }
        else {

            if (val >= Number(taxIul_planAge[taxIul_planAge.length - 1]))
                setChangeYearIUL(Number(taxIul_planAge[taxIul_planAge.length - 1]))
            else {
                setChangeYearIUL(Number(taxIul_planAge[0]))
            }
        }
    }

    const isInputBetween = (num1, num2, value) => value > num1 && value < num2;

    const inputBlureChangeYear = (e) => {
        let val = parseInt(e.target.value);
        let checkInBeetween = isInputBetween(Number(taxTypicalAge[0]), Number(taxTypicalAge[taxTypicalAge.length - 1]), val)
        if (checkInBeetween == false) {
            if (val >= Number(taxTypicalAge[taxTypicalAge.length - 1]))
                setChangeYear(Number(taxTypicalAge[taxTypicalAge.length - 1]))
            else {
                setChangeYear(Number(taxTypicalAge[0]))
            }
            return
        }
    }

    const InputBlurChangeIUL = (e) => {
        let val = parseInt(e.target.value);
        let checkInBeetween = isInputBetween(Number(taxIul_planAge[0]), Number(taxIul_planAge[taxIul_planAge.length - 1]), val)
        if (checkInBeetween == false) {
            if (val >= Number(taxIul_planAge[taxIul_planAge.length - 1]))
                setChangeYearIUL(Number(taxIul_planAge[taxIul_planAge.length - 1]))
            else {
                setChangeYearIUL(Number(taxIul_planAge[0]))
            }
            return
        }
    }

    return (

        <>
            {window.screen.width > 900 &&

                <Grid item xs={12}>
                    <Grid container className="up-ri-white-container2"  >
                        {/* Fees */}
                        <Grid item xs={1}>
                            <Grid container className="ri-left-blue-container" >
                                <Grid item className="ri-left-blue-text">
                                    <p id="ri-left-blue-retirement-text">Retirement Income</p>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Typical plan & IUL */}
                        <Grid item xs={10.5}  >
                            <Grid container className="ri-tp-iul-container"   >

                                {/* Typical plan */}
                                <Grid item xl={4} lg={5} md={5.5} xs={4.5} className="ri-typical-plan-item">
                                    <Grid container className={(calculationShowError) ? "ri-typical-plan-container redBorder" : "ri-typical-plan-container"}>

                                        <Grid item xs={10}>
                                            <Grid container>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-head-container">
                                                        <Grid item>
                                                            <span id="ri-tp-container-heading">Typical Plan</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="ri-tp-label">Age at Retirement</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <span id="ri-tp-value">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-container">
                                                        <Grid item xs={9} >
                                                            <span id="ri-tp-label">Tax Rate&nbsp;<span id="ri-tp-braces-label">(During Retirement)</span></span>
                                                            {/* <Typography id="ri-tp-braces-label">(During Retirement)</Typography> */}
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <span id="ri-tp-value">
                                                                        {finalData?.typical_plan?.tax_rate_during_retirement ? finalData?.typical_plan?.tax_rate_during_retirement : "0"}%
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="ri-tp-label">Annual withdraw from Account</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <Typography id="ri-tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.annual_pre_tax_income_in_retirement) ? formatNumber(finalData?.typical_plan?.annual_pre_tax_income_in_retirement) : "0"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="ri-tp-label">Rate of Return&nbsp;<span id="ri-tp-braces-label">(During Retirement)</span></span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <span id="ri-tp-value">{(finalData?.typical_plan?.rateOfReturnRetirement) ? (finalData?.typical_plan?.rateOfReturnRetirement) : "0"}%</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-hr-ri-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="ri-tp-label">Total Contributions to Account</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <span id="ri-tp-value">{formatNumber(finalData?.typical_plan?.total_contribution) ? formatNumber(finalData?.typical_plan?.total_contribution) : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                                {/* life insurance start */}
                                                <Grid item xs={12} className="ri-tp-life-item">
                                                    <Grid container>

                                                        <Grid item xs={12}>
                                                            <Grid container className="ri-tp-life-head-container">
                                                                <Grid item>
                                                                    <span id="ri-tp-life-container-heading">Income During Retirement</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="ri-tp-life-container" >
                                                                <Grid item xs={12} className="ri-tp-life-sub-item">
                                                                    <Grid container className="ri-tp-life-sub1-container" >
                                                                        <Grid item xs={8} >
                                                                            <span id="ri-tp-life-field-label">Account Value</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            {/* <Grid container className={parseFloat(finalData.typical_plan.RI_Age_data[changeYear]['account_value'], 10) > 0  ? "ri-tp-sub-field-value-conatiner":"ri-tp-sub-field-value-conatiner errorMinusValueDeferred"}> */}
                                                                            <Grid container className="ri-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="ri-tp-life-field-value">
                                                                                        {formatNumber(finalData?.typical_plan?.RI_Age_data[changeYear]?.account_value) ? formatNumber(finalData?.typical_plan?.RI_Age_data[changeYear]?.account_value) : "$0"}</span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="ri-tp-life-container" >
                                                                <Grid item xs={12} className="ri-tp-life-sub-item">
                                                                    <Grid container className="ri-tp-life-sub1-container" >
                                                                        <Grid item xs={8} >
                                                                            <span id="ri-tp-life-field-label">Annual Spendable Income</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="ri-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="ri-tp-life-field-value">
                                                                                        {formatNumber(finalData?.typical_plan?.RI_Age_data[changeYear]?.annual_sp_income) ? formatNumber(finalData?.typical_plan?.RI_Age_data[changeYear]?.annual_sp_income) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className={calculationShowError ? "ri-tp-life-container-last-true" : "ri-tp-life-container-last"} >
                                                                <Grid item xs={12} className="ri-tp-life-sub-item">
                                                                    <Grid container className="ri-tp-life-sub1-container" >
                                                                        <Grid item xs={8} >
                                                                            <span id="ri-tp-life-field-label">Total Spendable Income</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="ri-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="ri-tp-life-field-value">
                                                                                        {formatNumber(finalData?.typical_plan?.RI_Age_data[changeYear]?.total_annual_sp_income) ? formatNumber(finalData?.typical_plan?.RI_Age_data[changeYear]?.total_annual_sp_income) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {calculationShowError &&
                                                            <Grid item xs={12}>
                                                                <Grid container className="all-module-red-label" >
                                                                    <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                        <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>TYPICAL PLAN ACCOUNT IS EMPTY</span>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                    </Grid>
                                                </Grid>
                                                {/* life insurance end */}
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* IUL */}
                                <Grid item xl={4} lg={5} md={5.5} xs={4.5} className="ri-iul-item">
                                    <Grid container className="ri-iul-container">

                                        <Grid item xs={10}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-head-container">
                                                        <Grid item>
                                                            <span id="ri-tp-container-heading">IUL</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="ri-tp-label">Age at Retirement</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <span id="ri-tp-value">{finalData.ageAtRetirement ? finalData.ageAtRetirement : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-container">
                                                        <Grid item xs={9} >
                                                            <span id="ri-tp-label">Tax Rate&nbsp;<span id="ri-tp-braces-label">(During Retirement)</span></span>
                                                            {/* <Typography id="ri-tp-braces-label">(During Retirement)</Typography> */}
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <span id="ri-tp-value">0%</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-iul-container-last1">
                                                        <Grid item xs={9}>
                                                            <span id="ri-tp-label">Annual Draw Against Account</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <span id="ri-tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.annual_spendable_income) ? formatNumber(finalData?.typical_plan?.annual_spendable_income) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-hr-ri-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="ri-tp-sub-iul-container-last2">
                                                        <Grid item xs={9}>
                                                            <span id="ri-tp-label">Total Premium Paid</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="ri-sub-value-container">
                                                                <Grid item>
                                                                    <Typography id="ri-tp-value">
                                                                        {formatNumber(finalData?.iul_plan?.total_premiums_paid) ? formatNumber(finalData?.iul_plan?.total_premiums_paid) : "0"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                                {/* Account value at retirement */}
                                                <Grid item xs={12} className="ri-iul-life-item">
                                                    <Grid container className="ri-iul-life-container">
                                                        <Grid item xs={12}>
                                                            <Grid container className="ri-iul-life-head-container">
                                                                <span id="ri-iul-life-container-heading">Account Value at Retirement</span>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="ri-iul-sub-container" >
                                                                <Grid item xs={12} className="ri-iul-sub-item">
                                                                    <Grid container >
                                                                        <Grid item xs={8}>
                                                                            <span id="ri-iul-life-field-label">Account Value</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="ri-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="ri-tp-life-field-value">
                                                                                        {formatNumber(finalData?.iul_plan?.RI_Age_data[changeYearIUL]?.account_value) ? formatNumber(finalData?.iul_plan?.RI_Age_data[changeYearIUL]?.account_value) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="ri-iul-sub-container" >
                                                                <Grid item xs={12} className="ri-iul-sub-item">
                                                                    <Grid container>
                                                                        <Grid item xs={8}>
                                                                            <span id="ri-iul-life-field-label">Annual Spendable Income</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="ri-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="ri-tp-life-field-value">
                                                                                        {formatNumber(finalData?.iul_plan?.RI_Age_data[changeYearIUL]?.annual_sp_income) ? formatNumber(finalData?.iul_plan?.RI_Age_data[changeYearIUL]?.annual_sp_income) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="ri-iul-sub-container" >
                                                                <Grid item xs={12} className="ri-iul-sub-item">
                                                                    <Grid container >
                                                                        <Grid item xs={8}>
                                                                            <span id="ri-iul-life-field-label">Total Spendable Income</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="ri-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="ri-tp-life-field-value">
                                                                                        {formatNumber(finalData?.iul_plan?.RI_Age_data[changeYearIUL]?.total_annual_sp_income) ? formatNumber(finalData?.iul_plan?.RI_Age_data[changeYearIUL]?.total_annual_sp_income) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="ri-iul-sub-container" >
                                                                <Grid item xs={12} className="ri-iul-sub-item">
                                                                    <Grid container >
                                                                        <Grid item xs={8}>
                                                                            <span id="ri-iul-life-field-label">Total Death Benefit</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="ri-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="ri-tp-life-field-value">
                                                                                        {formatNumber(finalData?.iul_plan?.RI_Age_data[changeYearIUL]?.total_death_benefit) ? formatNumber(finalData?.iul_plan?.RI_Age_data[changeYearIUL]?.total_death_benefit) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {calculationShowErrorIUL &&
                                                            <>
                                                            </>
                                                            // <Grid item xs={12}>
                                                            //     <Grid container className="all-module-red-label2" >
                                                            //         <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                            //             <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>IUL PLAN ACCOUNT IS EMPTY</span>
                                                            //         </Grid>
                                                            //     </Grid>
                                                            // </Grid>
                                                        }

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Age */}
                                <Grid item xs={12}>
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Grid container className="ri-tp-iul-age-main-container">

                                                <Grid item xl={4} lg={5} md={5.5} xs={4.5} className="ri-tp-age-sub-item" >
                                                    <Grid container className="ri-tp-age-sub-container" >
                                                        <Grid item xs={6} className="ri-tp-iul-age-text">
                                                            <Typography id="ri-tp-age-label">Age:</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} >
                                                            <Grid container className="ri-tp-iul-age-scroll-btn">
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    className="ri-tp-digit-field"
                                                                    type="number"
                                                                    InputProps={{ inputProps: { min: riTpAgeMin, max: riTpAgeMax } }}
                                                                    onChange={(e) => {
                                                                        setChangeYear(e.target.value);
                                                                    }}
                                                                    onBlur={inputBlureChangeYear}
                                                                    onKeyUp={(e) => {
                                                                        if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                            inputChangeYear(e);
                                                                    }}
                                                                    value={changeYear}
                                                                    size="small"
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xl={4} lg={5} md={5.5} xs={4.5} className="ri-iul-age-sub-item" >
                                                    <Grid container className="ri-tp-age-sub-container">
                                                        <Grid item xs={6} className="ri-tp-iul-age-text" >
                                                            <Typography id="ri-tp-age-label">Age:</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} >
                                                            <Grid container className="ri-tp-iul-age-scroll-btn">
                                                                {/* <Grid item> */}
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    className="ri-tp-digit-field"
                                                                    type="number"
                                                                    InputProps={{ inputProps: { min: riIULAgeMin, max: riIULAgeMax } }}
                                                                    onChange={(e) => {
                                                                        setChangeYearIUL(e.target.value);
                                                                    }}
                                                                    onBlur={InputBlurChangeIUL}
                                                                    onKeyUp={(e) => {
                                                                        if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                            InputChangeYearIUL(e);
                                                                    }}
                                                                    value={changeYearIUL}
                                                                    size="small"
                                                                    variant="outlined" />
                                                                {/* </Grid> */}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Share edit btn */}
                        <Grid item xs={0.5}>
                            <OutputAction data={props.data} inputData={props.inputData} />
                        </Grid>
                    </Grid>
                </Grid>

            }

            {window.screen.width <= 900 &&
                <>
                    <Grid container className='md-avl-main-container'>
                        <Grid item sm={11} xs={11} className='md-ri-main-item'>
                            <Grid container>
                                <Grid item sm={12} xs={12} className='md-ri-item1' >
                                    <Accordion className="md-ri-tp-main-accordion" defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="md-ri-tp-acordion">Typical Plan</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item sm={12} xs={12}>
                                                        <Grid container>
                                                            <Grid item sm={12} xs={12} className='md-ri-tp-main-item'>
                                                                <Grid container className='md-ri-tp-blue-container'>
                                                                    <Grid item sm={12} xs={11} className='md-ri-tp-sub-item' >
                                                                        <Grid container>
                                                                            <Grid item sm={12} xs={12}>
                                                                                <Grid container className="md-ri-tp-fields">

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container className="md-ri-tp-container">

                                                                                            {/* <Grid item sm={12} xs={12} className="md-ri-tp-head-item">
                                                                                                <span className="md-ri-head-text">Typical Plan</span>
                                                                                            </Grid> */}

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-ri-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-ri-tp-iul-label-text">Age at Retirement</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-ri-tp-iul-value-text">{finalData.ageAtRetirement}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-ri-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-ri-tp-iul-label-text">Tax Rate <span className="md-avl-tp-label-span">(During Retirement)</span></span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-ri-tp-iul-value-text">{finalData.typical_plan.tax_rate_during_retirement}%</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-ri-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-ri-tp-iul-label-text">Annual withdraw from account</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.typical_plan.annual_pre_tax_income_in_retirement)}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-ri-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-ri-tp-iul-label-text">Rate of Return <span className="md-avl-tp-label-span">(During Retirement)</span></span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-ri-tp-iul-value-text">{(finalData.typical_plan.rateOfReturnRetirement)}%</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item xs={12}>
                                                                                                        <Divider className="md-avl-devider" />
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-ri-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-ri-tp-iul-label-text">Total Contributions to account</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.typical_plan.total_contribution)}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container>
                                                                                            <Grid item sm={12} xs={12} className="md-ri-tp-head-item2">
                                                                                                <span className="md-ri-head-text2">Income During Retirement</span>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-ri-av-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-ri-tp-iul-label-text">Account Value</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.typical_plan.RI_Age_data[changeYear]?.account_value) ? formatNumber(finalData.typical_plan.RI_Age_data[changeYear]?.account_value) : "$0"}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-ri-av-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-ri-tp-iul-label-text">Annual Spandable Income</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.typical_plan.RI_Age_data[changeYear]?.annual_sp_income) ? formatNumber(finalData.typical_plan.RI_Age_data[changeYear]?.annual_sp_income) : "$0"}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-ri-av-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-ri-tp-iul-label-text">Total Spandable Income</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.typical_plan.RI_Age_data[changeYear]?.total_annual_sp_income) ? formatNumber(finalData.typical_plan.RI_Age_data[changeYear]?.total_annual_sp_income) : "$0"}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            {calculationShowError &&
                                                                                                <Grid item xs={12}>
                                                                                                    <Grid container className="taxes-tp-life-container-last" marginTop={1}>
                                                                                                        <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                                                            <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>TYPICAL PLAN ACCOUNT IS EMPTY</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            }

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sm={12} xs={12} >
                                                        <Grid container className="md-age-container">
                                                            <Grid item sm={9} xs={8} className='md-age-item1'>
                                                                <span className="md-age-label">Age: </span>
                                                            </Grid>
                                                            <Grid item sm={3} xs={4} className='md-age-item2'>
                                                                <Grid container>
                                                                    <Grid item xs={12} className='md-age-num-item'>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            type="number"
                                                                            InputProps={{ inputProps: { min: riTpAgeMin, max: riTpAgeMax } }}
                                                                            onChange={(e) => {
                                                                                setChangeYear(e.target.value);
                                                                            }}
                                                                            onBlur={inputBlureChangeYear}
                                                                            onKeyUp={(e) => {
                                                                                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                                    inputChangeYear(e);
                                                                            }}
                                                                            value={changeYear}
                                                                            size="small"
                                                                            variant="outlined" />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                <Grid item sm={12} xs={12} className='md-ri-item2' >
                                    <Accordion className="md-ri-iul-main-accordion" defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="md-ri-iul-acordion">IUL</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} className='md-ri-iul-main-item'>
                                                        <Grid container className='md-ri-tp-blue-container'>
                                                            <Grid item xs={11} className='md-ri-iul-sub-item' >
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Grid container className="md-ri-iul-fields">

                                                                            <Grid item xs={12}>
                                                                                <Grid container className="md-ri-iul-container">

                                                                                    {/* <Grid item xs={12} className="md-avl-iul-head-item">
                                                                                        <span className="md-ri-head-text">IUL</span>
                                                                                    </Grid> */}

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-ri-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-ri-tp-iul-label-text">Age at Retirement</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-ri-tp-iul-value-text">{finalData.ageAtRetirement}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-ri-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-ri-tp-iul-label-text">Tax Rate <span className="md-avl-tp-label-span">(During Retirement)</span></span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-ri-tp-iul-value-text">0%</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-ri-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-ri-tp-iul-label-text">Annual draw against account   </span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.typical_plan.annual_spendable_income)}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Divider className="md-avl-devider" />
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-ri-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-ri-tp-iul-label-text">Total Premium Paid</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.iul_plan.total_premiums_paid)}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <Grid container>
                                                                                    <Grid item xs={12} className="md-ri-tp-head-item2">
                                                                                        <span className="md-ri-head-text2">Account Value at Retirement</span>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-ri-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-ri-tp-iul-label-text">Account Value</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.iul_plan.RI_Age_data[changeYearIUL]?.account_value) ? formatNumber(finalData.iul_plan.RI_Age_data[changeYearIUL]?.account_value) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-ri-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-ri-tp-iul-label-text">Annual Spendable Income</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.iul_plan.RI_Age_data[changeYearIUL]?.annual_sp_income) ? formatNumber(finalData.iul_plan.RI_Age_data[changeYearIUL]?.annual_sp_income) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-ri-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-ri-tp-iul-label-text">Total Spendable Income</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.iul_plan.RI_Age_data[changeYearIUL]?.total_annual_sp_income) ? formatNumber(finalData.iul_plan.RI_Age_data[changeYearIUL]?.total_annual_sp_income) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-ri-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-ri-tp-iul-label-text">Total Death Benefit</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-ri-tp-iul-value-text">{formatNumber(finalData.iul_plan.RI_Age_data[changeYearIUL]?.total_death_benefit) ? formatNumber(finalData.iul_plan.RI_Age_data[changeYearIUL]?.total_death_benefit) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>



                                                                                    {calculationShowErrorIUL &&
                                                                                        <>
                                                                                        </>
                                                                                        // <Grid item xs={12}>
                                                                                        //     <Grid container className="taxes-tp-life-container-last" marginTop={1}>
                                                                                        //         <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                                        //             <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>IUL PLAN ACCOUNT IS EMPTY</span>
                                                                                        //         </Grid>
                                                                                        //     </Grid>
                                                                                        // </Grid>
                                                                                    }

                                                                                </Grid>
                                                                            </Grid>



                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sm={12} xs={12} >
                                                        <Grid container className="md-age-container">
                                                            <Grid item sm={9} xs={8} className='md-age-item1'>
                                                                <span className="md-age-label">Age: </span>
                                                            </Grid>
                                                            <Grid item sm={3} xs={4} className='md-age-item2'>
                                                                <Grid container>
                                                                    <Grid item xs={12} className='md-age-num-item'>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            type="number"
                                                                            InputProps={{ inputProps: { min: riIULAgeMin, max: riIULAgeMax } }}
                                                                            onChange={(e) => {
                                                                                setChangeYearIUL(e.target.value);
                                                                            }}
                                                                            onBlur={InputBlurChangeIUL}
                                                                            onKeyUp={(e) => {
                                                                                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                                    InputChangeYearIUL(e);
                                                                            }}
                                                                            value={changeYearIUL}
                                                                            size="small"
                                                                            variant="outlined"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    );
};

export default UPRetirementIncome;     