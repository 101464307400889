import { Alert, Avatar, avatarClasses, Button, Divider, Grid, IconButton, LinearProgress, TextareaAutosize, TextField, Tooltip } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import Footer from '../../GlobalComponent/Footer/Footer';
import Header from '../../GlobalComponent/Header/Header';
import './AccountProfile.css'
import { Context as mainContext } from '../../Context/auth_context'
import { getUploadResourceData, updateAgentData, uploadFilesAPI, UserMeAPI } from '../../service/login.service';

const AccountProfile = () => {

    const { state, UpdateLoader, UpdateUserDetails } = useContext(mainContext)

    const [userDetails, setUserDetails] = useState({
        user_name: "",
        user_email: "",
        firstname: "",
        lastname: "",
        contact: "",
        userprofileid: "",
        userprofileimageurl: "",
        userprofileimageid: ""
    })
    const [linearShow, setLinearShow] = useState(false);
    const [showMessage, setShowMessage] = useState({
        'status': false,
        'error': false,
        'message': ""

    })

    useEffect(() => {
        if (state.user_detail.user_details) {
            setUserDetails({
                user_name: state.user_detail.user_details.user_name,
                user_email: state.user_detail.user_details.user_email,
                firstname: state.user_detail.user_details.firstname,
                lastname: state.user_detail.user_details.lastname,
                contact: state.user_detail.user_details.user_contact,
                userprofileid: "",
                userprofileimageurl: state.user_detail.user_details.user_profile_image,
                userprofileimageid: state.user_detail.user_details.user_profile_image_id
            })
        }
    }, [state])


    const updateprofilefunc = () => {
        if (!userDetails.firstname || !userDetails.contact) {
            return setShowMessage({
                'status': false,
                'error': true,
                'message': "Please Fill required fields"
            })
        }
        var payload = {
            "firstname": userDetails.firstname,
            "lastname": userDetails.lastname,
            "contact": userDetails.contact,
            "image_flag": (userDetails.userprofileid) ? true : true,
            "image_id": userDetails.userprofileimageid ? userDetails.userprofileimageid : null
            // "image_id": (userDetails.userprofileid) ? userDetails.userprofileid : null
        }
        updateAgentData(payload, (res) => {
            console.log(res);
            if (res.data.status) {
                setShowMessage({
                    'status': true,
                    'error': false,
                    'message': "Data updated succesfully!"
                })
                UserMeAPI((res) => {
                    console.log("userme api response in update agent data call::", res);
                    UpdateUserDetails(res)
                },
                    (err) => {
                        setShowMessage({
                            'status': false,
                            'error': true,
                            'message': "Something went wrong!"
                        })
                    })
            } else {
                setShowMessage({
                    'status': false,
                    'error': true,
                    'message': "Something went wrong!"
                })
            }
        }, (err) => {
            console.log(err);
            setShowMessage({
                'status': false,
                'error': true,
                'message': "Something went wrong!"
            })
        })
    }


    return (
        <>
            <Header />
            <Grid container className="ap-main-container">
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xl={11} xs={11} sx={{ margin: "0 auto !important" }}>
                            <Grid container sx={{ justifyContent: "center" }} >
                                <Grid item xl={11} xs={10} textAlign="center" className='ap-main-hading-item'>
                                    <span>My Account</span>
                                </Grid>

                                <Grid item xl={12} lg={12} md={12} xs={12}>

                                    <Grid container className='ap-img-form-container'>
                                        <Grid item xl={10} lg={11} md={11} sm={10} xs={12}>
                                            <Grid container className='ap-blue-main-container' sx={{ justifyContent: "center" }} >

                                                <Grid item xl={8} lg={11} md={11} sm={9} xs={11} className='ap-form-main-container'>

                                                    <Grid container justifyContent="center">


                                                        {/* Profile Image */}

                                                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} className="ap-image-item">
                                                            <Grid container className='ap-blue-avtar-container'>
                                                                {/* <Grid item xl={12} sm={12} xs={12} className="ap-avtar-item">
                                                                    
                                                                </Grid> */}
                                                                <Grid item xl={10} lg={9} md={12} sm={8} xs={9} className="ap-avtar-item" marginTop={2}>
                                                                <label htmlFor="ap-upload-profile">
                                                                <Tooltip title="Upload Image" arrow>
                                                                    <Avatar className='rp-avtar' src={userDetails.userprofileimageurl} sx={{ height: "160px", width: "160px", margin: "0 auto",  pointer:"hover"}} />
                                                                </Tooltip>
                                                                <TextField
                                                                        style={{display:'none'}}
                                                                        required
                                                                        fullWidth
                                                                        id='ap-upload-profile'
                                                                        name='upload thumbnail'
                                                                        type="file"
                                                                        accept="image/*"
                                                                        size='small'
                                                                        inputProps={{ accept: 'image/*' }}
                                                                        onChange={(e) => {
                                                                            // debugger
                                                                            console.log("files:::::::::::::::::::::::::::::::::::::::::::::::::::::::::",e.target.files);
                                                                            let formData = new FormData();
                                                                            formData.append("profile_flag",true);
                                                                            formData.append("file",e.target.files[0],e.target.files[0].name);
                                                                            setLinearShow(true)
                                                                            console.log("form data:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::",formData);
                                                                            uploadFilesAPI(formData, (res) => {
                                                                                console.log("res::::::::::::::::::::::::::::::::::::::::::::::::::::::::::", res)
                                                                                // var fileId = res.data.data.thumbnail_file_id
                                                                                if (res.data.status) {
                                                                                    var data= {
                                                                                        imageflag:true,
                                                                                        thumbnail_file_id:res.data.data.thumbnail_file_id,
                                                                                        userprofileimageid:res.data.data.user_profile_image_id
                                                                                    }
                                                                                    getUploadResourceData(data,(res1) => {
                                                                                        console.log("resource data response:::::",res1);
                                                                                        setUserDetails({...userDetails, userprofileimageid:res.data.data.thumbnail_file_id, userprofileimageurl:res1.data.data.file_url})
                                                                                        setLinearShow(false)
                                                                                    },(err)=>{
                                                                                        console.log(err);
                                                                                        setLinearShow(false)
                                                                                        setShowMessage({
                                                                                            'status': false,
                                                                                            'error': true,
                                                                                            'message': "File not uploading something Went wrong"
                                                                                        })    
                                                                                    })
                                                                                } 
                                                                                else {
                                                                                    setLinearShow(false)
                                                                                    setShowMessage({
                                                                                        'status': false,
                                                                                        'error': true,
                                                                                        'message': "Something Went wrong!"
                                                                                    })
                                                                                }
                                                                            }, 
                                                                            (err) => {
                                                                                setLinearShow(false)
                                                                                console.log(err);
                                                                                setShowMessage({
                                                                                    'status': false,
                                                                                    'error': true,
                                                                                    'message': "Something Went wrong!"
                                                                                })
                                                                            })
                                                                        }}
                                                                    />
                                                                    </label>
                                                                    {/* <TextField
                                                                        required
                                                                        fullWidth
                                                                        name='upload thumbnail'
                                                                        type='file'
                                                                        size='small'
                                                                        inputProps={{ accept: 'image/*' }}
                                                                        onChange={(e) => {
                                                                            let formData = new FormData(); 
                                                                            formData.append("file",e.target.files[0],e.target.files[0].name,);
                                                                            setLinearShow(true)
                                                                            uploadFilesAPI(formData, (res) => {
                                                                                console.log(res)
                                                                                var fileId = res.data.data.thumbnail_file_id
                                                                                if (res.data.status) {
                                                                                    var data= {
                                                                                        imageflag:true,
                                                                                        thumbnail_file_id:fileId,
                                                                                        userprofileimageid:res.data.data.user_profile_image_id
                                                                                    }
                                                                                    getUploadResourceData(data,(res1) => {
                                                                                        console.log("resource data response:::::",res1);
                                                                                        setUserDetails({...userDetails,userprofileid:fileId,userprofileimageurl:res1.data.data.file_url})
                                                                                        setLinearShow(false)
                                                                                    },(err)=>{
                                                                                        console.log(err);
                                                                                        setLinearShow(false)
                                                                                        setShowMessage({
                                                                                            'status': false,
                                                                                            'error': true,
                                                                                            'message': "File not uploading something Went wrong"
                                                                                        })    
                                                                                    })
                                                                                } 
                                                                                else {
                                                                                    setLinearShow(false)
                                                                                    setShowMessage({
                                                                                        'status': false,
                                                                                        'error': true,
                                                                                        'message': "Something Went wrong!"
                                                                                    })
                                                                                }
                                                                            }, 
                                                                            (err) => {
                                                                                setLinearShow(false)
                                                                                console.log(err);
                                                                                setShowMessage({
                                                                                    'status': false,
                                                                                    'error': true,
                                                                                    'message': "Something Went wrong!"
                                                                                })
                                                                            })
                                                                        }}
                                                                    /> */}
                                                                </Grid>
                                                                {linearShow &&
                                                                    <Grid item xl={12} sm={12} xs={12} marginTop={2}>
                                                                        <LinearProgress />
                                                                    </Grid>
                                                                }
                                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} marginTop={4.2}>
                                                                    <Grid container>
                                                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="ap-name-item" >
                                                                            <span className='ap-name-label'>{userDetails.user_name}</span>
                                                                        </Grid>
                                                                        <Grid item xl={12} lg={12}  md={12} sm={12} xs={12} className="ap-email-item" >
                                                                            <span className='ap-email-label'>{userDetails.user_email}</span>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {/* Profile form */}
                                                        <Grid item xl={6} lg={7} md={7} sm={10} xs={11} className=''>
                                                            
                                                            <Grid container justifyContent="center">
                                                                <Grid xl={10} sm={10} xs={10}>
                                                                    <Grid container >
                                                                   
                                                                        <Grid item xl={12} xs={12} className="ap-form-item2">
                                                                        {showMessage.error && <Alert severity="error">{showMessage.message}</Alert>}
                                                                        {showMessage.status && <Alert severity="success">{showMessage.message}</Alert>}
                                                                            <Grid container >
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <span className="ap-form-labels">First Name <span id="reg-astrisk-sign">*</span> </span>
                                                                                </Grid>
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        required
                                                                                        type="text"
                                                                                        id="outlined-size-small"
                                                                                        size="small"
                                                                                        placeholder="Enter First Name"
                                                                                        onChange={(e) => {
                                                                                            setUserDetails({ ...userDetails, firstname: e.target.value })
                                                                                        }}
                                                                                        value={userDetails.firstname}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xl={12} xs={12} className="ap-form-item2">
                                                                            <Grid container >
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <span className="ap-form-labels">Last Name</span>
                                                                                </Grid>
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        required
                                                                                        type="text"
                                                                                        id="outlined-size-small"
                                                                                        size="small"
                                                                                        placeholder="Enter Last Name"
                                                                                        onChange={(e) => {
                                                                                            setUserDetails({ ...userDetails, lastname: e.target.value })
                                                                                        }}
                                                                                        value={userDetails.lastname}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xl={12} xs={12} className="ap-form-item2">
                                                                            <Grid container >
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <span className="ap-form-labels">Contact <span id="reg-astrisk-sign">*</span></span>
                                                                                </Grid>
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        required
                                                                                        type="text"
                                                                                        id="outlined-size-small"
                                                                                        size="small"
                                                                                        placeholder="Enter Contact Number"
                                                                                        onChange={(e) => {
                                                                                            setUserDetails({ ...userDetails, contact: e.target.value })
                                                                                        }}
                                                                                        value={userDetails.contact}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        {/* <Grid item xl={12} xs={12} className="ap-form-item2">
                                                                            <Grid container >
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <span className="ap-form-labels">Address:</span>
                                                                                </Grid>
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <TextField
                                                                                        id="outlined-multiline-static"
                                                                                        autoComplete="off"
                                                                                        multiline
                                                                                        rows={2}
                                                                                        fullWidth
                                                                                        placeholder='Enter Address'
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xl={12} xs={12} className="ap-form-item2">
                                                                            <Grid container >
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <span className="ap-form-labels">State:</span>
                                                                                </Grid>
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        required
                                                                                        type="text"
                                                                                        id="outlined-size-small"
                                                                                        size="small"
                                                                                        placeholder="Enter State"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xl={12} xs={12} className="ap-form-item2">
                                                                            <Grid container >
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <span className="ap-form-labels">City:</span>
                                                                                </Grid>
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        required
                                                                                        type="text"
                                                                                        id="outlined-size-small"
                                                                                        size="small"
                                                                                        placeholder="Enter City"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xl={12} xs={12} className="ap-form-item2">
                                                                            <Grid container >
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <span className="ap-form-labels">ZIp Code:</span>
                                                                                </Grid>
                                                                                <Grid item xl={12} xs={12}>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        required
                                                                                        type="text"
                                                                                        id="outlined-size-small"
                                                                                        size="small"
                                                                                        placeholder="Enter Zip Code"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>  */}



                                                                        <Grid item xl={6} lg={7} md={7} sm={12} xs={12} className="ap-form-save-btn">
                                                                            <Button variant='contained' disabled={(linearShow) ? true : false} onClick={updateprofilefunc} fullWidth>Update Profile</Button>
                                                                        </Grid>

                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default AccountProfile;