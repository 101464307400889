import { Avatar, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import Header from "../../GlobalComponent/Header/Header";
import './UPFee.css';
import { useNavigate } from "react-router-dom";
import ShareIcon from '@mui/icons-material/Share';
import CreateIcon from '@mui/icons-material/Create';
import OutlinedAction from "../OutputAction/OutputAction";
import OutputAction from "../OutputAction/OutputAction";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const UPFee = (props) => {

    let finalData = props.data;
    var taxTypicalAge = []
    var taxIul_planAge = []
    if (finalData?.typical_plan?.FEES_age_data && finalData?.iul_plan?.FEES_age_data) {
        taxTypicalAge = Object.keys(finalData?.typical_plan?.FEES_age_data)
        taxIul_planAge = Object.keys(finalData?.iul_plan?.FEES_age_data)
        var feeTpAgeMin = taxTypicalAge[0]
        var feeTpAgeMax = taxTypicalAge[taxTypicalAge.length - 1]

        var feeIULAgeMin = taxIul_planAge[0]
        var feeIULAgeMax = taxIul_planAge[taxIul_planAge.length - 1]
    }

    const [openInput, setopenInput] = useState(false);
    const [changeYear, setChangeYear] = useState(finalData.current_age)
    const [changeYearIUL, setChangeYearIUL] = useState(finalData.current_age)
    const [calculationShowError, setCalculationShowError] = useState(false)
    const [calculationShowErrorIUL, setCalculationShowErrorIUL] = useState(false)
    const formatNumber = (q) => {
        if (q || q == 0) {
            return q.toLocaleString('en-US', {
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                currency: "usd"
            })
        }
    }

    const InputChangeYearIUL = (e) => {
        let val = parseInt(e.target.value);
        if (val >= Number(taxIul_planAge[0]) && val <= Number(taxIul_planAge[taxIul_planAge.length - 1])) {
            setChangeYearIUL(val)
        }
        else {

            if (val >= Number(taxIul_planAge[taxIul_planAge.length - 1]))
                setChangeYearIUL(Number(taxIul_planAge[taxIul_planAge.length - 1]))
            else {
                setChangeYearIUL(Number(taxIul_planAge[0]))
            }
            return;
        }

        // else {
        //     // setChangeYear(val)
        //     return
        // }

        // if (val == Number(taxIul_planAge[taxIul_planAge.length - 1]) || (changeYear == Number(taxIul_planAge[taxIul_planAge.length - 1]) && val == changeYear)) {
        //     setCalculationShowError(true)
        // } else {
        //     setCalculationShowError(false)
        // }
    }

    const isBetween = (num1, num2, value) => value > num1 && value < num2;
    const InputBlurChangeIUL = (e) => {
        let val = parseInt(e.target.value);
        let checkInBeetween = isInputBetween(Number(taxIul_planAge[0]), Number(taxIul_planAge[taxIul_planAge.length - 1]), val)
        if (checkInBeetween == false) {
            if (val >= Number(taxIul_planAge[taxIul_planAge.length - 1]))
                setChangeYearIUL(Number(taxIul_planAge[taxIul_planAge.length - 1]))
            else {
                setChangeYearIUL(Number(taxIul_planAge[0]))
            }
            // setChangeYear(taxIul_planAge[0])
            return
        }
    }


    useEffect(() => {
        if (changeYear == Number(taxTypicalAge[taxTypicalAge.length - 1])) {
            setCalculationShowError(true)
        } else {
            setCalculationShowError(false)
        }
    }, [changeYear])

    useEffect(() => {
        if (changeYearIUL == Number(taxIul_planAge[taxIul_planAge.length - 1])) {
            setCalculationShowErrorIUL(true)
        } else {
            setCalculationShowErrorIUL(false)
        }
    }, [changeYearIUL])


    const inputChangeYear = (e) => {
        let val = parseInt(e.target.value);
        if (val >= Number(taxTypicalAge[0]) && val <= Number(taxTypicalAge[taxTypicalAge.length - 1])) {
            setChangeYear(val)
        }
        else {

            if (val >= Number(taxTypicalAge[taxTypicalAge.length - 1]))
                setChangeYear(Number(taxTypicalAge[taxTypicalAge.length - 1]))
            else {
                setChangeYear(Number(taxTypicalAge[0]))
            }
            return;
        }

        // else {
        //     // setChangeYear(val)
        //     return
        // }

        // if (val == Number(taxTypicalAge[taxTypicalAge.length - 1]) || (changeYear == Number(taxTypicalAge[taxTypicalAge.length - 1]) && val == changeYear)) {
        //     setCalculationShowError(true)
        // } else {
        //     setCalculationShowError(false)
        // }
    }
    const isInputBetween = (num1, num2, value) => value > num1 && value < num2;
    const inputBlureChangeYear = (e) => {
        let val = parseInt(e.target.value);
        let checkInBeetween = isInputBetween(Number(taxTypicalAge[0]), Number(taxTypicalAge[taxTypicalAge.length - 1]), val)
        if (checkInBeetween == false) {
            if (val >= Number(taxTypicalAge[taxTypicalAge.length - 1]))
                setChangeYear(Number(taxTypicalAge[taxTypicalAge.length - 1]))
            else {
                setChangeYear(Number(taxTypicalAge[0]))
            }
            // setChangeYear(taxIul_planAge[0])
            return
        }
    }


    return (

        <>
            {window.screen.width > 900 &&
                <Grid item xs={12}>
                    <Grid container className="fee-white-container2" >
                        {/* Fees */}
                        <Grid item xs={1} className="fees-left-blue-item" >
                            <Grid container className="fees-left-blue-container" >
                                <Typography id="fees-left-blue-text">Fees</Typography>
                            </Grid>
                        </Grid>

                        {/* Typical plan & IUL */}
                        <Grid item xs={10.5} >
                            <Grid container className="tp-iul-container">
                                {/* Typical plan */}
                                <Grid item xl={3.5} lg={4.5} md={5} xs={4.5} className="typical-plan-item">
                                    <Grid container className={(calculationShowError) ? "typical-plan-container redBorder" : "typical-plan-container"} >

                                        <Grid item xs={10}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-head-container">
                                                        <Grid item>
                                                            <Typography id="tp-container-heading">Typical Plan</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Current Age</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">{finalData?.current_age ? finalData?.current_age : "0"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Age at Retirement</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Annual Contribution</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.annualContribution) ? formatNumber(finalData?.typical_plan?.annualContribution) : "0"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Current Account Balance</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.currAccountBalance) ? formatNumber(finalData?.typical_plan?.currAccountBalance) : "0"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-fee-hr-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Rate of Return</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">
                                                                        {(finalData?.typical_plan?.rateOfReturnWorking) ? (finalData?.typical_plan?.rateOfReturnWorking) : "0"}%
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Fees</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container">
                                                                <Grid item>
                                                                    <Typography id="tp-value">{(finalData?.typical_plan?.percentFees) ? (finalData?.typical_plan?.percentFees) : "0"}%</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-fees-head-container">
                                                        <Typography id="tp-fees-container-heading">Fees - No Life Insurance</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} >
                                                    <Grid container className="tp-sub-fees-container" >
                                                        <Grid item xs={12} className="tp-sub-fees-item">
                                                            <Grid container className="tp-sub-fees-container1" >

                                                                <Grid item xs={8}>
                                                                    <Typography id="tp-fees-field-label">Annual Fees</Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Grid container className="tp-sub-value-container-white-box">
                                                                        <Grid item>
                                                                            <Typography id="tp-fees-field-value">
                                                                                {formatNumber(finalData?.typical_plan?.FEES_age_data[changeYear]?.annual_fees) ? formatNumber(finalData?.typical_plan?.FEES_age_data[changeYear]?.annual_fees) : "$0"}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sx={{ marginBottom: '-5px' }}>
                                                    <Grid container className="tp-sub-fees-container" >
                                                        <Grid item xs={12} className="tp-sub-fees-item">
                                                            <Grid container className="tp-sub-fees-container1" >
                                                                <Grid item xs={8}>
                                                                    <Typography id="tp-fees-field-label">Total Fees Paid</Typography>
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    <Grid container className="tp-sub-value-container-white-box">
                                                                        <Grid item>
                                                                            <Typography id="tp-fees-field-value">{formatNumber(finalData?.typical_plan?.FEES_age_data[changeYear]?.total_fees_paid) ? formatNumber(finalData?.typical_plan?.FEES_age_data[changeYear]?.total_fees_paid) : "$0"}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {calculationShowError &&
                                                    <Grid item xs={12}>
                                                        <Grid container className="all-module-fees-red-label">
                                                            <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>TYPICAL PLAN ACCOUNT IS EMPTY</span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* IUL */}
                                <Grid item xl={3.5} lg={4.5} md={5} xs={4.5} className="fee-iul-item">
                                    <Grid container className="fee-iul-container">
                                        <Grid item xs={10}>
                                            <Grid container>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-head-container">
                                                        <Grid item>
                                                            <Typography id="tp-container-heading">IUL</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Current Age</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">{finalData?.current_age ? finalData?.current_age : "0"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Age at Retirement</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Annual Premium</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">{formatNumber(finalData?.iul_plan?.annual_premium) ? formatNumber(finalData?.iul_plan?.annual_premium) : "0"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Lump Sum Premium</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">{formatNumber(finalData?.iul_plan?.lumpSumPremium) ? formatNumber(finalData?.iul_plan?.lumpSumPremium) : "0"}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-fee-hr-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Rate of Return</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">{(finalData?.iul_plan?.rateOfReturn) ? (finalData?.iul_plan?.rateOfReturn) : "0"}%</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <Typography id="tp-label">Fees</Typography>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="tp-sub-value-container" >
                                                                <Grid item>
                                                                    <Typography id="tp-value">Included</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>



                                                {/* fees-include life insurance */}
                                                <Grid item xs={12} >
                                                    <Grid container className="iul-sub-fields-container">
                                                        <Grid item xs={12}>
                                                            <Grid container className="iul-fees-head-container">
                                                                <Typography id="iul-fees-container-heading">Fees - Includes Life Insurance</Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="iul-sub-fees-container" >
                                                                <Grid item xs={12} className="iul-sub-fees-item">
                                                                    <Grid container className="iul-sub-fees-container1" >
                                                                        <Grid item xs={8}>
                                                                            <Typography id="iul-fees-field-label">Annual Fees</Typography>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="tp-sub-value-container-white-box" >
                                                                                <Grid item>
                                                                                    <Typography id="iul-fees-field-value">{formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.annual_fees) ? formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.annual_fees) : "$0"}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="iul-sub-fees-container" >
                                                                <Grid item xs={12} className="iul-sub-fees-item">
                                                                    <Grid container className="iul-sub-fees-container1" >
                                                                        <Grid item xs={8}>
                                                                            <Typography id="iul-fees-field-label">Total Fees Paid</Typography>
                                                                        </Grid>

                                                                        <Grid item xs={4} >
                                                                            <Grid container className="tp-sub-value-container-white-box">
                                                                                <Grid item>
                                                                                    <Typography id="iul-fees-field-value">{formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.total_fees_paid) ? formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.total_fees_paid) : "$0"}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="iul-sub-fees-container" >
                                                                <Grid item xs={12} className="iul-sub-fees-item">
                                                                    <Grid container className="iul-sub-fees-container1" >
                                                                        <Grid item xs={8}>
                                                                            <Typography id="iul-fees-field-label">Total Death Benefits</Typography>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="tp-sub-value-container-white-box">
                                                                                <Grid item>
                                                                                    <Typography id="iul-fees-field-value">{formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.total_death_benefit) ? formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.total_death_benefit) : "$0"}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {calculationShowErrorIUL &&
                                                                    <></>
                                                                    // <Grid item xs={12}>
                                                                    //     <Grid container className="taxes-tp-life-container-last" marginTop={1}>
                                                                    //         <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                    //             <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>IUL PLAN ACCOUNT IS EMPTY</span>
                                                                    //         </Grid>
                                                                    //     </Grid>
                                                                    // </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* Age */}
                                <Grid item xs={12} className="fee-age-sub-item">
                                    <Grid container className="fee-age-main-container" justifyContent="center">
                                        <Grid item xl={3.5} lg={4.5} md={5} xs={4.5} sx={{ marginRight: '30px' }}>
                                            <Grid container className="fee-age-container" >
                                                <Grid item xs={6} className="fee-age-item">
                                                    <Typography id="fee-age-label">Age: &nbsp;</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container className="taxes-tp-iul-age-scroll-btn">
                                                        <TextField
                                                            id="outlined-basic"
                                                            className="fee-digit-field"
                                                            type="number"
                                                            InputProps={{ inputProps: { min: feeTpAgeMin, max: feeTpAgeMax } }}
                                                            onChange={(e) => {
                                                                setChangeYear(e.target.value);
                                                            }}
                                                            onBlur={inputBlureChangeYear}
                                                            onKeyUp={(e) => {
                                                                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                    inputChangeYear(e);
                                                            }}
                                                            value={changeYear}

                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xl={3.5} lg={4.5} md={5} xs={4.5}>
                                            <Grid container className="fee-age-container1">
                                                <Grid item xs={6} className="fee-age-item">
                                                    <Typography id="fee-age-label">Age: &nbsp;</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container className="taxes-tp-iul-age-scroll-btn">
                                                        <TextField
                                                            id="outlined-basic"
                                                            className="fee-digit-field"
                                                            type="number"
                                                            InputProps={{ inputProps: { min: feeIULAgeMin, max: feeIULAgeMax } }}
                                                            onChange={(e) => {
                                                                setChangeYearIUL(e.target.value);
                                                            }}
                                                            onBlur={InputBlurChangeIUL}
                                                            onKeyUp={(e) => {
                                                                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                    InputChangeYearIUL(e);
                                                            }}
                                                            value={changeYearIUL}
                                                            size="small"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* share and edit */}
                        <Grid item xs={0.5}>
                            <OutputAction data={props.data} inputData={props.inputData} />
                        </Grid>
                    </Grid>
                </Grid>
            }
            {window.screen.width <= 900 &&
                <>
                    <Grid container className='md-avl-main-container'>
                        <Grid item sm={11} xs={11} className='md-fee-main-item'>
                            <Grid container>

                                <Grid item sm={12} xs={12} className='md-fee-item1' >
                                    <Accordion className="md-fee-tp-main-accordion" defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="md-fee-tp-acordion">Typical Plan</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item sm={12} xs={12}>
                                                        <Grid container>
                                                            <Grid item sm={12} xs={12} className='md-fee-tp-main-item'>
                                                                <Grid container className='md-fee-tp-blue-container'>
                                                                    <Grid item sm={12} xs={11} className='md-fee-tp-sub-item' >
                                                                        <Grid container>
                                                                            <Grid item sm={12} xs={12}>
                                                                                <Grid container className="md-fee-tp-fields">

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container className="md-fee-tp-container">

                                                                                            {/* <Grid item sm={12} xs={12} className="md-ri-tp-head-item">
                                                                                                <span className="md-ri-head-text">Typical Plan</span>
                                                                                            </Grid> */}

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-fee-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-fee-tp-iul-label-text">Current Age</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-fee-tp-iul-value-text">{finalData?.current_age ? finalData?.current_age : "0"}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-fee-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-fee-tp-iul-label-text">Age at Retirement</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-fee-tp-iul-value-text">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-fee-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-fee-tp-iul-label-text">Annual Contribution</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-fee-tp-iul-value-text">{formatNumber(finalData?.typical_plan?.annualContribution) ? formatNumber(finalData?.typical_plan?.annualContribution) : "0"}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-fee-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-fee-tp-iul-label-text">Current Account Balance</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-fee-tp-iul-value-text">{formatNumber(finalData?.typical_plan?.currAccountBalance) ? formatNumber(finalData?.typical_plan?.currAccountBalance) : "0"}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item xs={12}>
                                                                                                        <Divider className="md-avl-devider" />
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-fee-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-fee-tp-iul-label-text">Rate of Return</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-fee-tp-iul-value-text">{(finalData?.typical_plan?.rateOfReturnWorking) ? (finalData?.typical_plan?.rateOfReturnWorking) : "0"}%</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-fee-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-fee-tp-iul-label-text">Fees</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-fee-tp-iul-value-text">{(finalData?.typical_plan?.percentFees) ? (finalData?.typical_plan?.percentFees) : "0"}%</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container>
                                                                                            <Grid item sm={12} xs={12} className="md-fee-tp-head-item2">
                                                                                                <span className="md-fee-head-text2">Fees - No Life Insurance</span>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-fee-av-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-fee-tp-iul-label-text">Annual Fees</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-fee-tp-iul-value-text">{formatNumber(finalData?.typical_plan?.FEES_age_data[changeYear]?.annual_fees) ? formatNumber(finalData?.typical_plan?.FEES_age_data[changeYear]?.annual_fees) : "$0"}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-fee-av-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-fee-tp-iul-label-text">Total Fees Paid</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-fee-tp-iul-value-text">{formatNumber(finalData?.typical_plan?.FEES_age_data[changeYear]?.total_fees_paid) ? formatNumber(finalData?.typical_plan?.FEES_age_data[changeYear]?.total_fees_paid) : "$0"}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            {calculationShowError &&
                                                                                                <Grid item xs={12}>
                                                                                                    <Grid container className="taxes-tp-life-container-last" marginTop={1}>
                                                                                                        <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                                                            <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>TYPICAL PLAN ACCOUNT IS EMPTY</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            }

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sm={12} xs={12} >
                                                        <Grid container className="md-age-container">
                                                            <Grid item sm={9} xs={8} className='md-age-item1'>
                                                                <span className="md-age-label">Age: </span>
                                                            </Grid>
                                                            <Grid item sm={3} xs={4} className='md-age-item2'>
                                                                <Grid container>
                                                                    <Grid item xs={12} className='md-age-num-item'>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            className="fee-digit-field"
                                                                            type="number"
                                                                            InputProps={{ inputProps: { min: feeTpAgeMin, max: feeTpAgeMax } }}
                                                                            onChange={(e) => {
                                                                                setChangeYear(e.target.value);
                                                                            }}
                                                                            onBlur={inputBlureChangeYear}
                                                                            onKeyUp={(e) => {
                                                                                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                                    inputChangeYear(e);
                                                                            }}
                                                                            value={changeYear}
                                                                            size="small"
                                                                            variant="outlined"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                <Grid item sm={12} xs={12} className='md-fee-item2' >
                                    <Accordion className="md-fee-iul-main-accordion" defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="md-fee-iul-acordion">IUL</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item sm={12} xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} className='md-fee-iul-main-item'>
                                                        <Grid container className='md-fee-tp-blue-container'>
                                                            <Grid item xs={11} className='md-fee-iul-sub-item' >
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Grid container className="md-fee-iul-fields">
                                                                            <Grid item xs={12}>
                                                                                <Grid container className="md-fee-iul-container">
                                                                                    <Grid item xs={12}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-fee-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-fee-tp-iul-label-text">Current Age</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-fee-tp-iul-value-text">{finalData?.current_age ? finalData?.current_age : "0"}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-fee-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-fee-tp-iul-label-text">Age at Retirement</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-fee-tp-iul-value-text">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-fee-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-fee-tp-iul-label-text">Annual Premium</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-fee-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.iul_plan?.annual_premium) ? formatNumber(finalData?.iul_plan?.annual_premium) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-fee-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-fee-tp-iul-label-text">Lump Sum Premium</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-fee-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.iul_plan?.lumpSumPremium) ? formatNumber(finalData?.iul_plan?.lumpSumPremium) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Divider className="md-avl-devider" />
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-fee-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-fee-tp-iul-label-text">Rate of Return</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-fee-tp-iul-value-text">
                                                                                                            {(finalData?.iul_plan?.rateOfReturn) ? (finalData?.iul_plan?.rateOfReturn) : "0"}%</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-fee-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-fee-tp-iul-label-text">Fees</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-fee-tp-iul-value-text">Included</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <Grid container>
                                                                                    <Grid item xs={12} className="md-fee-tp-head-item2">
                                                                                        <span className="md-fee-head-text2">Fees - Includes Life Insurance</span>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-fee-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-fee-tp-iul-label-text">Annual Fees</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-fee-tp-iul-value-text">
                                                                                                    {formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.annual_fees) ? formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.annual_fees) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-fee-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-fee-tp-iul-label-text">Total Fees Paid</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-fee-tp-iul-value-text">{formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.total_fees_paid) ? formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.total_fees_paid) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-fee-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-fee-tp-iul-label-text">Total Death Benefits</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-fee-tp-iul-value-text">{formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.total_death_benefit) ? formatNumber(finalData?.iul_plan?.FEES_age_data[changeYearIUL]?.total_death_benefit) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {calculationShowErrorIUL &&
                                                                                        <></>
                                                                                        // <Grid item xs={12}>
                                                                                        //     <Grid container className="taxes-tp-life-container-last" marginTop={1}>
                                                                                        //         <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                                        //             <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>IUL PLAN ACCOUNT IS EMPTY</span>
                                                                                        //         </Grid>
                                                                                        //     </Grid>
                                                                                        // </Grid>
                                                                                    }
                                                                                </Grid>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item sm={12} xs={12} >
                                                        <Grid container className="md-age-container">
                                                            <Grid item sm={9} xs={8} className='md-age-item1'>
                                                                <span className="md-age-label">Age: </span>
                                                            </Grid>
                                                            <Grid item sm={3} xs={4} className='md-age-item2'>
                                                                <Grid container>
                                                                    <Grid item xs={12} className='md-age-num-item'>
                                                                        <TextField
                                                                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                                                            id="outlined-basic"
                                                                            className="fee-digit-field"
                                                                            type="number"
                                                                            InputProps={{ inputProps: { min: feeIULAgeMin, max: feeIULAgeMax } }}
                                                                            onChange={(e) => {
                                                                                setChangeYearIUL(e.target.value);
                                                                            }}
                                                                            onBlur={InputBlurChangeIUL}
                                                                            onKeyUp={(e) => {
                                                                                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                                    InputChangeYearIUL(e);
                                                                            }}
                                                                            value={changeYearIUL}
                                                                            size="small"
                                                                            variant="outlined"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </>
            }

        </>
    );
};

export default UPFee;