import { Alert, Button, Dialog, DialogContent, Grid, Snackbar } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import Header from '../../GlobalComponent/Header/Header';
import './TrainingPage.css';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TraininPageDocPopup from '../../GlobalComponent/TraininPageDocPopup/TraininPageDocPopup'
import { useState } from 'react';
import { delteResourceDataAPI, getResourceDataAPI, getCompanyresourceData, delteResourceAPI } from "../../service/login.service"
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { Context as mainContext } from '../../Context/auth_context'
import { CheckCircleRounded, ErrorRounded } from '@mui/icons-material';
import Footer from '../../GlobalComponent/Footer/Footer';





function TrainingPage(props) {

    const { UpdateLoader, state } = useContext(mainContext)
    const [addNewDoc, setaddNewDoc] = useState(false);
    const [resorcesData, setResorcesData] = useState([{}]);
    const [datareloadCall, setDatareloadCall] = useState(false);
    const [selectedResorceData, setSelectedResorceData] = useState({});
    const [newPopup, setNewPopup] = useState(false);
    const [flagDeleteDocPopup, setFlagDeleteDocPopup] = useState(false);
    const [flagADocDeleted, setFlagDocDeleted] = useState(false);
    const [selectedDoc, setSelectedDoc] = React.useState({
        resource_title: "",
        resource_description: "",
        resource_document_type_id: 0,
        resource_thumbnail: "",
        resource: "",
        resource_id: "",
    })
    const [message, setMessage] = React.useState({
        status: false,
        message: "",
        error: "",
    });
    const [editState, setEditState] = useState(false)
    // const [docToDeleteInfo, setDoctToDeleteInfo] = useState({});



    const handleOpenDoc = () => {
        setSelectedResorceData({})
        setaddNewDoc(true);
    }

    const handleCloseDoc = () => {
        setSelectedResorceData({})
        setaddNewDoc(false);
    }

    const datareload = () => {
        setDatareloadCall(!datareloadCall)
    }

    useEffect(() => {
        UpdateLoader(true)
        getCompanyresourceData((res) => {
            if (res.data.status) {
                setResorcesData(res.data.data)
            }
            else {
                setMessage({
                    status: true,
                    message: "Something Went Wrong!",
                    error: true,
                })
                UpdateLoader(false)
            }
            UpdateLoader(false)
        }, (err) => {
            UpdateLoader(false)
            setMessage({
                status: true,
                message: "Something Went Wrong!",
                error: true,
            })
        })
    }, [datareloadCall])

    const newPopupOpen = () => {
        setNewPopup(true)
    }

    const popupclose = () => {
        setNewPopup(false)
    }

    const deleteDocument = (props) => {
        var obj = {
            resource_id: selectedResorceData.resource_id
        }
        delteResourceAPI(obj, (res) => {
            setMessage({
                status: true,
                message: "Resource Deleted Successfully!",
                error: false,
            })
            setFlagDeleteDocPopup(false)
            UpdateLoader(false)
            getCompanyresourceData((res) => {

                if (res.data.status) {
                    setResorcesData(res.data.data)
                } else {
                    setMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                    UpdateLoader(false)


                }
                UpdateLoader(false)
                // props.handlePropClose()
            },
                (err) => {
                    setMessage({
                        status: true,
                        message: "Something Went Wrong!",
                        error: true,
                    })
                    UpdateLoader(false)
                })
        })
    }

    const handleClose1 = () => {
        setMessage({
            status: false,
            message: "",
            error: "",
        })
    };


    return (
        <>
            <Header />
            {message.status &&
                <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
                    <Alert onClose={handleClose1} severity={message.error == true ? "error" : "success"} sx={{ width: '100%' }}>
                        {message.message}
                    </Alert>
                </Snackbar>
            }
            {flagDeleteDocPopup && <Dialog
                open={true}
                // onClose={() => { setFlagDocDeleted(false) }}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title">
                <DialogContent>
                    <Grid item xs={12}>
                        <Grid container className="pr-assign-profile-popup">
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container className="pr-assign-profile-popup-title-container">
                                            {flagADocDeleted ?
                                                (
                                                    <>
                                                        <Typography id="pr-assign-profile-popup-title">Successfully Deleted!</Typography>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography id="pr-assign-profile-popup-title">Delete {selectedResorceData.resource_title}  Document</Typography>
                                                    </>
                                                )
                                            }

                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container className="pr-assign-profile-popup-close-btn-container">
                                            <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={() => { setFlagDeleteDocPopup(false); setFlagDocDeleted(false) }} /></Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={7} >
                                <Grid container className="pr-assign-agent-dropdown-container">

                                    <Grid item xs={12}>
                                        <Grid container className="pr-assign-agent-dropdown-label" textAlign={"center"}>
                                            {flagADocDeleted ?
                                                (
                                                    <>
                                                        <CheckCircleRounded sx={{ color: "green", width: "60px", height: "60px", margin: "0px auto 15px" }} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ErrorRounded sx={{ color: "red", width: "60px", height: "60px", margin: "5px auto" }} />
                                                    </>
                                                )
                                            }
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container className="pr-assign-agent-dropdown-label">
                                            {flagADocDeleted ?
                                                (
                                                    <>
                                                        <Typography id="pr-assign-agent-dropdown-label">Document Deleted Successfully!</Typography>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography id="pr-assign-agent-dropdown-label" fontSize="14px !important">Are you sure you want delete this Document?</Typography>
                                                    </>
                                                )
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={7} >
                                <Grid container className="pr-save-cancel-popup-container-agent" justifyContent="center" marginTop='10px'>
                                    {flagADocDeleted ?
                                        (
                                            <>
                                                <Grid item xs={5.6}>
                                                    <Grid container className="avl-email-popup-btn" sx={{ display: "flex", justifyContent: "center", margin: "0 auto", width: "100%" }}>
                                                        <Button variant="outlined" sx={{ margin: "0 auto", justifyContent: "center" }} id="avl-email-save-popup-btn" onClick={() => { setFlagDeleteDocPopup(false); setFlagDocDeleted(false) }}>Close</Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={12}>
                                                    <Grid container justifyContent="space-between">

                                                        <Grid item xs={5.6} >
                                                            <Grid container className="avl-email-popup-btn">
                                                                <Button variant="outlined" id="avl-email-save-popup-btn" onClick={() => { setFlagDeleteDocPopup(false) }}>No</Button>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={5.6}>
                                                            <Grid container className="pr-save-popup-btn">
                                                                <Button variant="contained" id="pr-save-popup-btn" onClick={deleteDocument}>Yes</Button>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            }


            <Grid container >
                <Grid item xs={12}>

                    <Grid container className='training-page-main-container'>

                        <Grid item xs={12} >
                            <Grid container>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={10} className='training-page-item'>
                                    <Grid container justifyContent="center">
                                        <Grid item xl={2} lg={2} md={3} sm={10} xs={10} className='training-page-heading-item'>
                                            <span className="rb-global-heading-class">Training</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {state.user_detail.role_id == 1 &&

                                    <Grid item xl={10} lg={10} md={10} sm={10} xs={10} className="training-page-item">
                                        <Grid container className="training-page-comntainer">
                                            <Grid item xl={1} lg={2} md={2} sm={3} xs={12} className="training-page-btn-item">
                                                <Button variant='contained' id="training-page-btn" onClick={handleOpenDoc} fullWidth><AddIcon />&nbsp;Document</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                }

                            </Grid>
                        </Grid>

                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10} className='training-page-sub-item'>
                            <Grid container className='training-page-sub-container' sx={{ direction: "row" }}>

                                <Grid item lg={12} xs={12}  >
                                    <Grid container className='training-page-card-container'>
                                        {resorcesData.length == 0 &&
                                            <Grid item xs={12} textAlign="center" className='training-page-cmg-soon-item'>
                                                <p id="training-page-no-found-label">Coming Soon!</p>
                                            </Grid>
                                        }

                                        {resorcesData.length > 0 && resorcesData.map((rr) => {
                                            return (
                                                <>
                                                    <Grid item xl={2.5} lg={3.5} md={3} sm={5} xs={10} className="training-page-cards">
                                                        <Card>
                                                            <CardMedia
                                                                component="img"
                                                                height="140"
                                                                className='tp-doc-card-media'
                                                                image={rr.resource_file_data && rr?.resource_file_data[1]?.thumbnail_url}
                                                                onClick={() => {
                                                                    setSelectedResorceData(rr)
                                                                    newPopupOpen()
                                                                }}
                                                            />
                                                            <CardContent>
                                                                <Typography gutterBottom variant="h5" component="div" className='training-page-card-title'>
                                                                    {rr.resource_title}
                                                                </Typography>
                                                                <Typography variant="body2" color="text.secondary" className='training-page-card-peragraph'>
                                                                    {rr.resource_description}
                                                                </Typography>
                                                            </CardContent>
                                                            {state.user_detail.role_id == 1 &&
                                                                <CardActions >
                                                                    <EditIcon
                                                                        className='training-page-edit-icon'
                                                                        onClick={() => {

                                                                            setSelectedResorceData(rr)
                                                                            setaddNewDoc(true);
                                                                            // handleOpenDoc()
                                                                            // updateDoc()

                                                                        }}
                                                                    />
                                                                    <DeleteForeverIcon
                                                                        className='training-page-dlt-icon'
                                                                        onClick={() => {
                                                                            setSelectedResorceData(rr)
                                                                            setFlagDeleteDocPopup(true)
                                                                            // deleteDocument()
                                                                        }}

                                                                    />
                                                                </CardActions>
                                                            }
                                                        </Card>
                                                    </Grid>
                                                </>
                                            )
                                        })}

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
            {newPopup == true &&
                <>
                    <Dialog
                        open={() => { setNewPopup(false) }}
                        onClose={popupclose}
                        aria-labelledby="responsive-dialog-title"
                        fullWidth
                        maxWidth="lg"
                    >
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={10} className="tp-doc-iframe-main-heading">
                                                    <span >{selectedResorceData?.resource_title}</span>
                                                </Grid>
                                                <Grid item xs={2} className="tp-doc-iframe-close-item">
                                                    <Box id="tp-doc-iframe-close-btn"><CloseIcon onClick={() => { setNewPopup(false) }} /></Box>
                                                </Grid>
                                                <Grid item xs={12} className="tp-doc-iframe-item">
                                                    <iframe id="tabula" class="tabula_class" src={selectedResorceData?.resource_file_data[0]?.file_url} height={700} width="100%" frameborder="0" allowtransparency="true" style={{ width: "100%" }}></iframe>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </DialogContent>
                    </Dialog>

                </>
            }
            <TraininPageDocPopup
                addNewDoc={addNewDoc}
                selectedResorceData={selectedResorceData}
                handleCloseDoc={handleCloseDoc}
                datareload={datareload}
                edit={true}
                editState={editState} />
        </>
    )
}

export default TrainingPage;
