import { Button, Grid } from "@mui/material";
import React from "react";
import './Email.css';
import logo from '../../assets/Images/logo.png';
import { Box } from "@mui/system";

const Email = () => {
    return(
        <>
        <Grid container>
            <Grid item xs={12}>
                <Grid container justifyContent="center" textAlign="center">

                    <Grid item xs={12} sx={{ marginTop:2 }}>
                        <img src={logo} alt="" />
                    </Grid>

                    <Grid item xs={6} className="email-main-item" sx={{ marginTop:5 }} padding={2}>
                        <Grid container>
                            <Grid item xs={12} padding={2}>
                                <Box id="email-main-heading" >Welcome to the RetirementBrain</Box>
                            </Grid>

                            <Grid item xs={10} className="email-white-item" padding={2}>
                                <Grid container>
                                    <Grid item xs={9} className="email-cred-text" padding={2} >
                                        <Box>Admin has opened your account in RetirementBrain. Here are your login credentials.</Box>
                                    </Grid>

                                    <Grid item xs={12} padding={1} >
                                    <Grid container>
                                        <Grid item xs={10} className="email-pwd" direction="row">
                                            <Grid container>
                                                <Grid item xs={5}>
                                                    <Box id="email-acc-field">Email: johnk@gmail.com</Box>
                                                </Grid> 
                                                <Grid item xs={1} justifyContent="space-evenly">
                                                    <Box id="dash">|</Box>
                                                </Grid>  
                                                <Grid item xs={5}>
                                                    <Box id="email-acc-password">Password: 15RHFgd41f1</Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </Grid> 
                                    
                                    <Grid item xs={12} padding={4}>
                                         <Button id="email-click-btn" variant="contained">Click here to Login</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={11} textAlign="start" className="about-section"  >
                                <Grid container >
                                    <Grid item xs={11} padding={2} className="about-section-title" >
                                        <Box id="abt-retirement-title">About RetirementBrain</Box>
                                    </Grid>
                                    <Grid item xs={11} className="about-section-text">
                                        <Box id="abt-retirement-text">
                                            The unique program of the <span id="abt-text-bold">Retirement BRAIN</span> will explore how alternative solutions, such as Indexed Universal Life Insurance, can supplement your retirement portfolio by providing protections from market downturns, income that lasts a lifetime, valuable taxbenefits, and relief from excessive fees.
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                
                </Grid>
            </Grid>
        </Grid>
        </>
    );
}

export default Email;