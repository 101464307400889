import createDataContext from "./create_data_context";
import { LoginAPI, UserMeAPI, LogoutUserAPI } from '../service/login.service';
import { useHistory } from 'react-router-dom'


const auth_context_Reducer = (state, action) => {
  switch (action.type) {
    case "setUserDetail":
      return { ...state, user_detail: action.payload };
    case "loaderUpdate":
      return { ...state, loading: action.payload };
    case "calculationHitory":
      return { ...state, calculationHistory: action.payload };
    case "setErrorMessage":
      return { ...state, error: action.payload };
    case "setLoader":
      return { ...state, loading: action.payload };
    case "UpdateCompanyDetils":
      return { ...state, company: action.payload };
    case "setPreviousURL" : 
      return {...state, previousUrl: action.payload}
  }
};

const setPreviousUrl = (dispatch) => async (data) =>{
  dispatch({
    type: "setPreviousURL",
    payload: data
  })
}

const UpdateLoader = (dispatch) => async (data) => {
  dispatch({
    type: "setLoader",
    payload: data
  })
}

const updateErrorMessage = (dispatch) => async (data) => {
  dispatch({
    type: "setErrorMessage",
    payload: {
      status: data.status,
      message: data.message
    }
  })
}

const UpdateCompanyDetils = (dispatch) => async (data) => {
  dispatch({
    type: "UpdateCompanyDetils",
    payload: data
  })
}

const UpdateUserDetails = (dispatch) => async (res) => {
  dispatch({
    type: "setUserDetail",
    payload: {
      loggedIn: true,
      token: res.data.data[0].access_token,
      id: res.data.data[0]['user_data'].user_id,
      name: res.data.data[0]['user_data'].user_name,
      role_id: res.data.data[0]['user_data'].user_role_id,
      status_id: res.data.data[0]['user_data'].user_status_id,
      company_id: res.data.data[0]['user_data'].user_company_id,
      company_name: res.data.data[0]['user_data'].user_company_name,
      user_details: res.data.data[0]['user_data'],
      flag_authenticated: true,
    }
  })
}

const UpdatelogoutUserDetails = (dispatch) => async () => {
  dispatch({
    type: "setUserDetail",
    payload: {
      loggedIn: false,
      token: "",
      id: "",
      name: "",
      flag_authenticated: false,
    }
  })
}

const updateCalculationHistory = (dispatch) => async (data) => {
  dispatch({
    type: "calculationHitory",
    payload:data
  })
}

const loginCall = (dispatch) => async (data) => {

  dispatch({
    type: "setLoader",
    payload: true
  })
  let flag = null;
  await LoginAPI(data,
    (res) => {
      console.log("Login call successfull response", res.data);
      if (res.data.status == true) {
        flag = true;
        dispatch({
          type: "setUserDetail",
          payload: {
            loggedIn: true,
            token: res.data.data[0].access_token,
            id: res.data.data[0]['user_data'].user_id,
            name: res.data.data[0]['user_data'].user_name,
            role_id: res.data.data[0]['user_data'].user_role_id,
            status_id: res.data.data[0]['user_data'].user_status_id,
            company_id: res.data.data[0]['user_data'].user_company_id,
            company_name: res.data.data[0]['user_data'].user_company_name,
            user_details: res.data.data[0]['user_data'],
            flag_authenticated: true,
          }
        })
        dispatch({
          type: "setLoader",
          payload: false
        })
      }
      else {
        flag = false;
        // if (res.data.error.code == 9006) {}
        dispatch({
          type: "setErrorMessage",
          payload: {
            status: true,
            message: res.data.error.message
          }
        })
        dispatch({
          type: "setLoader",
          payload: false
        })
      }
      return true;
    }, (err) => {
      flag = false;
      console.log(err);
      dispatch({
        type: "setErrorMessage",
        payload: {
          status: true,
          message: "Something Went wrong!"
        }
      })
      dispatch({
        type: "setLoader",
        payload: false
      })
      return false;
    }
  );
  return flag
}

const logoutCall = (dispatch) => async () => {

  dispatch({
    type: "setUserDetail",
    payload: {
      token: "",
      id: "",
      name: "",
      flag_authenticated: false,
    }
  })
  dispatch({
    type: "loader_update",
    payload: {
      flag_loader: true
    }
  })
  LogoutUserAPI({

    email: "",
    password: ""

  }, (res) => {

    if (res.data.status === true) {
      window.location.replace("/login");
      dispatch({
        type: "loader_update",
        payload: {
          flag_loader: false
        }
      })
    }
    else {
      dispatch({
        type: "loader_update",
        payload: {
          flag_loader: false
        }
      })
      // alert("Something went wrong, Please try again later.!");
    }

  }, (err) => {
    //  alert("Something went wrong, Please try again later.!",err);
  })

}
const checkUserLoggedIn = (dispatch) => async () => {

  UserMeAPI((res) => {
    console.log("UserMe API Response in Auth Context page ", res.data);
    if (res.data.status == true) {
      dispatch({
        type: "setUserDetail",
        payload: {
          loggedIn: true,
          token: res.data.data[0].access_token,
          id: res.data.data[0]['user_data'].user_id,
          name: res.data.data[0]['user_data'].user_name,
          role_id: res.data.data[0]['user_data'].user_role_id,
          status_id: res.data.data[0]['user_data'].user_status_id,
          company_id: res.data.data[0]['user_data'].user_company_id,
          company_name: res.data.data[0]['user_data'].user_company_name,
          user_details: res.data.data[0]['user_data'],
          flag_authenticated: true,
        }
      })
    }
    else {
      dispatch({
        type: "setErrorMessage",
        payload: {
          message: "Invalid Email address or password"
        }
      })
      dispatch({
        type: "setUserDetail",
        payload: {
          token: "",
          id: "",
          name: ""
        }
      })
    }
  }, (err) => {
    dispatch({
      type: "setErrorMessage",
      payload: {
        message:  "Email address or password"
      }
    })
    console.log("Error response on User Me call in Auth Context", err);
    dispatch({
      type: "setUserDetail",
      payload: {
        token: "",
        id: "",
        name: ""
      }
    })
  });


}

export const { Provider, Context } = createDataContext(
  auth_context_Reducer,
  { loginCall, 
    checkUserLoggedIn, 
    logoutCall, 
    UpdateLoader, 
    UpdateUserDetails, 
    UpdateCompanyDetils, 
    UpdatelogoutUserDetails,
    updateErrorMessage, 
    updateCalculationHistory, 
    setPreviousUrl 
  },
  {
    loading: false,
    calculationHistory: 0,
    previousUrl: "",
    company: {},
    user_detail: {
      loggedIn: false,
      token: "",
      id: "",
      name: "",
      flag_authenticated: false,
    },
  }
);