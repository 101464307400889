import React from "react";
import './RPage.css';
import { Alert, Button, Checkbox, Dialog, FormControlLabel, FormGroup, Grid, Snackbar, TextField, Typography } from "@mui/material";
import Header from "../../GlobalComponent/Header/Header";
import { Box } from "@mui/system";
import { useState } from "react";

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import CloseIcon from '@mui/icons-material/Close';
import RegisterSuccessPopup from "../../GlobalComponent/RegisterSuccessPopup/RegisterSuccessPopup";
import { useContext } from "react";
import { Context } from "../../Context/auth_context";
import { getAgentList, registerNewUser, updateAgentData } from "../../service/login.service";
import { useEffect } from "react";






const RPage = (props) => {

  const { user_detail } = useContext(Context).state;
  const { UpdateLoader } = useContext(Context);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [registerUserPopup, setRegisterUserPopup] = useState(false);
  const [userDetail, setUserDetail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    captcha: ""
  })
  const [showMessage, setShowMessage] = useState({
    'status': false,
    'error': false,
    'message': ""
  })

  const onChangeFun = () => {
    setShowMessage({
      status: false,
      error: false,
      message: ""
    })
  }

  const registerPopup = () => {
    const { firstName, lastName, email, phone, captcha } = userDetail;


    const emailRegx = /^([a-zA-Z0-9\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a -z]+)?$/;
    if (!emailRegx.test(email)) {
      setShowMessage({
        status: false,
        error: true,
        message: "Invalid Email Address!"
      })
    }
    else {
      if (!firstName || !lastName || !phone) {
        setShowMessage({
          status: false,
          error: true,
          message: "Please fill all fields.!"
        })

      }
      else {
        UpdateLoader(true);
        if (props?.data?.id == 0) {
          let payload = {
            "agent_id": props?.row?.user_id,
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "contact": phone
          }
          updateAgentData(payload, (res) => {
            if (res.data.status === true) {
              setShowMessage({
                status: true,
                message: "User Updated Successfully!",
                error: "",
              })
              getAgentList((res) => {
                props.close();
                UpdateLoader(false);
                if (res.data.status) {
                  res.data.data[0].agents = res.data.data[0].agents.sort((a, b) => a.user_firstname.toLowerCase() > b.user_firstname.toLowerCase() ? 1 : -1);
                  res.data.data[0].agents = res.data.data[0].agents.map(row => {
                    if (row.user_role_id != 1 && row.user_status_id == "1") {
                      return { ...row, pending: true };
                    }
                    else {
                      return { ...row, pending: false };
                    }
                  })
                  props.setAgentList(res.data.data[0].agents);
                }
                else {
                  setShowMessage({
                    message: res.data.error.message
                  })
                }
              },
                (err) => {
                  UpdateLoader(false);
                  console.log("Pritning err", err)
                })
            }
            else {
              UpdateLoader(false);
            }
          },
            (err) => {
              // debugger;
              UpdateLoader(false);
              setShowMessage({
                status: true,
                message: "Something went wrong.",
                error: true,
              })
            })
        }
        else {
          let payload = {
            first_name: firstName,
            last_name: lastName,
            email_address: email,
            contact_number: phone,
            re_captcha: captcha === "" ? "123" : captcha,
            created_by_admin_flag: true,
            admin_user_id: user_detail?.id,
            agent_licensed_checkbox: true,
            user_agreement_checkbox: false,
          };
          registerNewUser(payload, (res) => {
            if (res.data.status === true) {
              setShowMessage({
                status: true,
                message: res.data.data.message,
                error: "",
              })
              getAgentList((res) => {


                if (res.data.status) {
                  res.data.data[0].agents = res.data.data[0].agents.map(row => {
                    if (row.user_role_id != 1 && row.user_status_id == "1") {
                      return { ...row, pending: true };
                    }
                    else {
                      return { ...row, pending: false };
                    }
                  })
                  props.close();
                  props.setAgentList(res.data.data[0].agents);
                  UpdateLoader(false);
                }
                else
                  setShowMessage({
                    message: res.data.error.message
                  })
              }, (err) => {
                UpdateLoader(false);
                console.log("Pritning err", err)
              })
            }
            else {
              UpdateLoader(false);
              setShowMessage({
                status: false,
                error: true,
                message: res.data.error.message
              })
            }
          }, (err) => {
            UpdateLoader(false);
            setShowMessage({
              status: true,
              message: "Something went wrong.",
              error: true,
            })
          })
        }
      }
    }
  }

  useEffect(() => {

    if (props?.row?.length > 1) {
      setUserDetail({
        firstName: props.row.user_firstname,
        lastName: props.row.user_lastname,
        email: props.row.user_email,
        phone: props.row.user_contact,
        captcha: ""
      })
    }

  }, [props])

  // when user edit page
  useEffect(() => {
    if (props.editFlag) {
      setUserDetail({
        firstName: props.row.user_firstname,
        lastName: props.row.user_lastname,
        email: props.row.user_email,
        phone: props.row.user_contact,
        captcha: ""
      })
    }
    props.setEditFlag(false)

  }, [props.editFlag])

  const handleClose = () => {
    setRegisterUserPopup(false);
    setShowMessage({
      status: false,
      message: "",
      error: false,
    });
    setUserDetail({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      captcha: ""
    })
    props.handleClose()
  }

  const handleClose1 = () => {
    setShowMessage({
      status: false,
      message: "",
      error: "",
    })
  };


  return (
    <>
      {showMessage.status &&
        <Snackbar open={true} autoHideDuration={6000} onClose={handleClose1}>
          <Alert onClose={handleClose1} severity={showMessage.error == true ? "error" : "success"} sx={{ width: '100%' }}>
            {showMessage.message}
          </Alert>
        </Snackbar>
      }
      <Header />

      <Dialog
        fullWidth={fullWidth}
        open={true}
        onClose={props.close}
        aria-labelledby="responsive-dialog-title"

      >
        <DialogContent>
          <Grid container >
            <Grid item xs={12}>
              <Grid container className="reg-popup-main-container">
                <Grid item xl={9} lg={9} md={9} sm={11.5} xs={11.5}>
                  <Grid container className="reg-popup-sub-container" >

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={10} className="reg-main-heading">
                          <span id="reg-popup-heading">{(props?.data?.id == 0) ? "Edit Agent" : "Register Agent"}</span>
                        </Grid>
                        <Grid item xs={2} textAlign="start">
                          <Box id="close-btn"><CloseIcon onClick={props.close} /></Box>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container className="reg-popup-fields">

                        <Grid item xs={12}>
                          <Grid container className="reg-popup-field1" >
                            <Grid item xs={12} className="reg-popup-item1">
                              {showMessage.error && <Alert severity="error" >{showMessage.message}</Alert>}
                              <span id="reg-popup-label">First Name <span id="reg-astrisk-sign">*</span> </span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                required
                                className="reg-popup-fname"
                                id="outlined-size-small"
                                size="medium"
                                placeholder="Enter First Name"
                                value={userDetail.firstName}
                                onChange={(e) => {
                                  onChangeFun()
                                  setUserDetail({
                                    ...userDetail,
                                    firstName: e.target.value
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container className="reg-popup-field1" >
                            <Grid item xs={12} className="reg-popup-item2">
                              <span id="reg-popup-label">Last Name <span id="reg-astrisk-sign">*</span></span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                required
                                className="reg-popup-lname"
                                id="outlined-size-small"
                                size="medium"
                                placeholder="Enter Last Name"
                                value={userDetail.lastName}
                                onChange={(e) => {
                                  onChangeFun()
                                  setUserDetail({
                                    ...userDetail,
                                    lastName: e.target.value
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container className="reg-popup-field1" >
                            <Grid item xs={12} className="reg-popup-item3">
                              <span id="reg-popup-label">Email <span id="reg-astrisk-sign">*</span> </span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                required
                                fullWidth
                                className={(props?.data?.id == 0) ? "reg-popup-email disableEmail" : "reg-popup-email "}
                                id="outlined-size-small"
                                size="medium"
                                type="email"
                                placeholder="Enter Email"
                                value={userDetail.email}
                                onChange={(e) => {
                                  onChangeFun()
                                  setUserDetail({
                                    ...userDetail,
                                    email: e.target.value
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container className="reg-popup-field1" >
                            <Grid item xs={12} className="reg-popup-item4">
                              <span id="reg-popup-label">Phone <span id="reg-astrisk-sign">*</span></span>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                className="reg-popup-phone"
                                fullWidth
                                required
                                id="outlined-size-small"
                                size="medium"
                                placeholder="Enter Phone"
                                value={userDetail.phone}
                                onChange={(e) => {
                                  onChangeFun()
                                  setUserDetail({
                                    ...userDetail,
                                    phone: e.target.value
                                  })
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* <Grid item xs={12}>
                          <Grid container className="reg-popup-field2">
                            <Grid item xs={12} className="reg-popup-item5">
                              <Box className="captcha-box">
                                <ReCAPTCHA
                                  sitekey="6Ld3zhcaAAAAALdQpxG7ktmwT_39Oahb1BPBjYDP"
                                  // onChange={onChange}
                                  onChange={(token) => {
                                    setUserDetail({
                                      ...userDetail,
                                      captcha: token
                                    })
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid> */}

                        <Grid item xs={12}>
                          <Grid container className="reg-popup-field3">
                            <Grid item className="reg-popup-item6">
                              <Button id="reg-popup-register-btn" variant="contained" onClick={registerPopup}> {(props?.data?.id == 0) ? "Update" : "Register"}</Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <RegisterSuccessPopup registerUserPopup={registerUserPopup} close={handleClose} />
    </>
  )
}


export default RPage;