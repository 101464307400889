import { Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { Context as mainContext } from '../../Context/auth_context'
import './UserAgreement.css';

export const UserAgreement = () => {

    const { state } = useContext(mainContext)
    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item sm={8} xs={8} className="logoHeader">
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item sm={12} xs={12} className="tncLogo">
                        <img src={state.company.company_logo} height={50} alt={state.company.company_description} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} xs={12} textAlign="center">
                <h4 id="tncTitle">User Agreement</h4>
            </Grid>


            <Grid item sm={8} xs={8} className="tncContainer">
                <Grid container spacing={3} className="paraData">
                    <Grid item sm={12} xs={12} textAlign="center">
                        <Typography variant='p' fontWeight="bold">{state.company.company_description}, Inc. RetirementBrain User Agreement</Typography>
                    </Grid>

                    <Grid item sm={12} xs={12}>
                        <Typography variant='p'>
                            By agreeing to The RetirementBrain User Agreement Relating to the access to The RetirementBrain Intellectual Property, Life Insurance and Annuity Production, Carrier Contracts and Non­Competition (the “Agreement”), the undersigned (“Producer”) represents, warrants, acknowledges, and agrees as follows:
                        </Typography>
                    </Grid>

                    <Grid item sm={12} xs={12} className="lastElement">
                        <ol type="I">
                            <li className='listHead'>Production
                                <ol type='i'>
                                    <li className='agreeSubList'>RetirementBrain will be made available at no monthly charge, as long as the Producer settles a majority (51% or more) of their Index Universal Life premium through {state.company.company_description}, Inc. every calendar quarter.</li>
                                    <li className='agreeSubList'>A new business must be submitted within 30 days of joining RetirementBrain</li>
                                    <li className='agreeSubList'>Submitted business within the first 90 days of enrollment must show the Producer is on target for their annual settled premium requirement as indicated in (I.i).</li>
                                    <li className='agreeSubList'> If settled production goals are not met at the end of six months from the time of enrollment, the Producer will be terminated from the RetirementBrain program at the discretion of {state.company.company_description}, Inc</li>
                                    <li className='agreeSubList'>Production is evaluated on a quarterly calendar basis. The Producer will receive an update from {state.company.company_description}, Inc. via email and phone call.</li>
                                </ol>
                            </li>
                            <li className='listHead'>Required Carrier Contracts
                                <ol type='i'>
                                    <li className='agreeSubList'>The Producer is required to contract with the carrier(s) indicated by their {state.company.company_description}, Inc Agent Relationship Manager. {state.company.company_description}, Inc. reserves the right to request additional carrier contracts if it is in the best interest of the client.
                                    </li>
                                    <li className='agreeSubList'>Dual contracting will not be permitted for the carriers referenced in this agreement. Cancellation confirmation of the additional contract must be submitted to {state.company.company_description}, Inc.</li>
                                    <li className='agreeSubList'> Proof of contract release request must be submitted to {state.company.company_description}, Inc</li>
                                </ol>
                            </li>

                            <li className='listHead'>Non-Compete
                                <ol type='i'>
                                    <li className='agreeSubList'>Production from the required carrier contracts must be submitted to {state.company.company_description}, Inc. when sales result from marketing or use of {state.company.company_description}, Inc. marketing brands such as but not limited to RetirementBrain.</li>
                                    <li className='agreeSubList'>Use of RetirementBrain intellectual property, including but not limited to, sales tools, calculators, fact finders, needs analysis tools, agent training videos, consumer videos, customized presentations, and seminars is not permitted for sales outside of {state.company.company_description}, Inc.</li>
                                    <li className='agreeSubList'>Abuse of RetirementBrain User Agreement will result in termination of this agreement and potential recovery of commissions or damages resulting from violations of this agreement.</li>
                                    <li className='agreeSubList'>{state.company.company_description}, Inc. reserves the right to make changes to RetirementBrain User Agreement and will provide written notification of the changes to the Producer.</li>
                                </ol>
                            </li>
                        </ol>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item sm={8} xs={8} className="tncDis" >
                <Grid container className='paraData'>
                    <Grid item sm={12} xs={12}>
                        <h4 className='tncTitle'>Disclaimer</h4>
                        <Typography variant='p'>
                            This presentation is a general discussion of the topic presented, based on our current understanding of applicable tax laws, regulations, and rulings. This information and presentation should not be interpreted as investment or tax advice. In actual practice, the transactions discussed may be more complex and will require the attention and expertise of professional tax and legal advisors. In order to comply with certain U.S. Treasury regulations, please be advised of the following: unless expressly stated otherwise, any reference to U.S. Federal tax information contained in these materials, including any attachments, is not intended or written to be used, and cannot be used, by any person for the purpose of avoiding any penalties that may be imposed by the Internal Revenue Service. Values and benefits are not guaranteed unless stated as guaranteed on illustrations. Examples illustrate the potential benefits of indexed universal life insurance / IRS SEC. 7702 & 72(e). Portions of this presentation are based on NAIC-compliant life insurance illustrations which must be presented during the presentation and fully reviewed for additional disclosures.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
