import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, AlertTitle, Button, FormLabel, Grid, Stack, TextField, Typography } from '@mui/material'
import "./ResetPassword.css";
import Header from "../../GlobalComponent/Header/Header";
import Footer from "../../GlobalComponent/Footer/Footer";
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import { Context as mainContext } from '../../Context/auth_context'
import { MobileFooter } from "../../GlobalComponent/MobileFooter/MobileFooter";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useEffect } from "react";
import { resetPasswordAPI } from "../../service/login.service"

export const ResetPassword = () => {

    const navigate = useNavigate();
    const { UpdateLoader, state } = useContext(mainContext)
    const [saveFlag, setSaveFlag] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState({
        "token": "",
        "email": "",
        "password": "",
        "confirm_password": ""
    })
    const [showMessage, setShowMessage] = useState({
        'status': false,
        'error': false,
        'message': ""
    })
    useEffect(() => {
        let ggb = showMessage;
        // debugger
    }, [showMessage])
    const savedata = () => {
        setShowMessage({ status: false, message: "", error: false })

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        if (!user.password) {
            setShowMessage({
                status: false,
                message: "Please fill all the fields.",
                error: true,
            })
            return
        }
        else if (!passwordRegex.test(user.password)) {
            setShowMessage({
                status: false,
                message: "Password must be Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.",
                error: true,
            })
            return
        }

        else if (user.password !== user.confirm_password) {
            setShowMessage({
                status: false,
                message: "Password does not match.",
                error: true,
            })
            return
        } else {
            UpdateLoader(true)
            setShowMessage({ status: false, message: "", error: false })
            resetPasswordAPI(user, (res) => {
                if (res.data.status) {
                    setShowMessage({
                        status: true,
                        message: "Password Changed Successfully!",
                        error: false,
                    })
                    setSaveFlag(true)
                } else {
                    setShowMessage({
                        status: true,
                        message: res.error.message,
                        error: true,
                    })
                }
                UpdateLoader(false)
            }, (err) => {
                UpdateLoader(false)
                setShowMessage({
                    status: false,
                    message: "Something Went Wrong!",
                    error: true,
                })
            })
        }
    }

    useEffect(() => {

        let token = searchParams.get("verify_token")
        let email = searchParams.get("email")
        if (!token || !email) {
            return navigate('/login')
        }
        setUser({
            "token": token,
            "email": email,
            "password": "",
            "confirm_password": ""
        })
    }, [])

    return (
        <>
            <Header />
            <Grid container className="reset-main-contaiber">
                <Grid item xs={12} className="reset-img-main-item">
                    <Grid container className="reset-img-main-container">
                        <Grid item xl={4} lg={5} md={6} sm={10} xs={11} className="reset-blure-img-item" >
                            <Grid container className="reset-items-center-container">
                                <Grid item xs={10} sm={9} md={8}>
                                    <Grid container className="reset-fields-main-container">

                                        <Grid item xs={12}>
                                            <Grid container className="reset-fields-sub-container">
                                                <Typography id="reset-tittle">Reset Password</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="reset-email-field-container">
                                                {/* <Grid item xs={12} marginBottom={0.3}>
                                                    {showMessage.error && <Alert severity="error" >{showMessage.message}</Alert>}
                                                    {state?.error?.status && <Alert severity="error">{state.error.message}</Alert>}
                                                </Grid> */}
                                                {showMessage.status &&
                                                    <Grid item xs={12}>
                                                        <Alert severity="success" sx={{ textAlign: "justify" }}>
                                                            <strong>Success: </strong>{showMessage.message}
                                                        </Alert>
                                                    </Grid>
                                                }
                                                {showMessage.error &&
                                                    <Grid item xs={12}>
                                                        <Alert severity={showMessage.error ? "error" : "success"} sx={{ textAlign: "justify" }}>
                                                            <strong>Error: </strong>{showMessage.message}
                                                        </Alert>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Grid container className="reset-email-field-label">
                                                        <Typography id="reset-fields-label">Email Address</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="reset-email-textfield-container">
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className="reset-email-textfield"
                                                                id="outlined-size-small"
                                                                size="small"
                                                                disabled={true}
                                                                value={user.email}
                                                                placeholder="Enter Email"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" className="person-icon">
                                                                            <PersonIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="reset-password-field-container">
                                                <Grid item xs={12}>
                                                    <Grid container className="reset-password-field-label">
                                                        <Typography id="reset-fields-label">New Password</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="reset-email-textfield-container">
                                                        <TextField
                                                            className="reset-password-textfield"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            type="password"
                                                            placeholder="Enter Password"
                                                            onChange={(e) => {
                                                                setUser({ ...user, password: e.target.value })
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start" className="lock-icon">
                                                                        <LockOutlinedIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container className="reset-password-field-container">
                                                <Grid item xs={12}>
                                                    <Grid container className="reset-password-field-label">
                                                        <Typography id="reset-fields-label">Confirm New Password</Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="reset-email-textfield-container">
                                                        <TextField
                                                            className="reset-password-textfield"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            type="password"
                                                            placeholder="Re-Enter Password"
                                                            onChange={(e) => {
                                                                setUser({ ...user, confirm_password: e.target.value })
                                                            }}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start" className="lock-icon">
                                                                        <LockOutlinedIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>



                                        <Grid item xs={12}>
                                            <Grid container className="reset-create-btn-container">
                                                <Grid item xs={12}>
                                                    <Grid container className="reset-btn-container">
                                                        {(saveFlag) ?
                                                            (<Button variant="outlined" color="info" id="reset-btn-text" onClick={() => {
                                                                navigate('/login')
                                                            }} sx={{ color: "white", border: "1px solid" }}>Back to Login Page</Button>) :
                                                            (<Button variant="contained" id="reset-btn-text" disable={saveFlag} onClick={savedata} >Save</Button>)
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
            <MobileFooter className="mobilefooterResposive" />
        </>
    )
}
