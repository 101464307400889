import { Avatar, Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import Header from "../../GlobalComponent/Header/Header";
import './UPTaxes.css';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ShareIcon from '@mui/icons-material/Share';
import CreateIcon from '@mui/icons-material/Create';
import OutputAction from "../OutputAction/OutputAction";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const UPTaxes = (props) => {

    let finalData = props.data;
    var taxTypicalAge = []
    var taxIul_planAge = []
    if (finalData?.typical_plan?.TAXES_age_data && finalData?.iul_plan?.TAXES_age_data) {
        taxTypicalAge = Object.keys(finalData?.typical_plan?.TAXES_age_data)
        taxIul_planAge = Object.keys(finalData?.iul_plan?.TAXES_age_data)
        var taxTpAgeMin = taxTypicalAge[0]
        var taxTpAgeMax = taxTypicalAge[taxTypicalAge.length - 1]
        var taxIULAgeMin = taxIul_planAge[0]
        var taxIULAgeMax = taxIul_planAge[taxIul_planAge.length - 1]
    }

    const [changeYear, setChangeYear] = useState(finalData.ageAtRetirement)
    const [changeYearIUL, setChangeYearIUL] = useState(finalData.ageAtRetirement)
    const [calculationShowError, setCalculationShowError] = useState(false)
    const [calculationShowErrorIUL, setCalculationShowErrorIUL] = useState(false)


    const formatNumber = (q) => {
        if (q || q == 0) {
            return q.toLocaleString('en-US', {
                style: 'currency',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                currency: "usd"
            })
        }
    }

    useEffect(() => {
        document.getElementById("outlined-basic").addEventListener("mouseup", () => {
            inputChangeYear();
        })
        document.getElementById("outlined-basic").addEventListener("mousedown", () => {
            inputChangeYear();
        })
    }, [])

    useEffect(() => {
        if (changeYear == Number(taxTypicalAge[taxTypicalAge.length - 1])) {
            setCalculationShowError(true)
        } else {
            setCalculationShowError(false)
        }
    }, [changeYear])

    useEffect(() => {
        if (changeYearIUL == Number(taxIul_planAge[taxIul_planAge.length - 1])) {
            setCalculationShowErrorIUL(true)
        } else {
            setCalculationShowErrorIUL(false)
        }
    }, [changeYearIUL])


    const inputChangeYear = (e) => {
        let val = parseInt(e.target.value);
        if (val >= Number(taxTypicalAge[0]) && val <= Number(taxTypicalAge[taxTypicalAge.length - 1])) {
            setChangeYear(val)
        }
        else {

            if (val >= Number(taxTypicalAge[taxTypicalAge.length - 1]))
                setChangeYear(Number(taxTypicalAge[taxTypicalAge.length - 1]))
            else {
                setChangeYear(Number(taxTypicalAge[0]))
            }
        }

    }

    const InputChangeYearIUL = (e) => {
        let val = parseInt(e.target.value);
        if (val >= Number(taxIul_planAge[0]) && val <= Number(taxIul_planAge[taxIul_planAge.length - 1])) {
            setChangeYearIUL(val)
        }
        else {

            if (val >= Number(taxIul_planAge[taxIul_planAge.length - 1]))
                setChangeYearIUL(Number(taxIul_planAge[taxIul_planAge.length - 1]))
            else {
                setChangeYearIUL(Number(taxIul_planAge[0]))
            }
            return;
        }
    }

    const isInputBetween = (num1, num2, value) => value > num1 && value < num2;
    const inputBlureChangeYear = (e) => {
        let val = parseInt(e.target.value);
        let checkInBeetween = isInputBetween(Number(taxTypicalAge[0]), Number(taxTypicalAge[taxTypicalAge.length - 1]), val)
        if (checkInBeetween == false) {
            if (val >= Number(taxTypicalAge[taxTypicalAge.length - 1]))
                setChangeYear(Number(taxTypicalAge[taxTypicalAge.length - 1]))
            else {
                setChangeYear(Number(taxTypicalAge[0]))
            }
            return
        }
    }

    const InputBlurChangeIUL = (e) => {
        let val = parseInt(e.target.value);
        let checkInBeetween = isInputBetween(Number(taxIul_planAge[0]), Number(taxIul_planAge[taxIul_planAge.length - 1]), val)
        if (checkInBeetween == false) {
            if (val >= Number(taxIul_planAge[taxIul_planAge.length - 1]))
                setChangeYearIUL(Number(taxIul_planAge[taxIul_planAge.length - 1]))
            else {
                setChangeYearIUL(Number(taxIul_planAge[0]))
            }
            return
        }
    }

    return (
        <>
            {/* Desktop/Laptop */}
            {window.screen.width > 900 &&
                <Grid item xs={12}>
                    <Grid container className="up-taxes-white-main-container"  >

                        {/* Fees */}
                        <Grid item xs={1}>
                            <Grid container className="taxes-left-blue-container" >
                                <Typography id="taxes-left-blue-text">Taxes</Typography>
                            </Grid>
                        </Grid>

                        {/* Typical plan & IUL */}
                        <Grid item xs={10.5}  >
                            <Grid container className="taxes-tp-iul-container"   >

                                {/* Typical plan */}
                                <Grid item xl={4} lg={4.5} md={5.5} xs={4.5} className="taxes-typical-plan-item" >
                                    <Grid container className={(calculationShowError) ? "taxes-typical-plan-container redBorder" : "taxes-typical-plan-container"}  >

                                        <Grid item xl={10.5} lg={10.8} xs={10.5}>
                                            <Grid container>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-head-container">
                                                        <span id="taxes-tp-container-heading">Typical Plan</span>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Age at Retirement</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Annual Contribution</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <Typography id="taxes-tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.annualContribution) ? formatNumber(finalData?.typical_plan?.annualContribution) : "0"}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Current Account Balance</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.currAccountBalance) ? formatNumber(finalData?.typical_plan?.currAccountBalance) : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-taxes-hr-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9} >
                                                            <span id="taxes-tp-label">Tax Rate&nbsp;<span id="taxes-tp-braces-label">(Before Retirement)</span></span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">{(finalData?.typical_plan?.tax_rate_before_retirement) ? (finalData?.typical_plan?.tax_rate_before_retirement) : "0"}%</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Annual Taxes Deferred</span>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.annual_tax_deferred) ?
                                                                            formatNumber(finalData?.typical_plan?.annual_tax_deferred) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Annual Tax Deferred&nbsp;<span id="taxes-tp-braces-label">(Before Retirement)</span></span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">{formatNumber(finalData?.typical_plan?.TAXES_age_data[taxTypicalAge[0]]?.total_taxes_deferred) ?
                                                                        formatNumber(finalData?.typical_plan?.TAXES_age_data[taxTypicalAge[0]]?.total_taxes_deferred) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-taxes-hr-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>



                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Annual Pre-Tax Income in Retirement</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.annual_pre_tax_income_in_retirement) ? formatNumber(finalData?.typical_plan?.annual_pre_tax_income_in_retirement) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Annual Spendable Income in Retirement</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.annual_spendable_income) ? formatNumber(finalData?.typical_plan?.annual_spendable_income) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>



                                                {/* life insurance start */}

                                                <Grid item xs={12} className="taxes-tp-life-item">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Grid container className="texes-tp-life-head-container">
                                                                <Grid item>
                                                                    <span id="taxes-tp-life-container-heading">Taxes Paid During Retirement</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className={parseFloat(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_taxes_deferred, 10) > 0 ? "taxes-tp-life-container" : "taxes-tp-life-container errorMinusValueDeferred"} >
                                                                <Grid item xs={12} className="taxes-tp-life-sub-item-first">
                                                                    <Grid container className="taxes-tp-life-sub1-container" >
                                                                        <Grid item xs={8} md={8}>
                                                                            <span id="taxes-tp-life-field-label">Total Taxes Deferred <span id="taxes-braces-fontsize-label">(Before Retirement)</span></span>
                                                                        </Grid>

                                                                        <Grid item xs={4} md={4}>
                                                                            <Grid container className="taxes-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <Typography id="taxes-tp-life-field-value">{formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_taxes_deferred) ? formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_taxes_deferred) : "$0"}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="taxes-tp-life-container" >
                                                                <Grid item xs={12} className="taxes-tp-life-sub-item">
                                                                    <Grid container className="taxes-tp-life-sub1-container" >
                                                                        <Grid item xs={8} >
                                                                            <span id="taxes-tp-life-field-label">Total Rate</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="taxes-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="taxes-tp-life-field-value">{(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_rate) ? (finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_rate) : "0"}%</span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="taxes-tp-life-container" >
                                                                <Grid item xs={12} className="taxes-tp-life-sub-item">
                                                                    <Grid container className="taxes-tp-life-sub1-container" >
                                                                        <Grid item xs={8} >
                                                                            <span id="taxes-tp-life-field-label">Annual Income Tax Paid</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="taxes-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="taxes-tp-life-field-value">
                                                                                        {formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.annual_income_tax_paid) ?
                                                                                            formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.annual_income_tax_paid) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        {/* ************************** LINE-LAST ************************  */}
                                                        <Grid item xs={12} >
                                                            <Grid container className={calculationShowError ? "taxes-tp-life-container-last-true" : "taxes-tp-life-container-last"}>
                                                                <Grid item xs={12} className="taxes-tp-life-sub-item">
                                                                    <Grid container className="taxes-tp-life-sub1-container" >
                                                                        <Grid item xs={8} >
                                                                            <span id="taxes-tp-life-field-label">Total Income Tax Paid</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="taxes-tp-sub-field-value-conatiner">
                                                                                <Grid item>
                                                                                    <span id="taxes-tp-life-field-value">
                                                                                        {formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_income_tax_paid) ?
                                                                                            formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_income_tax_paid) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        {calculationShowError &&
                                                            <Grid item xs={12}>
                                                                <Grid container className="all-module-taxes-red-label">
                                                                    <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                        <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>TYPICAL PLAN ACCOUNT IS EMPTY</span>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }

                                                        {/* life insurance end */}
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* IUL */}
                                <Grid item xl={4} lg={4.5} md={5.5} xs={4.5} className="taxes-iul-item">
                                    <Grid container className="taxes-iul-container">
                                        <Grid item xl={10.5} lg={10.8} xs={10.5}>
                                            <Grid container>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-head-container">
                                                        <Grid item>
                                                            <span id="taxes-tp-container-heading">IUL</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Age at Retirement</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Annual Premium</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.iul_plan?.annual_premium) ? formatNumber(finalData?.iul_plan?.annual_premium) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Lump Sum Premium</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.iul_plan?.lumpSumPremium) ? formatNumber(finalData?.iul_plan?.lumpSumPremium) : "0"}</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-taxes-hr-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Tax Rate&nbsp;<span id="taxes-tp-braces-label">(Before Retirement)</span></span>
                                                            {/* <span id="taxes-tp-braces-label">(Before Retirement)</span> */}
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">
                                                                        {(finalData?.iul_plan?.tax_rate_rpe_retirement) ? (finalData?.iul_plan?.tax_rate_rpe_retirement) : "0"}%
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Annual Taxes Paid</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item >
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.iul_plan?.annual_taxes_paid) ? formatNumber(finalData?.iul_plan?.annual_taxes_paid) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container-total-last-container">
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Total Tax Paid&nbsp;<span id="taxes-tp-braces-label">(Before Retirement)</span></span>

                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item>
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.iul_plan?.total_taxes_paid_before_retirement) ? formatNumber(finalData?.iul_plan?.total_taxes_paid_before_retirement) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* breakline */}
                                                <Grid item xs={12}>
                                                    <Grid container className="tp-taxes-hr-border">
                                                        <Divider id="devider" />
                                                    </Grid>
                                                </Grid>


                                                {/* ************************** LINE-LAST ************************  */}
                                                <Grid item xs={12}>
                                                    <Grid container className="taxes-tp-sub-container-last" >
                                                        <Grid item xs={9}>
                                                            <span id="taxes-tp-label">Annual Spendable Income in Retirement</span>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <Grid container className="taxes-sub-value-container">
                                                                <Grid item>
                                                                    <span id="taxes-tp-value">
                                                                        {formatNumber(finalData?.typical_plan?.annual_spendable_income) ? formatNumber(finalData?.typical_plan?.annual_spendable_income) : "0"}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>



                                                <Grid item xs={12} className="taxes-iul-life-item" >
                                                    <Grid container className="taxes-iul-life-container">
                                                        <Grid item xs={12}>
                                                            <Grid container className="texes-iul-life-head-container">
                                                                <Grid item>
                                                                    <span id="taxes-iul-life-container-heading">Taxes Saved During Retirement</span>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="taxes-iul-sub-container" >
                                                                <Grid item xs={12} className="taxes-iul-sub-item-extra">
                                                                    <Grid container className="taxes-iul-sub1-container1" >
                                                                        <Grid item xs={8}>
                                                                            <span id="taxes-iul-life-field-label">Total Taxes Paid&nbsp;<span id="taxes-braces-fontsize-label">(Before Retirement)</span></span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="taxes-iul-life-field-value">
                                                                                <Grid item>
                                                                                    <span id="taxes-iul-life-field-value">
                                                                                        {formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_taxes_paid) ? formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_taxes_paid) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="taxes-iul-sub-container" >
                                                                <Grid item xs={12} className="taxes-iul-sub-item">
                                                                    <Grid container className="taxes-iul-sub1-container1">
                                                                        <Grid item xs={8}>
                                                                            <span id="taxes-iul-life-field-label">Total Rate</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="taxes-iul-life-field-value">
                                                                                <Grid item>
                                                                                    <span id="taxes-iul-life-field-value">
                                                                                        {(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_rate) ? (finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_rate) : "0"}%
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="taxes-iul-sub-container" >
                                                                <Grid item xs={12} className="taxes-iul-sub-item">
                                                                    <Grid container className="taxes-iul-sub1-container1" >
                                                                        <Grid item xs={8}>
                                                                            <span id="taxes-iul-life-field-label">Annual Income Tax Saved</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="taxes-iul-life-field-value">
                                                                                <Grid item>
                                                                                    <span id="taxes-iul-life-field-value">{formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.annual_income_tax_saved) ? formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.annual_income_tax_saved) : "$0"}</span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="taxes-iul-sub-container" >
                                                                <Grid item xs={12} className="taxes-iul-sub-item">
                                                                    <Grid container className="taxes-iul-sub1-container1" >
                                                                        <Grid item xs={8}>
                                                                            <span id="taxes-iul-life-field-label">Total Income Tax Saved</span>
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <Grid container className="taxes-iul-life-field-value">
                                                                                <Grid item>
                                                                                    <span id="taxes-iul-life-field-value">
                                                                                        {formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_income_tax_saved) ? formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_income_tax_saved) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Grid container className="taxes-iul-sub-container" >
                                                                <Grid item xs={12} className="taxes-iul-sub-item">
                                                                    <Grid container className="taxes-iul-sub1-container1" >
                                                                        <Grid item xs={8}>
                                                                            <span id="taxes-iul-life-field-label">Total Death Benefit</span>
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <Grid container className="taxes-iul-life-field-value">
                                                                                <Grid item>
                                                                                    <span id="taxes-iul-life-field-value">
                                                                                        {formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_death_benefit) ? formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_death_benefit) : "$0"}
                                                                                    </span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {calculationShowErrorIUL &&
                                                            <></>
                                                            // <Grid item xs={12}>
                                                            //     <Grid container className="all-module-taxes-red-label">
                                                            //         <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                            //             <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>IUL PLAN ACCOUNT IS EMPTY</span>
                                                            //         </Grid>
                                                            //     </Grid>
                                                            // </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Age */}
                                <Grid item xs={12}>
                                    <Grid container >
                                        <Grid item xs={12}>
                                            <Grid container className="taxes-tp-iul-age-main-container">
                                                {/* Typical plan for Desktop/Laptop */}
                                                <Grid item xl={4} lg={4.5} md={5.5} xs={4.5} className="taxes-tp-age-sub-item" >
                                                    <Grid container className="taxes-tp-age-sub-container" >
                                                        <Grid item xs={6} className="taxes-tp-iul-age-text">
                                                            <span id="taxes-tp-age-label">Age: &nbsp;</span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid container className="taxes-tp-iul-age-scroll-btn">
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    className="taxes-tp-digit-field"
                                                                    type="number"
                                                                    size="small"
                                                                    InputProps={{ inputProps: { min: taxTpAgeMin, max: taxTpAgeMax } }}
                                                                    onChange={(e) => {
                                                                        setChangeYear(e.target.value);
                                                                    }}
                                                                    onBlur={inputBlureChangeYear}
                                                                    onKeyUp={(e) => {
                                                                        if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                            inputChangeYear(e);
                                                                    }}
                                                                    value={changeYear}
                                                                    variant="outlined"

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/* Iul Plan for Desktop/Laptop */}
                                                <Grid item xl={4} lg={4.5} md={5.5} xs={4.5} className="taxes-iul-age-sub-item">
                                                    <Grid container className="taxes-iul-age-sub-container">
                                                        <Grid item xs={6} className="taxes-tp-iul-age-text">
                                                            <span id="taxes-iul-age-label">Age: &nbsp;</span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid container className="taxes-tp-iul-age-scroll-btn">
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    className="taxes-iul-digit-field"
                                                                    type="number"
                                                                    size="small"
                                                                    InputProps={{ inputProps: { min: taxIULAgeMin, max: taxIULAgeMax } }}
                                                                    onChange={(e) => {
                                                                        setChangeYearIUL(e.target.value);
                                                                    }}
                                                                    onBlur={InputBlurChangeIUL}
                                                                    onKeyUp={(e) => {
                                                                        if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                            InputChangeYearIUL(e);
                                                                    }}
                                                                    value={changeYearIUL}
                                                                    variant="outlined"

                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        {/* share edit btn */}
                        <Grid item xs={0.5}>
                            <OutputAction data={props.data} inputData={props.inputData} />
                        </Grid>

                    </Grid>
                </Grid>
            }

            {/* Mobile/Laptop */}
            {window.screen.width <= 900 &&
                <>
                    <Grid container className='md-avl-main-container'>
                        <Grid item sm={11} xs={11} className='md-tx-main-item'>
                            <Grid container>
                                <Grid item sm={12} xs={12} className='md-tx-item1' >
                                    <Accordion className="md-tx-tp-main-accordion" defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="md-tx-tp-acordion">Typical Plan</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item sm={12} xs={12}>
                                                        <Grid container>
                                                            <Grid item sm={12} xs={12} className='md-tx-tp-main-item'>
                                                                <Grid container className='md-tx-tp-blue-container'>
                                                                    <Grid item sm={12} xs={11} className='md-tx-tp-sub-item' >
                                                                        <Grid container>
                                                                            <Grid item sm={12} xs={12}>
                                                                                <Grid container className="md-tx-tp-fields">

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container className="md-tx-tp-container">

                                                                                            {/* <Grid item sm={12} xs={12} className="md-tx-tp-head-item">
                                                                                            <span className="md-tx-head-text">Typical Plan</span>
                                                                                        </Grid> */}

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-tx-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-tx-tp-iul-label-text">Age at Retirement</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-tx-tp-iul-value-text">
                                                                                                                    {finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>


                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-tx-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-tx-tp-iul-label-text">Annual Contribution</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-tx-tp-iul-value-text">
                                                                                                                    {formatNumber(finalData?.typical_plan?.annualContribution) ?
                                                                                                                        formatNumber(finalData?.typical_plan?.annualContribution) : "0"}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-tx-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-tx-tp-iul-label-text">Current Account Balance</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-tx-tp-iul-value-text">
                                                                                                                    {formatNumber(finalData?.typical_plan?.currAccountBalance) ?
                                                                                                                        formatNumber(finalData?.typical_plan?.currAccountBalance) : "0"}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item xs={12}>
                                                                                                        <Divider className="md-avl-devider" />
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-tx-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-tx-tp-iul-label-text">Tax Rate <span className="md-avl-tp-label-span">(Before Retirement)</span></span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-tx-tp-iul-value-text">
                                                                                                                    {(finalData?.typical_plan?.tax_rate_before_retirement) ?
                                                                                                                        (finalData?.typical_plan?.tax_rate_before_retirement) : "0"}%
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-tx-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-tx-tp-iul-label-text">Annual Taxes Deferred</span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-tx-tp-iul-value-text">
                                                                                                                    {formatNumber(finalData?.typical_plan?.annual_tax_deferred) ?
                                                                                                                        formatNumber(finalData?.typical_plan?.annual_tax_deferred) : "0"}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-tx-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-tx-tp-iul-label-text">Annual Tax Deferred <span className="md-avl-tp-label-span">(Before Retirement)</span></span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-tx-tp-iul-value-text">
                                                                                                                    {formatNumber(finalData?.typical_plan?.TAXES_age_data[taxTypicalAge[0]]?.total_taxes_deferred) ?
                                                                                                                        formatNumber(finalData?.typical_plan?.TAXES_age_data[taxTypicalAge[0]]?.total_taxes_deferred) : "0"}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item xs={12}>
                                                                                                        <Divider className="md-avl-devider" />
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-tx-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-tx-tp-iul-label-text">Annual Pre-Tax Income in Retirement </span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-tx-tp-iul-value-text">
                                                                                                                    {formatNumber(finalData?.typical_plan?.annual_pre_tax_income_in_retirement) ?
                                                                                                                        formatNumber(finalData?.typical_plan?.annual_pre_tax_income_in_retirement) : "0"}</span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    <Grid item sm={12} xs={12}>
                                                                                                        <Grid container className="md-tx-tp-field-container">
                                                                                                            <Grid item xs={9}>
                                                                                                                <span className="md-tx-tp-iul-label-text">Annual Spendable Income in Retirement </span>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={3}>
                                                                                                                <span className="md-tx-tp-iul-value-text">
                                                                                                                    {formatNumber(finalData?.typical_plan?.annual_spendable_income) ?
                                                                                                                        formatNumber(finalData?.typical_plan?.annual_spendable_income) : "0"}
                                                                                                                </span>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item sm={12} xs={12}>
                                                                                        <Grid container>
                                                                                            <Grid item sm={12} xs={12} className="md-tx-tp-head-item2">
                                                                                                <span className="md-tx-head-text2">Taxes Paid During Retirement</span>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className={parseFloat(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_taxes_deferred, 10) > 0 ? "md-tx-av-container" : "md-tx-av-container errorMinusValueDeferre"}>
                                                                                                    <Grid item xs={9} className="md-tax-red-label">
                                                                                                        <span className="md-tx-tp-iul-label-text">Total Taxes Deferred <span className="md-avl-tp-label-span">(Before Retirement)</span></span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3} className="md-tax-red-label">
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_taxes_deferred) ?
                                                                                                                formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_taxes_deferred) : "$0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-tx-av-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Total Rate</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {(finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_rate) ?
                                                                                                                (finalData?.typical_plan?.TAXES_age_data[changeYear]?.total_rate) : "0"}%
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-tx-av-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Annual Income Tax Paid</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.annual_income_tax_paid) ?
                                                                                                                formatNumber(finalData?.typical_plan?.TAXES_age_data[changeYear]?.annual_income_tax_paid) : "$0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item sm={12} xs={12}>
                                                                                                <Grid container className="md-tx-av-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Total Income Tax Paid</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.typical_plan?.RI_Age_data[changeYear]?.total_annual_sp_income) ?
                                                                                                                formatNumber(finalData?.typical_plan?.RI_Age_data[changeYear]?.total_annual_sp_income) : "$0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            {calculationShowError &&
                                                                                                <Grid item xs={12}>
                                                                                                    <Grid container className="taxes-tp-life-container-last" marginTop={1}>
                                                                                                        <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                                                            <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>TYPICAL PLAN ACCOUNT IS EMPTY</span>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            }

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    {/* Typical Plan for Mobile / Tablet*/}
                                                    <Grid item sm={12} xs={12} >
                                                        <Grid container className="md-age-container">
                                                            <Grid item sm={9} xs={7} className='md-age-item1'>
                                                                <span className="md-age-label">Age: </span>
                                                            </Grid>
                                                            <Grid item sm={3} xs={5} className='md-age-item2'>
                                                                <Grid container>
                                                                    <Grid item xs={12} className='md-age-num-item'>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            type="number"
                                                                            size="small"
                                                                            InputProps={{ inputProps: { min: taxTpAgeMin, max: taxTpAgeMax } }}
                                                                            onChange={(e) => {
                                                                                setChangeYear(e.target.value);
                                                                            }}
                                                                            onBlur={inputBlureChangeYear}
                                                                            onKeyUp={(e) => {
                                                                                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                                    inputChangeYear(e);
                                                                            }}
                                                                            value={changeYear}
                                                                            variant="outlined"

                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                                <Grid item sm={12} xs={12} className='md-tx-item2' >
                                    <Accordion className="md-tx-iul-main-accordion" defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="md-tx-iul-acordion">IUL</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} className='md-tx-iul-main-item'>
                                                        <Grid container className='md-tx-tp-blue-container'>
                                                            <Grid item xs={11} className='md-tx-iul-sub-item' >
                                                                <Grid container>
                                                                    <Grid item xs={12}>
                                                                        <Grid container className="md-tx-iul-fields">

                                                                            <Grid item xs={12}>
                                                                                <Grid container className="md-tx-iul-container">

                                                                                    {/* <Grid item xs={12} className="md-tx-iul-head-item">
                                                                                    <span className="md-tx-head-text">IUL</span>
                                                                                </Grid> */}

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-tx-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Age at Retirement</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">{finalData?.ageAtRetirement ? finalData?.ageAtRetirement : "0"}</span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-tx-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Annual Premium</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.iul_plan?.annual_premium) ? formatNumber(finalData?.iul_plan?.annual_premium) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-tx-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Lump Sum Premium   </span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.iul_plan?.lumpSumPremium) ?
                                                                                                                formatNumber(finalData?.iul_plan?.lumpSumPremium) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Divider className="md-avl-devider" />
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-tx-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Tax Rate <span className="md-avl-tp-label-span">(Before Retirement)</span></span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {(finalData?.iul_plan?.tax_rate_rpe_retirement) ?
                                                                                                                (finalData?.iul_plan?.tax_rate_rpe_retirement) : "0"}%
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-tx-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Annual Taxes Paid</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.iul_plan?.annual_taxes_paid) ? formatNumber(finalData?.iul_plan?.annual_taxes_paid) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-tx-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Total Taxes Paid <span className="md-avl-tp-label-span">(Before Retirement)</span></span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.iul_plan?.total_taxes_paid_before_retirement) ? formatNumber(finalData?.iul_plan?.total_taxes_paid_before_retirement) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Divider className="md-avl-devider" />
                                                                                            </Grid>

                                                                                            <Grid item xs={12}>
                                                                                                <Grid container className="md-tx-iul-field-container">
                                                                                                    <Grid item xs={9}>
                                                                                                        <span className="md-tx-tp-iul-label-text">Annual Spendable Income in Retirement</span>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={3}>
                                                                                                        <span className="md-tx-tp-iul-value-text">
                                                                                                            {formatNumber(finalData?.typical_plan?.annual_spendable_income) ?
                                                                                                                formatNumber(finalData?.typical_plan?.annual_spendable_income) : "0"}
                                                                                                        </span>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12}>
                                                                                <Grid container>
                                                                                    <Grid item xs={12} className="md-tx-tp-head-item2">
                                                                                        <span className="md-tx-head-text2">Taxes Saved During Retirement</span>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-tx-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-tx-tp-iul-label-text">Total Taxes Paid <span className="md-avl-tp-label-span">(Before Retirement)</span></span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-tx-tp-iul-value-text">{formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_taxes_paid) ? formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_taxes_paid) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-tx-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-tx-tp-iul-label-text">Total Rate</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-tx-tp-iul-value-text">{(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_rate) ? (finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_rate) : "0"}%</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-tx-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-tx-tp-iul-label-text">Annual Income Tax Saved</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-tx-tp-iul-value-text">{formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.annual_income_tax_saved) ? formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.annual_income_tax_saved) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-tx-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-tx-tp-iul-label-text">Total Income Tax Saved</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-tx-tp-iul-value-text">{formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_income_tax_saved) ? formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_income_tax_saved) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Grid container className="md-tx-av-container">
                                                                                            <Grid item xs={9}>
                                                                                                <span className="md-tx-tp-iul-label-text">Total Death Benefit</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <span className="md-tx-tp-iul-value-text">{formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_death_benefit) ? formatNumber(finalData?.iul_plan?.TAXES_age_data[changeYearIUL]?.total_death_benefit) : "$0"}</span>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    {calculationShowErrorIUL &&
                                                                                        <></>
                                                                                        // <Grid item xs={12}>
                                                                                        //     <Grid container className="taxes-tp-life-container-last" marginTop={1}>
                                                                                        //         <Grid item xs={12} className="taxes-tp-life-sub-item" sx={{ backgroundColor: "#ff000087 !important" }}>
                                                                                        //             <span id="taxes-tp-life-field-label" sx={{ color: "#fff" }}>IUL PLAN ACCOUNT IS EMPTY</span>
                                                                                        //         </Grid>
                                                                                        //     </Grid>
                                                                                        // </Grid>
                                                                                    }

                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    nsole.log
                                                    {/* Iul plan for mobile/laptop */}
                                                    <Grid item sm={12} xs={12} >
                                                        <Grid container className="md-age-container">
                                                            <Grid item sm={9} xs={7} className='md-age-item1'>
                                                                <span className="md-age-label">Age: </span>
                                                            </Grid>
                                                            <Grid item sm={3} xs={5} className='md-age-item2'>
                                                                <Grid container>
                                                                    <Grid item xs={12} className='md-age-num-item'>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            type="number"
                                                                            InputProps={{ inputProps: { min: taxIULAgeMin, max: taxIULAgeMax } }}
                                                                            onChange={(e) => {
                                                                                setChangeYearIUL(e.target.value);
                                                                            }}
                                                                            onBlur={InputBlurChangeIUL}
                                                                            onKeyUp={(e) => {
                                                                                if (e.keyCode === 40 || e.keyCode === 38 || e.keyCode == 13)
                                                                                    InputChangeYearIUL(e);
                                                                            }}
                                                                            value={changeYearIUL}
                                                                            size="small"
                                                                            variant="outlined"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </>

    );
};

export default UPTaxes;     