import { Dialog, DialogContent, Grid, TextField, Typography, Button, Box, InputAdornment, Alert, Checkbox } from "@mui/material"
import './AssignProfilePopup.css';
import React from 'react'

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
// close-btn
import { updateUserAssignedAgent } from '../../../src/service/login.service'
import CloseIcon from '@mui/icons-material/Close';
import { Context as mainContext } from '../../Context/auth_context'
import { useContext } from "react";
import { useState } from "react"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const AssignProfilePopup = (props) => {

  const theme = useTheme();
  const { UpdateLoader } = useContext(mainContext)
  const [personName, setPersonName] = React.useState(props.data.user_assigned_agent_id ? props.data.user_assigned_agent_id : '');
  const [apistatus, setApistatus] = React.useState();
  const [sendEmailCheckbox, setSendEmailCheckbox] = useState(false)

  let agentList = props.agentList
  const handleChange = (e) => {
    setPersonName(e.target.value)
  }
  const saveAssignAgentbtn = () => {
    if (!personName) return alert('Please select Agent')
    let obj = {
      "user_profile_id": props.data.user_profile_id,
      "agent_id": personName,
      "send_email_flag": sendEmailCheckbox
    }
    UpdateLoader(true)
    updateUserAssignedAgent(obj, (res) => {
      props.showMessage({
        status: true,
        message: "Data Saved Successfully!",
        error: false,
      })
      props.handleClose()
      UpdateLoader(false)
    }, (err) => {
      console.log("err")
      console.log(err)
      // setApistatus(false)
      props.showMessage({
        status: true,
        message: "Something Went Wrong!",
        error: true,
      })
      props.handleClose()
      UpdateLoader(false)
    })
  }

  return (
    <Dialog
      open={true}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <Grid item xs={12}>
          <Grid container className="pr-assign-profile-popup">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container className="pr-assign-profile-popup-title-container">
                    <Typography id="pr-assign-profile-popup-title">Assign Profile</Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container className="pr-assign-profile-popup-close-btn-container">
                    <Box id="pr-assign-profile-popup-close-btn"><CloseIcon onClick={props.handleClose} /></Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
              <Grid container className="pr-assign-agent-dropdown-container">
                {apistatus === true &&
                  <Grid item xs={12} paddingBottom={2}>
                    <Alert severity="success">Data Saved Successfully</Alert>
                  </Grid>
                }
                {apistatus === false &&
                  <Grid item xs={12} paddingBottom={2}>
                    <Alert severity="error">Something Went Wrong!</Alert>
                  </Grid>
                }
                <Grid item xs={12}>
                  <Grid container className="pr-assign-agent-dropdown-label" justifyContent="center" sx={{ margin: "10px 0px !important" }}>
                    <Typography id="pr-assign-agent-dropdown-label" sx={{ marginBottom: "10px" }}>Agent</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <FormControl className="pr-assign-agent-dropdown">
                      {/* <InputLabel >Select Agent</InputLabel> */}
                      <Select
                        className="pr-assign-agent-dropdown-box"
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={personName}
                        onChange={handleChange}
                        placeholder="Select Agent"
                        input={<OutlinedInput label="select Agent" />}
                        MenuProps={MenuProps}

                      >
                        <MenuItem disabled value="">Select Agent List</MenuItem>
                        {
                          agentList.map((name) => {
                            if (name.user_status_id == 2) {
                              return (
                                <MenuItem
                                  key={name.user_id}
                                  value={name.user_id}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name.user_firstname} {name.user_lastname}
                                </MenuItem>
                              )
                            }
                          })
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
            <Grid item xl={7} lg={7} md={7} sm={7} xs={12} marginTop="10px">
              <Grid container>
                <Grid item xs={12} className="assign-profile-checkbox-item">
                        <Checkbox  id="assign-profile-checkbox"  onChange={(e)=> {setSendEmailCheckbox(e.target.checked) }}/><span id="assign-profile-checkbox-text">Send email to Agent</span>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xl={7} lg={7} md={7} sm={7} xs={12} >
              <Grid container className="pr-save-cancel-popup-container">
                <Grid item xs={5.6}>
                  <Grid container className="avl-email-popup-btn">
                    <Button variant="outlined" id="avl-email-save-popup-btn" onClick={props.handleClose}>Cancel</Button>
                  </Grid>
                </Grid>

                <Grid item xs={5.6}>
                  <Grid container className="pr-save-popup-btn">
                    <Button variant="contained" id="pr-save-popup-btn" onClick={saveAssignAgentbtn}>Save</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AssignProfilePopup;